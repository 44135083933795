import React, { useEffect, useState } from 'react'
import Header from './Layout/Header'
import { Container } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { PlansListDataTypes } from './Model/Interface';
import { Session, User } from './Helper';
import { API_URLS, API_URL_BASE, X_APP_SECRET, headers } from './utils';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

export default function Plans() {

    function showErrorNotification(message: any) {
        toast.error(message, {
            position: "bottom-right",
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    }

    const [PlansList, setPlansList] = useState<PlansListDataTypes[] | undefined>([]);
    const [PlansListloading, setPlansListloading] = useState(true);
    const [CheckPlansList, setCheckPlansList] = useState(true);
    const [SubscriptionId, setSubscriptionId] = useState(Number);

    var user = User();
    const token = user?.data.token
    var session = Session();

    const navigate = useNavigate();

    useEffect(() => {
        
            fetch(`${API_URL_BASE}${API_URLS.SUBSCRIPTION_LIST}`, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-type": "application/json",
                    'X-APP-SECRET': X_APP_SECRET,
                    Authorization: "Bearer " + token,
                },
            }).then((result) => {

                result.json().then((resp) => {
                    if (resp.response == true) {
                        
                        setPlansList(resp.data)   
                        setPlansListloading(false)
                    } else {

                        setPlansList(undefined)
                        setCheckPlansList(false)
                    }
                })
            })
        

    }, []);

    const handleRadioChange = (subscription_id: number) => {
        setSubscriptionId(subscription_id)
    };
    
    const  Checkout = () => {

        
        if(SubscriptionId == 0){
            showErrorNotification("Please Select Plan.")
            return;
        }
         const requestData = {
            profile_id: session?.profile_id, // Use profileId here
            plan_id   : SubscriptionId
            
        };

        const requestBody = JSON.stringify(requestData);

        fetch(`${API_URL_BASE}${API_URLS.SUBSCRIPTION_CHECKOUT}`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-type": "application/json",
                'X-APP-SECRET': X_APP_SECRET,
                Authorization: "Bearer " + token,
            },
            body: requestBody,
        }).then((result) => {

            result.json().then((resp) => {
                
                if (resp.response == true) {
                    window.open(resp.data.url, '_blank');
                }else{
                    showErrorNotification(resp.message)
                }
            })
        })  
    };
    
  return (
    <div>
        
         <Header/>
         <ToastContainer />
         <div className="account_setting">
          <Container fluid className='cust_container'>
            {CheckPlansList? (
                <div className="account_setting_title">
                    <h3>Choose Plan</h3>
                </div>
            ):(null)
            }
            

            {CheckPlansList ? (
                
                    PlansListloading == false && PlansList !== undefined ? (
                        PlansList && Array.isArray(PlansList) && PlansList.map((item, index) => (
    
                            <div className="account_setting_table" key={index}>
                                <input type="radio" className="cust_radio" onChange={() => handleRadioChange(item.subscription_id)}   id={`radio-${index}`} name="subscrition" value={item.stripe_product_id}/>
                                <div className="inner_div">
                                    <label htmlFor={`radio-${index}`} className="plan_radio">
                                        <div className="premium_header">
                                            <div className="premium_header_left">
                                                <img src="img/premium.png" alt=""></img>
                                                <h4>{item.plan_name}</h4>
                                            </div>
                                            <div className="premium_header_right">
                                                <h4>${item.subscription_fee}</h4>
                                            </div>
                                        </div>
                                        <div className="premium_details">
                                            <div className="premium_details_box">
                                                <img src="img/check.png"></img>
                                                <h5>Cancel anytime</h5>
                                            </div>
                                            <div className="premium_details_box">
                                                <img src="img/check.png"></img>
                                                <h5>HD available</h5>
                                            </div>
                                            <div className="premium_details_box">
                                                <img src="img/check.png"></img>
                                                <h5>Create profile on single login</h5>
                                            </div>
                                            <div className="premium_details_box">
                                                <img src="img/check.png"></img>
                                                <h5>Unlimited movies and TV shows</h5>
                                            </div>
                                            <div className="premium_details_box">
                                                <img src="img/check.png"></img>
                                                <h5>Watch on your laptop, TV, phone and tablet</h5>
                                            </div>
                                        </div>
                                    </label>
                                </div>  
                            </div>                        
                        ))
                    ) : (
                        Array.from({ length: 2 }).map((_, index) => (
                            <div className="subscription_shimmer_card" key={index}></div>
                        ))
                    )

            ):(
                // <div className='pt-5 '>
                //     <h3 className='text-white m-0'>Subscription Plan Not Found.</h3>
                // </div>
                <div className="App">
                    <div className="error_page">
                        <img src="/img/404.svg" alt="404"></img>
                        <h1>Subscription Plan Not Available.</h1>
                    </div>
                </div>
                )
            }
            
            <div className="plan_btn">
                {CheckPlansList? (
                    <Link to={""} className="continue_btn" onClick={Checkout}>Continue</Link>
                ):(null)
                }
                <Link to={"/billing-details"} className="go_back">Go back</Link>
            </div>
          </Container>
          </div>
    </div>
  )
}
