import React from 'react'
import Footer from './Layout/Footer'
import { Container } from 'react-bootstrap'
import PrivacyHeader from './Layout/PrivacyHeader'
import { Link } from 'react-router-dom'

export default function Privacy() {
    return (
        <>
            <PrivacyHeader />
            <div className='privacy_contnt'>
                <Container>
                    <div className='privacy_header'>
                        <Link to={"/home"}>
                            <img src='img/privacy_arrow.svg'></img>
                            Back to home
                        </Link>
                    </div>
                    <div className='privacy_large_text'>
                        <h3>Privacy & Policy</h3>
                    </div>
                    <div className='privacy_content'>
                        <h4>Your Agreement</h4>
                        <p>
                            Software, Website, Application, Saas Platform (henceforth collectively called as “Software) in this website is the intellectual property of Alphanso Technology (Henceforth termed as Alphanso Tech) and is covered by retained IPR( Intellectual Property Rights) including copyright. The software or application is made available to you – the purchasing party (known as the Client) under the following Terms and conditions.
                        </p>
                        <p>
                            When you buy our product or Web Application Script, You are given once a license to install the script on the given domain. Multiple instances of the software should not be operated in various areas. You are allowed to use multiple instances on a single domain, e.g., sub-domain and a local host for testing and development purpose. We reserve the right to terminate your license if evidence if found that the software is being run on the multiple domains.
                        </p>
                        <p>
                            The software is only available to the client/company who have purchased it. You can not re-distribute it to any other third party/ organization. Once your license, this software client is the only one to use this authorized software. Under no circumstances, this software is licensable, assignable or transferal able unless prior agreement with Alphanso Tech. You can not circulate, re-distribute, resell this application or software or any of its functionality or part( including, but not limited to, the scripts, database architecture, design, UI/UX) to any other party without the written consent of Alphanso Tech.
                        </p>
                        <p>
                            You can modify/change this software to improve your business need or purpose to achieve your business goal. You many to re-distribute this software or any of our work in the software for commercial use including but not limited to licensing, leasing, running business operation of other company.
                        </p>
                        <p>
                            Our Software/Application customization performed by a client or any other third party appointed by the client will not be eligible for free technical support. The Free Technical support is provided by Alphanso Tech team to fix bugs and answer a question about the coding architecture or flow of the software. Any request for advice on how to change or modify the files to develop a new feature or modify existing workflow will be treated as a consultation request and will be billed at an hourly rate.
                        </p>
                        <div className='sub_content'>
                            <div className="sub_content_header">
                                <h3>TM</h3>
                                <h4>Trademarks</h4>
                            </div>
                            <p>
                                Software, Website, Application, Saas Platform (henceforth collectively called as “Software) in this website is the intellectual property of Alphanso Technology (Henceforth termed as Alphanso Tech) and is covered by retained IPR( Intellectual Property Rights) including copyright. The software or application is made available to you – the purchasing party (known as the Client) under the following Terms and conditions.
                            </p>
                        </div>
                        <div className='sub_content'>
                            <div className="sub_content_header">
                                <img src='img/limited.svg'></img>
                                <h4>Limited Liability</h4>
                            </div>
                            <p>
                                In no instance or event, Alphanso Tech is liable for direct, indirect damages ( including but not limited to damages for loss of revenue or profit, business interruption, loss of information, etc.) arising out of using the services or product on this website.
                            </p>
                        </div>
                        <div className='sub_content'>
                            <div className="sub_content_header">
                                <img src='img/Indemnification.svg'></img>
                                <h4>Indemnification</h4>
                            </div>
                            <p>
                                Client agrees that it shall defend, indemnify, save and hold the Developer harmless from any demands, liabilities, losses, costs, and claims, including reasonable attorney’s fees associated with the Developer’s development of the Client’s website. This includes Liabilities asserted against the Developer, its subcontractors, its agents, its clients, servants, officers, and employees, that may arise or result from any service provided or performed or agreed to be performed or any product sold by the Client, its agents, employees or assigns.
                            </p>
                            <p>
                                The client also agrees to defend, indemnify and hold harmless the Developer against Liabilities arising out of any injury to person or property caused by any products or services sold or otherwise distributed over the Client’s website. This includes infringing upon on the proprietary rights of a third party, copyright infringement, and delivering any defective product or misinformation, which is detrimental to another person, organization, or business.
                            </p>
                        </div>
                        <div className='sub_content'>
                            <div className="sub_content_header">
                                <img src='img/non.svg '></img>
                                <h4>Non Disclosure</h4>
                            </div>
                            <p>
                                Alphanso tech, its employees, and subcontractors agree that, except as directed by the Client, it will not at any time during or after the term of this Agreement disclose any confidential information. Likewise, the Client agrees that it will not convey any confidential information obtained about the Developer to another party.
                            </p>
                        </div>
                        <div className='sub_content'>
                            <div className="sub_content_header">
                                <img src='img/goverment.svg'></img>
                                <h4>GOVERNING LAW.</h4>
                            </div>
                            <p>
                                This contract shall be governed by the laws of the Rajkot, Gujarat (India) except where the Federal supremacy clause requires otherwise.
                            </p>
                            <p>
                                Please note that the use of the phrase" Clone" is really for advertising purpose only since this is how the web development business refers to creating a Software similar to the reference website. Alphanso Tech certifies that our team of designers and developers assembles code and the full design. No system, design or graphics has been or will be used in the any of the reference website or app. The custom option of alphanso Tech allows you to conduct a site very similar to the reference website or app based on software engineering blueprint and our structure.
                            </p>
                            <p>
                            Alphanso tech is by no means connected with any other company.
                            </p>
                        </div>
                    </div>
                </Container>
            </div>
            <Footer />
        </>
    )
}
