import React, { useEffect, useState } from 'react'
import Carousel from 'react-bootstrap/Carousel';
import { CarouselItem, Container } from 'react-bootstrap';
import OwlCarousel from 'react-owl-carousel2';
import Header from './Layout/Header';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import Footer from './Layout/Footer';
import Modal from 'react-bootstrap/Modal';
import Genres from './Layout/MovieGenres';
import { FeaturesData, FormatTimeWithHourMinute, Session, User, UserLocationData, useHomePageApi, usePlayVideo } from './Helper';
import { UserInfo, Moviedatatype } from './Model/Interface';
import { API_URL_BASE, STRIPE_TEST_KEY, X_APP_SECRET, headers } from './utils';
import { API_URLS } from './utils';
import { WatchlistStatusInterface, FavoriteStatusInterface } from './Model/Interface';
import routes from './Routes';
import { Elements } from '@stripe/react-stripe-js';
import ItemPurchase from './ItemPurchase';
import { loadStripe } from '@stripe/stripe-js';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import MovieGenres from './Layout/MovieGenres';
import { isUndefined } from 'lodash';



export default function GenreswiseMovie() {


    const [showModal, setshowModal] = useState(false);
    const handleClosee = () => setshowModal(false);
    const loginModal = () => setshowModal(true);
    

    var { genresId } = useParams();
    const [show, setShow] = useState(false);
    
    const [MoreLikeThisMoviedata, setMoreLikeThisMoviedata] = useState<Moviedatatype[] | undefined>([]);
    const [modeldata, setModeldata] = useState<{
        data_id: number;
        data_type: string;
        title: string;
        description: string;
        maturity_level: string;
        release_date: string;
        media_banner: string;
        media_poster: string;
        genres: { genre_id: string; genre_title: string; genre_image: string }[];
        celebs: any[]; // Adjust the type as needed
        duration: string;
        premium: {
          subscriber_price: string;
          nonsubscriber_price: string;
          currency: string;
          currency_symbol: string;
          is_paid: boolean;
        };
        is_favourite: any;
        is_watchlist: any;
      }>();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    // const { PlayVideo } = usePlayVideo();
    const [genersCheck, setGenrescheck] = useState(true);
    const [MorelikeThisLoading, setMorelikeThisLoading] = useState(true);
    const [CheckMoreLikeThisMovie, setCheckMoreLikeThisMovie] = useState(true);
    const {Moviedata} = useHomePageApi();
    const FeaturesList = FeaturesData();
    const UserLocation = UserLocationData();

    var user = User();
    const token = user?.data.token


    const [moviePrice, setmoviePrice] = useState("");
    const [currencySymbol, setcurrencySymbol] = useState("");
    const [itemVideoId, setitemVideoId] = useState("");
    const [itemDataType, setitemDataType] = useState("");
    const [itemPrice, setitemPrice] = useState("");
    const [itemTitle, setitemTitle] = useState("");
    const [showThree, setShowThree] = useState(false);
    const [showTwo, setShowTwo] = useState(false);
    const [paymentLoder, setpaymentLoder] = useState(false);
    const userSubscription  =  user?.data.user_subscription;
    const payPerView        =  FeaturesList?.data.pay_per_view;
    
    const stripePromise = loadStripe(STRIPE_TEST_KEY);
      
    const loderShow = () => {
        setpaymentLoder(true);
    };

    const loderHide = () => {
        
        // setShowThree(false)
        // setpaymentLoder(false);
    };

      const errorInVideoPayment = () => {

        handleCloseThree()
        if(itemDataType == 'movie'){
            movie_name_modal_close();
        }else{
            movie_name_modal_close();
        }
    };

    const updateVideoPaymentStatus = () => {

        handleCloseThree()
        
        if(itemDataType == 'movie'){
            if (modeldata && typeof modeldata === 'object' && 'premium' in modeldata) {
                
                setModeldata({
                    ...modeldata,
                    premium: {
                    ...modeldata.premium,
                    is_paid: true,
                    },
                });

                movie_name_modal_close(); 
            } else {
                console.error('Invalid modeldata structure');
            }
        }

    };

    const handleShowThree = () => {
        setShowThree(true);
        setShowTwo(false);
        setShow(false);

    }

    const handleCloseThree = () =>{
        setpaymentLoder(false);
        setShowThree(false)
    }

     const handleCloseTwo = () => {
        setShowTwo(false);
    }

    const handleShowTwo = (data_id:any,data_type:string) =>{

        if (!token) {
            if (data_type == "movie") {
                handleClose()
            }
            setshowModal(true)
            return;
        }
        setShowTwo(true);
        setitemVideoId(data_id)
        setitemDataType(data_type)
    }


    let movie_name_modal_close = () =>{
        setShowTwo(false)
        
        if(itemDataType == 'movie'){
            setShow(true)
        }
    }

    const PlayVideo = (data_id: number, watching_time: string = "") => {
        const data_type: string = "movie";
        navigate(routes.VideoWatch, { state: { data_id,data_type,watching_time } });
    };


    function showSuccessNotification(message: any) {
        toast.success(message, {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    }

    function showErrorNotification(message: any) {
        toast.error(message, {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    }

    const handleClose = () => {
        setCheckMoreLikeThisMovie(true);
        setShow(false);
    }
    const handleShow = (data_id: number) => {
        setShow(true);
        setModeldata(undefined)
        setMoreLikeThisMoviedata(undefined)
        const requestData = {
            profile_id: session?.profile_id, // Use profileId here
            movie_id: data_id,
            country_code: UserLocation.country_code


        };
        const requestBody = JSON.stringify(requestData);

        fetch(`${API_URL_BASE}${API_URLS.MOVIE_DETIALS}`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-type": "application/json",
                'X-APP-SECRET': X_APP_SECRET,
                Authorization: "Bearer " + token,
            },
            body: requestBody,
        }).then((result) => {

            result.json().then((resp) => {

                if (resp.response == true) {
                    setModeldata(resp.data);
                    setitemTitle(resp.data.title)
                    if(payPerView ==  true){

                        setcurrencySymbol(resp.data.premium.currency_symbol)
                        if(userSubscription == true){   

                            setmoviePrice(resp.data.premium.subscriber_price)
                            setitemPrice(resp.data.premium.subscriber_price)

                        }else{
                            setmoviePrice(resp.data.premium.nonsubscriber_price)
                            setitemPrice(resp.data.premium.nonsubscriber_price)
                        }
                    }
                    
                }
            })
        })

        fetch(`${API_URL_BASE}${API_URLS.MORE_LIKE_THIS_MOVIES}`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-type": "application/json",
                'X-APP-SECRET': X_APP_SECRET,
                Authorization: "Bearer " + token,
            },
            body: requestBody,

        }).then((result) => {

            result.json().then((resp) => {

                if (resp.response == true) {
                    setMoreLikeThisMoviedata(resp.data)
                    setMorelikeThisLoading(false)
                } else {
                    setCheckMoreLikeThisMovie(false)
                }
            })
        })

    }



    const [GenersMoviedata,setMoviedata] = useState<Moviedatatype[]>([])
    const [profiledata, setProfiledata] = React.useState<string>();

    const viewAllMoviedata = GenersMoviedata.length >= 10;
    

    var session = Session();

    const [key, setKey] = useState(0);
    const [Genrestitle, setGenrestitle] = useState("");

    useEffect(() => {
        setLoading(true);
        setGenrescheck(true)
        const fetchData = async () => {
                const requestData = {
                    genres_id: genresId,
                    filter: {
                        status: "active"

                    },
                    profile_id: session?.profile_id, // Use profileId here
                    paginate: false,
                    order_by: ["created_at", "desc"],
                    country_code: UserLocation.country_code
                };

                const requestBody = JSON.stringify(requestData);

                fetch(`${API_URL_BASE}${API_URLS.GENRES_WISE_MOVIES}`, {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-type": "application/json",
                        'X-APP-SECRET': X_APP_SECRET,
                        Authorization: "Bearer " + token,
                    },
                    body: requestBody,
                })

                    .then((result) => {
                        result.json().then((resp) => {

                            if (resp.response == true) {

                                setMoviedata(resp.data.movies);
                                setGenrestitle(resp.data.genre['genre_title']);

                                setLoading(false);
                            } else {
                                setMoviedata([]);
                                setGenrestitle('')
                                setGenrescheck(false)
                            }
                        })
                    })
        };
        fetchData();

    }, [genresId, key]);

    useEffect(() => {

        setKey((prevKey) => prevKey + 1);

    }, [genresId]);

    const [watchlistStatus, setWatchlistStatus] = useState<WatchlistStatusInterface>([]);
    const [WatchlistStatusInterface, setNewWatchlistStatus] = useState<WatchlistStatusInterface>([]);


    const WatchlistAdd = (data_id: number) => {
        if(!token){
            handleClose()
            setshowModal(true)
            return; 
        }

        const action = watchlistStatus[data_id] ? 0 : 1; // Toggle action
        const requestData = {
            profile_id: session?.profile_id, // Use profileId here
            data_type: "movie",
            data_id: data_id,
            action: action,
            country_code: UserLocation.country_code
        };
        const requestBody = JSON.stringify(requestData);

        fetch(`${API_URL_BASE}${API_URLS.WATCHLIST_ADD_REMOVE}`, {
            method: "POST",
            headers: headers,
            body: requestBody,
        })
            .then((result) => result.json())
            .then((resp) => {
                if (resp.response === true) {

                    setKey((prevKey) => prevKey + 1);

                    setWatchlistStatus((prevStatus: any) => ({
                        ...prevStatus,
                        [data_id]: action === 1, // If action is 1, the item is not in the watchlist, otherwise it's in the watchlist
                    }));
                }
            })
            .catch((error) => {
                console.error("Error while updating watchlist:", error);
            });

    };

    useEffect(() => {

        const filteredData = Moviedata && Moviedata.filter((item) => item.is_watchlist === "true");
        const watchlistStatusDATA: any = [];
        filteredData.forEach((item) => {
            watchlistStatusDATA[item.data_id] = true;
        });
        setWatchlistStatus(watchlistStatusDATA);

    }, [Moviedata]);


    useEffect(() => {

        const initialWatchlistStatus: any = {};
        Moviedata.forEach((item) => {
            initialWatchlistStatus[item.data_id] = item.is_watchlist === "true";
        });
        setNewWatchlistStatus(initialWatchlistStatus);


    }, [key]);

    const [favoriteStatus, setFavoriteStatus] = useState<FavoriteStatusInterface>([]);
    const [NewFavoriteStatus, setNewFavotiteStatus] = useState<FavoriteStatusInterface>([]);

    const FavoriteAdd = (data_id: number) => {

        if(!token){
            handleClose()
            setshowModal(true)
            return; 
        }
        const action = favoriteStatus[data_id] ? 0 : 1; // Toggle action

        const requestData = {
            profile_id: session?.profile_id, // Use profileId here
            data_type: "movie",
            data_id: data_id,
            action: action,
            country_code: UserLocation.country_code
        };
        const requestBody = JSON.stringify(requestData);

        fetch(`${API_URL_BASE}${API_URLS.FAVORITE_ADD_REMOVE}`, {
            method: "POST",
            headers: headers,
            body: requestBody,
        })
            .then((result) => result.json())
            .then((resp) => {
                if (resp.response === true) {

                    setKey((prevKey) => prevKey + 1);

                    setFavoriteStatus((prevStatus: any) => ({
                        ...prevStatus,
                        [data_id]: action === 1, // If action is 1, the item is not in the watchlist, otherwise it's in the watchlist
                    }));
                }
            })
            .catch((error) => {
                console.error("Error while updating watchlist:", error);
            });

    };

    useEffect(() => {

        const filteredData = Moviedata.filter((item) => item.is_favourite === "true");
        const FavoriteStatusDATA: any = [];
        filteredData.forEach((item) => {
            FavoriteStatusDATA[item.data_id] = true;
        });
        setFavoriteStatus(FavoriteStatusDATA);

    }, [Moviedata]);


    useEffect(() => {

        const initialWatchlistStatus: any = {};
        Moviedata.forEach((item) => {
            initialWatchlistStatus[item.data_id] = item.is_favourite === "true";
        });
        setNewFavotiteStatus(initialWatchlistStatus);


    }, [key]);
    const options = {
        nav: true,
        items: 9,
        rewind: true,
        autoplay: true,
        dots: false,
        stagePadding: 40,
        navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"],
        responsive: {
            325: {
                items: 2,
            },
            425: {
                items: 2,
            },
            575: {
                items: 3,
            },
            767: {
                items: 4,
            },
            991: {
                items: 5,
            },
            1199: {
                items: 5,
            },
            1024: {
                items: 7,
            },
            1920: {
                items: 9,
            },


        },
    };


    return (
        <>
            <Header />

            <ToastContainer />
            <Container fluid className='cust_container'>
                <div className='text-white not_found pb-0'>
                    <MovieGenres GenresId={genresId} />
                </div>
            </Container>
            {/* Moviedata
            loading

            { */}
            {
                genersCheck ? (
                    <div className='index_second_section watchlist pt-0'>
                        <Container fluid className='cust_container inner_section pt-0'>
                            <div className="episode_title">

                                <h4>{Genrestitle}</h4>
                                {viewAllMoviedata && (
                                    <Link to={"/view"}>View All</Link>
                                )}

                            </div>
                            <div className="view_all_section">
                                <div className="slick-slider episode_details">

                                    {
                                        GenersMoviedata ?
                                            Array.isArray(GenersMoviedata) && GenersMoviedata.map((item) => (
                                                <div className='item_box watchlist_box' key={item.title}>
                                                    <div onClick={() => handleShow(item.data_id)}>
                                                        <img className="img" src={item.media_poster} />
                                                    </div>
                                                    <div className="item_img_details">
                                                        <div className="item_img_header">
                                                            <h5>{item.title}</h5>
                                                            <ul>
                                                                <li onClick={() => PlayVideo(item.data_id)}><img src="/img/play-icon.png" alt="" /></li>
                                            
                                                                <li key={item.data_id} onClick={() => WatchlistAdd(item.data_id)}>
                                                                    {watchlistStatus[item.data_id] ? (
                                                                        <img src="/img/check-white.png" alt="" />
                                                                    ) : (
                                                                        <img src="/img/add-icon.png" alt="" />
                                                                    )}

                                                                </li>
                                                                <li key={item.data_id} onClick={() => FavoriteAdd(item.data_id)}>
                                                                    {favoriteStatus[item.data_id] ? (
                                                                        <li><img src="/img/fav.png" alt="" /></li>
                                                                    ) : (
                                                                        <li><img src="/img/like stroke.png" alt="" /></li>
                                                                    )}

                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="item_img_number">
                                                            <h3>
                                                                {item.maturity_level}</h3>
                                                            <h4>{item.release_date ? (new Date(item.release_date)).getFullYear() : ""}  </h4>
                                                        </div>
                                                    </div>
                                                        {
                                                        item.is_premium == true && item.is_paid == false ? 
                                                        <div className='premium_icon'>
                                                            <Link to={"#"}><img src='img/premium_icon.png' /></Link>
                                                        </div>: "" 
                                                        }
                                                </div>
                                            ))
                                            : Array.from({ length: 8 }).map((_, index) => (
                                                <div className="shimmer_card" key={index}></div>
                                            ))
                                    }
                                </div>
                            </div>
                        </Container>
                    </div>
                ) : (
                    <h2 className='text-white not_found'>Movie Not Found</h2>

                )
            }



            <Footer />

            <Modal className='series_modal' show={show} onHide={handleClose} backdrop="static" size="lg" keyboard={false} centered>
                <div className="modal_close" onClick={handleClose}>
                    <Link to={""}><img src="/img/modal_close.png" alt=""></img></Link>
                </div>

                <Modal.Body>
                    <div className="series_img">
                        {modeldata ? (
                            <img src={modeldata ? modeldata['media_banner'] : 'img/default-banner.png'} className="series_big_imf" alt=""></img>
                        ) : (
                            <div className='series_img_shimmer'></div>
                        )}

                        <div className="series_tool">
                            <div className="series_name">
                                <h2>{modeldata ? modeldata['title'] : ''}</h2>
                            </div>
                            <div className="series_controller">
                                <div className="series_left_controller">

                                    {modeldata && Object.keys(modeldata['premium']).length > 0  && modeldata['premium']['is_paid']  == false?
                                        <button className="music_play_btn" onClick={() => handleShowTwo(modeldata['data_id'],'movie')}><img src="img/play_icon.jpg"></img>Pay & Play</button>
                                     :  <button onClick={() => modeldata && PlayVideo(modeldata['data_id'])} className="music_play_btn"><img src="img/play_icon.jpg"></img>Play</button>}
                                    
                                   

                                    {watchlistStatus && modeldata && watchlistStatus[modeldata['data_id']] ? (
                                        <div onClick={() => WatchlistAdd(modeldata['data_id'])}>
                                            {watchlistStatus[modeldata['data_id']] ? (

                                                <button className="music_small_btn"><img src="/img/check-white.png"></img></button>
                                            ) : (
                                                <button className="music_small_btn"><img src="/img/music_add.png"></img></button>
                                            )}

                                        </div>
                                    ) : (
                                        <div onClick={() => modeldata && WatchlistAdd(modeldata['data_id'])}>
                                            {modeldata && Object.keys(watchlistStatus).length === 0 && modeldata && modeldata['is_watchlist'] === "true" ? (
                                                <button className="music_small_btn"><img src="/img/check-white.png" alt="" /></button>
                                            ) : (
                                                <button className="music_small_btn"><img src="/img/music_add.png" alt="" /></button>
                                            )}
                                        </div>
                                    )}
                                     {favoriteStatus && modeldata && favoriteStatus[modeldata['data_id']] ? (
                                        <div onClick={() => FavoriteAdd(modeldata['data_id'])}>
                                            {favoriteStatus[modeldata['data_id']] ? (

                                                <button className="music_small_btn"><img src="/img/fav.png"></img></button>
                                            ) : (
                                                <button className="music_small_btn"><img src="/img/like stroke.png"></img></button>
                                            )}

                                        </div>
                                    ) : (
                                        <div onClick={() => modeldata && FavoriteAdd(modeldata['data_id'])}>
                                            {modeldata && Object.keys(favoriteStatus).length === 0 && modeldata && modeldata['is_watchlist'] === "true" ? (
                                                <button className="music_small_btn"><img src="/img/fav.png" alt="" /></button>
                                            ) : (
                                                <button className="music_small_btn"><img src="/img/like stroke.png" alt="" /></button>
                                            )}
                                        </div>
                                    )}

                                </div>
                                <div className="series_right_controller">
                                    <button className='music_small_btn'><img src="/img/music_volum.png" className=" m-0 d-none"></img></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="series_full_description">
                        {
                            modeldata ? (
                                <div className="series_details_content">
                                    <div className="series_description">
                                        <div className="more_second_section mt-0">
                                            <h3> {modeldata ? modeldata['maturity_level'] : ''}</h3>
                                            <h4>{modeldata && modeldata['release_date'] ? (new Date(modeldata['release_date'])).getFullYear() : ""} &nbsp;|&nbsp; {modeldata && FormatTimeWithHourMinute(modeldata['duration'])}</h4>
                                        </div>
                                        <h5 className='movie_price'>
                                                {!isUndefined(moviePrice) ? currencySymbol + moviePrice: ""}
                                            </h5>
                                        <p>{modeldata ? modeldata['description'] : ''}</p>
                                    </div>
                                    <div className="series_categories">
                                        <h4>Cast & Crew : <span className="white_text">
                                            {modeldata && modeldata['celebs'] && Array.isArray(modeldata['celebs']) ? (modeldata['celebs'] as { title: string }[]).map((cast) => cast.title).join(', ') : ""}
                                        </span></h4>
                                        <h4>Genres  : <span className="white_text">
                                            {modeldata && modeldata['genres'] && Array.isArray(modeldata['genres']) ? (modeldata['genres'] as { genre_title: string }[]).map((genre) => genre.genre_title).join(', ') : ""}
                                        </span></h4>
                                    </div>
                                </div>
                            ) : (
                                null
                            )
                        }
                        <div className="more_like_this">

                            {
                                CheckMoreLikeThisMovie === true ? (
                                    <div className="more_like_this_title">
                                        <h3>More Like This</h3>
                                    </div>
                                ) : (null)
                            }

                            <div className="row">

                                {
                                    CheckMoreLikeThisMovie === true ? (
                                        MorelikeThisLoading === false && Array.isArray(MoreLikeThisMoviedata) && MoreLikeThisMoviedata.length > 0 ? (
                                            MoreLikeThisMoviedata.map((item) => (

                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                    <div className="more_like_box">
                                                        <div className="more_like_box_img">
                                                            <img src={item.media_banner}></img>
                                                        </div>
                                                        {
                                                            item.is_premium == true && item.is_paid == false ? 
                                                            <div className='premium_icon'>
                                                                <Link to={"#"}><img src='img/premium_icon.png' /></Link>
                                                            </div>: "" 
                                                        }
                                                        <div className="more_like_content_box">
                                                            <div className="more_like_left_info">
                                                                <h4>{item.title}</h4>
                                                                {watchlistStatus && watchlistStatus[item.data_id] ? (
                                                                    <li onClick={() => WatchlistAdd(item.data_id)}>
                                                                        {watchlistStatus[item.data_id] ? (
                                                                            <img src="/img/check-white.png" alt="" />
                                                                        ) : (
                                                                            <img src="/img/add-icon.png" alt="" />
                                                                        )}

                                                                    </li>
                                                                ) : (
                                                                    <li onClick={() => WatchlistAdd(item.data_id)}>
                                                                        {Object.keys(watchlistStatus).length === 0 && item.is_watchlist == "true" ? (
                                                                            <img src="/img/check-white.png" alt="" />
                                                                        ) : (
                                                                            <img src="/img/add-icon.png" alt="" />
                                                                        )}
                                                                    </li>
                                                                )}
                                                            </div>
                                                            <div className="more_second_section">
                                                                <h3> {item.maturity_level}</h3>
                                                                <h4>
                                                                    {item.release_date ? (new Date(item.release_date)).getFullYear() : ""}
                                                                </h4>
                                                            </div>
                                                            <div className="more_second_description">
                                                                <p>{item.description}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            Array.from({ length: 3 }).map((_, index) => (
                                                <div className='more_like_shimmer' key={index}></div>
                                            ))
                                        )
                                    ) : (null)
                                }


                            </div>
                            <div className="more_img_load">
                                <Link to={""}><img src="/img/down_arrowpng.png" alt=""></img></Link>
                            </div>
                        </div>
                        <div className="about_movie">
                            <div className="more_like_this_title">
                                <h3>About {modeldata ? modeldata['title'] : ''}</h3>
                            </div>
                            <div className="about_movie_details">
                                <div className="series_categories">
                                    <h4>Cast & Crew : <span className="white_text"> {modeldata && modeldata['celebs'] && Array.isArray(modeldata['celebs']) ? (modeldata['celebs'] as { title: string }[]).map((cast) => cast.title).join(', ') : ""}</span></h4>
                                    <h4>Genres  : <span className="white_text">{modeldata && modeldata['genres'] && Array.isArray(modeldata['genres']) ? (modeldata['genres'] as { genre_title: string }[]).map((genre) => genre.genre_title).join(', ') : ""}</span></h4>
                                    <h4>Maturity Rating: <span className="white_text white_text_border"> {modeldata ? modeldata['maturity_level'] : ''} </span></h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal className='login_modal' show={showModal} onHide={handleClosee} centered   backdrop="static">
                <Modal.Body>
                    <div className='login_modal_img'>
                        <img src='/img/login.svg'></img>            
                    </div>
                    <div className='login_modal_text text-center'>
                         <h3>Login Now</h3>
                         <h4>You have to login to use this feature</h4>       
                    </div>
                    <div className='login_modal_btn'>
                         <Link to={routes.signin}>Login</Link>
                         <Link onClick={handleClosee}  to={""}>Continue</Link>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal className='movie_name_modal' show={showTwo} onHide={handleCloseTwo}  centered backdrop="static" keyboard={false}>
                <Modal.Body>
                    <div className='movie_name_close_modal' onClick={movie_name_modal_close}>
                        <Link to={"#"}><img src='img/modal_close.png'/></Link>
                    </div>
                    <div className='movie_name_details'>
                        <div className='movie_name_img'>
                            <img src='img/tv.svg'/>
                        </div>
                        <div className='movine_name_section'>
                            <h3>{itemTitle}</h3>
                            <h4>{currencySymbol}{itemPrice}</h4>
                            <button onClick={handleShowThree} >Continue to Pay</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal className='movie_name_modal' show={showThree} onHide={handleCloseThree}  centered backdrop="static" keyboard={false}>
                <Modal.Body>
                    {paymentLoder == true ? (
                    <div className='loader'>
                            <img src='img/loader.gif' alt="Loading..." />
                    </div>
                    ) : (
                        null
                    )}
                        <>
                            <div className='movie_name_close_modal' onClick={handleCloseThree}>
                                <Link to="#">
                                    <img src='img/modal_close.png' alt="Close Modal" />
                                </Link>
                            </div>
                            <div className='stripe_header'>
                                <h3>Payment for - <span>{itemTitle}</span></h3>
                                <h3>Total Amount - <span>{currencySymbol}{itemPrice}</span></h3>
                            </div>
                            <div className='stripe_details'>
                                {itemDataType && itemVideoId ? (
                                    <Elements stripe={stripePromise}>
                                        <ItemPurchase 
                                            data_id={itemVideoId} 
                                            data_type={itemDataType}  
                                            updatePaymentStatus={updateVideoPaymentStatus} 
                                            errorInVideoPayment={errorInVideoPayment} 
                                            loderShow={loderShow} 
                                            loderHide={loderHide}
                                        />
                                    </Elements>
                                ) : ""}
                            </div>
                        </>
                </Modal.Body>
            </Modal>
        </>
    )
}
