import React from 'react'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export default function CommingSoon() {
  return (
    <>
      <div>
      <Container>
         <Link to={"/home"}><img src="/img/play_now_logo.png" alt="" className='mt-5'></img></Link> 
         <div className='coming_soon_content'>
            <img src='img/coming-soon.svg' className='coming_soon_img'></img>
            <h3>We are Coming soon </h3>
         </div>
      </Container>
      </div>
    </>
  )
}
