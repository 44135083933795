import React, { useEffect, useState } from 'react'
import Header from './Layout/Header'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { IStateChangepassword } from './Model/Interface'
import { API_URL_BASE, X_APP_SECRET, headers } from './utils'
import { API_URLS } from './utils'
import { User } from './Helper'
import routes from './Routes'
import { useNavigate } from 'react-router-dom'

export default function AccountChangePassword() {

    var user = User();
    const token = user?.data.token
    const [errmsg, setErrormsg] = useState("");
    const navigate = useNavigate();
    const [state, setState] = useState<IStateChangepassword>({
        user: {
            current_password: "",
            new_password: "",
            confirm_new_password: ""
        },
    });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setState({
            user: {
                ...state.user,
                [event.target.name]: event.target.value,
            },
        });
    };

    const passwordChange = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        let current_password = state.user.current_password;
        let new_password = state.user.new_password;
        let confirm_new_password = state.user.confirm_new_password;


        let data = { current_password, new_password, confirm_new_password }

        fetch(`${API_URL_BASE}${API_URLS.USER_CHANGE_PASSWORD}`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-type": "application/json",
                'X-APP-SECRET': X_APP_SECRET,
                Authorization: "Bearer " + token,
              },
            body: JSON.stringify(data)
        }).then((result) => {

            result.json().then((resp) => {

                if (resp.response == false) {

                    setErrormsg(resp.message);
                } else {
                    navigate(routes.home)

                }

            })
        })
    }

    return (
        <div>
            <Header />
            <div className="account_setting">
                <Container fluid className='cust_container'>
                    <div className="account_setting_title">
                        <h3><Link to={"/setting"}><img src="img/back.svg" alt="" className="back_arrow"></img></Link>Change Password</h3>
                    </div>
                    <div className="account_setting_table change_password_box">
                        <form onSubmit={passwordChange}>
                            <div className="row">
                                <span style={{ color: "red" }} >{errmsg}</span>
                                <div className="col-xxl-3 col-xl-5 col-lg-5 col-md-6">
                                    <div className="form_group">
                                        <label>Current Password</label>
                                        <input type="password" name='current_password' onChange={handleChange} className="form_input_group" value={state.user.current_password} placeholder="**** ****" />
                                    </div>
                                    <div className="form_group">
                                        <label>New Password</label>
                                        <input type="password" name='new_password' onChange={handleChange} className="form_input_group" value={state.user.new_password} placeholder="**** ****" />
                                    </div>
                                    <div className="form_group">
                                        <label>Confirm Password</label>
                                        <input type="password" name='confirm_new_password' onChange={handleChange} className="form_input_group" value={state.user.confirm_new_password} placeholder="**** ****" />
                                    </div>
                                    <input type="submit" value="Change Password" className="change_pwd" />
                                </div>
                            </div>
                        </form>
                    </div>
                </Container>
            </div>
        </div>
    )
}
