import { useNavigate } from "react-router";
import { Moviedatatype, Tvshowdatatype, UserInfo, WatchlistConfig } from "./Model/Interface";
import { API_URLS, API_URL_BASE, X_APP_SECRET, headers } from "./utils";
import { useState, useEffect, useCallback } from 'react';
import  secureLocalStorage  from  "react-secure-storage";
import { isSet } from "lodash";

export const Session = () => {
    const userProfile = secureLocalStorage.getItem("userProfile") as string;
if (!userProfile) {
    return;
}
const profile = JSON.parse(userProfile);
return profile;
}

export const FeaturesData = () => {
    const FeaturesData = secureLocalStorage.getItem("features-Data") as string;
if (!FeaturesData) {
    return null;
    
}
const featuresList = JSON.parse(FeaturesData);
return featuresList;
}

export const UserLocationData = () => {
    const UserLocation = secureLocalStorage.getItem("user-location") as string;
if (!UserLocation) {
    return "";
}
const location = JSON.parse(UserLocation);
return location;
}

export const User = () => {
    const userJSON = secureLocalStorage.getItem("playnow-user") as string;
    const user: UserInfo | null = userJSON ? JSON.parse(userJSON) : null;   
    return user;
}

export const UserCountry = () => {
    const userCountry = secureLocalStorage.getItem("user-country") as string;
    const country: UserInfo | null = userCountry ? JSON.parse(userCountry) : null;   
    return country;
}


export const calculatePercentageOfTime = (timeString: string, percentage: number): string => {
    // Parse the time string to get hours, minutes, and seconds
    const [hours, minutes, seconds] = timeString.split(':').map(Number);

    // Convert the time to total seconds
    const totalSeconds = hours * 3600 + minutes * 60 + seconds;

    // Calculate the minimum required time (20% of total time)
    const minimumRequiredSeconds = (percentage / 100) * totalSeconds;

    // Convert the new total seconds back to HH:mm:ss format
    const newHours = Math.floor(minimumRequiredSeconds / 3600);
    const newMinutes = Math.floor((minimumRequiredSeconds % 3600) / 60);
    const newSeconds = Math.floor(minimumRequiredSeconds % 60);

    // Format the result as HH:mm:ss
    const result = `${String(newHours).padStart(2, '0')}:${String(newMinutes).padStart(2, '0')}:${String(newSeconds).padStart(2, '0')}`;

    return result;
}

export const SumTimeDurations = (startTime: string, endTime: string): string => {
    // Parse the time strings to get hours, minutes, and seconds
    const [hours1, minutes1, seconds1] = startTime.split(':').map(Number);
    const [hours2, minutes2, seconds2] = endTime.split(':').map(Number);

    // Calculate the total seconds for each time duration
    const totalSeconds1 = hours1 * 3600 + minutes1 * 60 + seconds1;
    const totalSeconds2 = hours2 * 3600 + minutes2 * 60 + seconds2;

    // Calculate the total seconds for the sum
    const totalSecondsSum = totalSeconds1 + totalSeconds2;

    // Convert the total seconds back to HH:mm:ss format
    const newHours = Math.floor(totalSecondsSum / 3600);
    const newMinutes = Math.floor((totalSecondsSum % 3600) / 60);
    const newSeconds = Math.floor(totalSecondsSum % 60);

    // Format the result as HH:mm:ss
    const result = `${String(newHours).padStart(2, '0')}:${String(newMinutes).padStart(2, '0')}:${String(newSeconds).padStart(2, '0')}`;

    return result;
}




export const FormatTime = (duration:any) => {
    const totalSeconds = Math.round(duration);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
  
    const formattedTime = `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    
    return formattedTime;
  };

  export const FormatTimeWithHourMinute = (time:any) => {
    const [hours, minutes, seconds] = time.split(':');
    const totalMinutes = parseInt(hours, 10) * 60 + parseInt(minutes, 10);
  
    const formattedTime = `${totalMinutes > 0 ? `${totalMinutes}m` : ''} ${seconds > 0 ? `${seconds}s` : ''}`;
    return formattedTime.trim();
  };


export const usePlayVideo = () => {
    var session = Session();
    var user = User();
    const token = user?.data.token
        const navigate = useNavigate();
  
    const PlayVideo = (data_id: number, watching_time: string = "") => {
        const requestData = {
            profile_id: session?.profile_id, // Use profileId here
            data_id: data_id,
            data_type: "movie"
        };
    
        const requestBody = JSON.stringify(requestData);
    
        fetch(`${API_URL_BASE}${API_URLS.PLAY_VIDEO}`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-type": "application/json",
                'X-APP-SECRET': X_APP_SECRET,
                Authorization: "Bearer " + token,
            },
            body: requestBody,
        })
            .then((result) => {
                if (!result.ok) {
    
                    throw new Error("API request failed");
                }
                return result.json();
            })
            .then((resp) => {
                if (resp.response === true) {
    
                    const encodedVideoUrl:string = encodeURIComponent(resp.data.video_url);
                    

                } else {
                    alert("API request succeeded, but the response indicates an error");
                }
            })
            .catch((error) => {
                console.error("API request error:", error);
            });
      return {
        PlayVideo,
      };
    };
    
    const PlayEpisode = (data_id: number, watching_time: string = "") => {

        const Tvshow = "tvshow";
        const requestData = {
            profile_id: session?.profile_id, // Use profileId here
            data_id: data_id,
            data_type: "episode"
        };
    
        const requestBody = JSON.stringify(requestData);
    
        fetch(`${API_URL_BASE}${API_URLS.PLAY_VIDEO}`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-type": "application/json",
                'X-APP-SECRET': X_APP_SECRET,
                Authorization: "Bearer " + token,
            },
            body: requestBody,
        })
            .then((result) => {
                if (!result.ok) {
    
                    throw new Error("API request failed");
                }
                return result.json();
            })
            .then((resp) => {
                if (resp.response === true) {
    
                    const encodedVideoUrl = encodeURIComponent(resp.data.video_url);
                    navigate(`/video/watch/${encodedVideoUrl}/${data_id}/${Tvshow}/${watching_time}/`);
    
                } else {
                    alert("API request succeeded, but the response indicates an error");
                }
            })
            .catch((error) => {
                console.error("API request error:", error);
            });
    }
    return {
        PlayVideo,
        PlayEpisode, // Include the additional function in the return object
      };
  };


export function useWatchlist({ Moviedata, session, token }: WatchlistConfig) {
  const [watchlistStatus, setWatchlistStatus] = useState<Record<number, boolean>>({});
  const [NewWatchlistStatus, setNewWatchlistStatus] = useState<Record<number, boolean>>({});
  const [key, setKey] = useState(0);
  const FeaturesList = FeaturesData();
  const UserLocation = UserLocationData();
  const WatchlistAdd = (data_id: number, data_type: string) => {
        const action = watchlistStatus[data_id] ? 0 : 1; // Toggle action
        const requestData = {
            profile_id: session?.profile_id, // Use profileId here
            data_type: data_type == "movie" ? "movie" : "tvshow",
            data_id: data_id,
            action: action,
            country_code: UserLocation.country_code
        };
        const requestBody = JSON.stringify(requestData);

        fetch(`${API_URL_BASE}${API_URLS.WATCHLIST_ADD_REMOVE}`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-type": "application/json",
                'X-APP-SECRET': X_APP_SECRET,
                Authorization: "Bearer " + token,
                
            },
            body: requestBody,
        })
            .then((result) => result.json())
            .then((resp) => {
                if (resp.response === true) {

                    setKey((prevKey) => prevKey + 1);

                    setWatchlistStatus((prevStatus: any) => ({
                        ...prevStatus,
                        [data_id]: action === 1, // If action is 1, the item is not in the watchlist, otherwise it's in the watchlist
                    }));
                }
            })
            .catch((error) => {
                console.error("Error while updating watchlist:", error);
            });
  };

  useEffect(() => {
    const filteredData =  Moviedata && Moviedata.filter((item) => item.is_watchlist === "true");
    const watchlistStatusDATA: Record<number, boolean> = {};
    filteredData && filteredData.forEach((item) => {
      watchlistStatusDATA[item.data_id] = true;
    });
    setWatchlistStatus(watchlistStatusDATA);
  }, [Moviedata]);

  useEffect(() => {
    const initialWatchlistStatus: Record<number, boolean> = {};
    Moviedata &&  Moviedata.forEach((item) => {
      initialWatchlistStatus[item.data_id] = item.is_watchlist === "true";
    });
    setNewWatchlistStatus(initialWatchlistStatus);
  }, [key]);

  return {
    watchlistStatus,
    NewWatchlistStatus,
    WatchlistAdd,
  };
}

export function useHomePageApi() {
    const [loading, setLoading] = useState(true);
    const [Moviedata, setMoviedata] = useState<Moviedatatype[] | any[]>([]);
    const [CheckMovieData, setCheckMovieData] = useState(true);
    const [TrendingMoviedata, setTrendingMoviedata] = useState<Moviedatatype[] | any[]>([]);
    const [Trendingloading, setTrendingloading] = useState(true);
    const [TrendingMovieCheck, setTrendingMovieCheck] = useState(true);
    const [TrendingTvshow, setTrendingtvshow] = useState<Tvshowdatatype[] | any[]>([]);
    const [TrendingTvshowCheck, setTrendingTvshowCheck] = useState(true);
    const [TrendingTvshowLoading, setTrendingTvshowLoading] = useState(true);
    const [NewreleseTvshow, setNewreleseTvshow] = useState<Tvshowdatatype[] | any[]>([]);
    const [NewreleseTvshowLoading, setNewreleseTvshowLoading] = useState(true);
    const [TopSearchMovie, setTopSearchMovie] = useState<Moviedatatype[] | any[]>([]);
    const [TopSearchMovieLoading, setTopSearchMovieLoading] = useState(true);
    const [NewreleseTvshowCheck, setNewreleseTvshowCheck] = useState(true);

    const FeaturesList = FeaturesData();
    const UserLocation = UserLocationData();

    useEffect(() => {
        var session = Session();
        var user = User();
        const token = user?.data.token;

        const requestData = {
            profile_id: session?.profile_id,
            paginate: false,
            limit: -1,
            country_code: UserLocation.country_code
            
        };

        const requestBody = JSON.stringify(requestData);
        fetch(`${API_URL_BASE}${API_URLS.NEW_RELEASE_MOVIES}`, {
            method: 'POST',
            headers: {
                Accept: "application/json",
                "Content-type": "application/json",
                'X-APP-SECRET': X_APP_SECRET,
                Authorization: "Bearer " + token,
            },
            body: requestBody,
        })
        .then((result) => result.json())
        .then((resp) => {
            if (resp.response === true) {
                
                setMoviedata(resp.data);
                setLoading(false);
            } else {
                setCheckMovieData(false)
            }
        })
        .catch((error) => {
            console.error('API request error:', error);
        });

    
        fetch(`${API_URL_BASE}${API_URLS.TRENDING_MOVIES}`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-type": "application/json",
                'X-APP-SECRET': X_APP_SECRET,
                Authorization: "Bearer " + token,
            },
            body: requestBody,
        })
        .then((result) => result.json())
        .then((resp) => {
            if (resp.response === true) {

                setTrendingMoviedata(resp.data);
                setTrendingloading(false)
            } else {
                setTrendingMovieCheck(false)
            }
        })
        .catch((error) => {
            console.error("API request error:", error);
        });

        fetch(`${API_URL_BASE}${API_URLS.TVSHOW_TRENDING}`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-type": "application/json",
                'X-APP-SECRET': X_APP_SECRET,
                Authorization: "Bearer " + token,
            },
            body: requestBody,
        })
        .then((result) => result.json())
        .then((resp) => {
            if (resp.response === true) {
                setTrendingtvshow(resp.data);
                setTrendingTvshowLoading(false)
            } else {
                setTrendingTvshowCheck(false)
            }
        })
        .catch((error) => {
            console.error("API request error:", error);
        });

        fetch(`${API_URL_BASE}${API_URLS.TVSHOW_NEW_RELEASE}`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-type": "application/json",
                'X-APP-SECRET': X_APP_SECRET,
                Authorization: "Bearer " + token,
            },
            body: requestBody,
        })
        .then((result) => result.json())
        .then((resp) => {
            if (resp.response === true) {

                setNewreleseTvshow(resp.data);
                setNewreleseTvshowLoading(false)

            } else {
                setNewreleseTvshowCheck(false)
            }
        })
        .catch((error) => {
            console.error("API request error:", error);
        });

    }, []);  
            return {
        loading,
        CheckMovieData,
        Moviedata,
        TrendingMoviedata,
        TrendingMovieCheck,
        Trendingloading,
        TrendingTvshowCheck,
        TrendingTvshow,
        TrendingTvshowLoading,
        NewreleseTvshow,
        NewreleseTvshowLoading,
        NewreleseTvshowCheck
    };
}










    


