import React, { useState, useEffect } from 'react'
import Carousel from 'react-bootstrap/Carousel';
import { CarouselItem, Container } from 'react-bootstrap';
import OwlCarousel from 'react-owl-carousel2';
import Form from 'react-bootstrap/Form';
import Header from './Layout/Header';
import Footer from './Layout/Footer';
import Modal from 'react-bootstrap/Modal';
import TvshowGenres from './Layout/TvshowGenres';
import { FeaturesData, FormatTimeWithHourMinute, Session, User, UserLocationData, usePlayVideo } from './Helper';
import { Tvshowdatatype } from './Model/Interface';
import { TvShowdataTypes } from './Model/Interface';
import { sliderdatatype } from './Model/Interface';
import { API_URL_BASE, STRIPE_TEST_KEY, X_APP_SECRET, headers } from './utils';
import { API_URLS } from './utils';
import { WatchlistStatusInterface, FavoriteStatusInterface } from './Model/Interface';
import { Link, useNavigate, useParams } from 'react-router-dom'
import routes from './Routes';
import { Elements } from '@stripe/react-stripe-js';
import ItemPurchase from './ItemPurchase';
import { loadStripe } from '@stripe/stripe-js';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { isUndefined } from 'lodash';

export default function ViewAllTvshow() {

    var { types } = useParams();

    function showSuccessNotification(message: any) {
        toast.success(message, {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    }

    function showErrorNotification(message: any) {
        toast.error(message, {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    }

    const [showModal, setshowModal] = useState(false);
    const handleClosee = () => setshowModal(false);
    const loginModal = () => setshowModal(true);

    const [show, setShow] = useState(false);
    var user = User();
    var session = Session();
    const token = user?.data.token
    const [Tvshow, setTvshowdata] = useState<Tvshowdatatype[] | any[]>([]);
    const [MoreLikeThisTVShowdata, setMoreLikeThisTVShowdata] = useState<TvShowdataTypes>()
    const [modeldata, setModeldata] = useState<Tvshowdatatype>();
    const [episode, setEpisode] = useState<any>();
    const [FirstepisodeID, setFirstepisodeID] = useState<number>();
    const [tvshowid, setTvshowid] = useState<any>();
    const [loading, setLoading] = useState(true);
    const [SeasonId, setSeasonId] = useState("");
    const [key, setKey] = useState(0);
    const navigate = useNavigate();
    const handleClose = () => {
        setShow(false);
        setEpisodeOrSeasonchCheck(true)
    }
    const [heading, setheading] = useState("");
    const [EpisodeLoading, setEpisodeLoading] = useState(true);
    const [EpisodeOrSeasonchCheck, setEpisodeOrSeasonchCheck] = useState(true); 
    // const { PlayEpisode } = usePlayVideo();
    const FeaturesList = FeaturesData();
    const UserLocation = UserLocationData();
    const payPerView        =  FeaturesList?.data.pay_per_view;
    const userSubscription  =  user?.data.user_subscription;

    const [showTwo, setShowTwo] = useState(false);
    const [currencySymbol, setcurrencySymbol] = useState("");
    const [tvShowPrice, settvShowPrice] = useState("");
    const [itemVideoId, setitemVideoId] = useState("");
    const [itemDataType, setitemDataType] = useState("");
    const [itemPrice, setitemPrice] = useState("");
    const [itemTitle, setitemTitle] = useState("");
    const [showThree, setShowThree] = useState(false);
    const [paymentLoder, setpaymentLoder] = useState(false);
    const stripePromise = loadStripe(STRIPE_TEST_KEY);




    const handleShowTwo = (data_id:any,data_type:string) =>{

        if (!token) {
            
            handleClose()
            
            setshowModal(true)
            return;
        }

        setShowTwo(true);
        setitemVideoId(data_id)
        setitemDataType(data_type)
    }

    const handleCloseTwo = () => {
        setShowTwo(false);
    }


    let movie_name_modal_close = () =>{
        setShowTwo(false)

        if(itemDataType == 'tvshow'){
            setShow(true);
            setEpisodeLoading(false)
        }
    }


    const handleShowThree = () => {
        setShowThree(true);
        setShowTwo(false);
        setShow(false);
        handleClose();
    }

    const handleCloseThree = () =>{
        setpaymentLoder(false);
        setShowThree(false)
    }


    
    const updateVideoPaymentStatus = () => {

        handleCloseThree()
        
        if(itemDataType == 'tvshow'){
            if (modeldata && typeof modeldata === 'object' && 'premium' in modeldata) {
                
                setModeldata({
                    ...modeldata,
                    premium: {
                    ...modeldata.premium,
                    is_paid: true,
                    },
                });

                movie_name_modal_close();
                // PlayVideo(Number(itemVideoId))
            } else {
                
                console.error('Invalid modeldata structure');
            }
            
        }
    };

    const errorInVideoPayment = () => {

        handleCloseThree()
        movie_name_modal_close();
        
    };

    const loderShow = () => {
        setpaymentLoder(true);
    };

    const loderHide = () => {
        
        // setShowThree(false)
        // setpaymentLoder(false);
    };
    const handleShow = (tvshow_id: number) => {
        setShow(true);
        setModeldata(undefined);
        setEpisode(undefined);
        setMoreLikeThisTVShowdata(undefined);
        setEpisodeOrSeasonchCheck(true)
        const requestData = {
            profile_id: session?.profile_id, // Use profileId here
            tvshow_id: tvshow_id,
            country_code: UserLocation.country_code
        };
        const requestBody = JSON.stringify(requestData);

        fetch(`${API_URL_BASE}${API_URLS.TVSHOW_DETAILS}`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-type": "application/json",
                'X-APP-SECRET': X_APP_SECRET,
                Authorization: "Bearer " + token,
            },
            body: requestBody,
        }).then((result) => {

            result.json().then((resp) => {
                if (resp.response == true) {
                    setModeldata(resp.data);
                    setTvshowid(tvshow_id);

                    setEpisodeLoading(false)
                    setitemTitle(resp.data.title)
                    
                    if(payPerView ==  true){
                        setcurrencySymbol(resp.data.premium.currency_symbol)
                        if(userSubscription == true){   

                            settvShowPrice(resp.data.premium.subscriber_price)
                            setitemPrice(resp.data.premium.subscriber_price)

                        }else{
                            settvShowPrice(resp.data.premium.nonsubscriber_price)
                            setitemPrice(resp.data.premium.nonsubscriber_price)
                        }
                    }

                    if (resp.data['season'].length > 0) {
                        setSeasonId(resp.data['season'][0]['season_id']);
                    } else {
                        setEpisodeOrSeasonchCheck(false)
                    }
                }else{
                    setEpisodeOrSeasonchCheck(false)
                }
            })
        })

        fetch(`${API_URL_BASE}${API_URLS.TVSHOW_MORE_LIKE_THIS}`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-type": "application/json",
                'X-APP-SECRET': X_APP_SECRET,
                Authorization: "Bearer " + token,
            },
            body: requestBody,

        }).then((result) => {

            result.json().then((resp) => {

                if (resp.response == true) {
                    setMoreLikeThisTVShowdata(resp.data.tvshows)
                } else {
                    console.warn("Responce False Please Tru Again")
                }
            })
        })
    }

    const PlayEpisode = (data_id: number, TVShow_id : number | null = null,watching_time: string = "") => {
        const data_type: string = "episode";
        navigate(routes.VideoWatch, { state: { data_id,data_type,watching_time,TVShow_id } });
    };

    useEffect(() => {
        setEpisode(undefined);
        const Episoderequest = {
            profile_id: session?.profile_id, // Use profileId here
            tvshow_id: tvshowid,
            season_id: SeasonId,
            paginate: false,
            limit: -1,
            order_by: ["created_at", "asc"],
            country_code: UserLocation.country_code
        };

        const EpisoderequestBody = JSON.stringify(Episoderequest);

        fetch(`${API_URL_BASE}${API_URLS.TVSHOW_SEASON_EPISODE}`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-type": "application/json",
                'X-APP-SECRET': X_APP_SECRET,
                Authorization: "Bearer " + token,
            },
            body: EpisoderequestBody,
        }).then((result) => {

            result.json().then((resp) => {

                if (resp.response == true) {
                    setEpisode(resp.data);
                    setFirstepisodeID(resp.data[0]['data_id'])
                    setEpisodeLoading(false)
                }else {
                    setEpisodeOrSeasonchCheck(false)
                }
            })
        })
    }, [tvshowid, SeasonId]);

    useEffect(() => {

        setLoading(true);

        const requestData = {
            profile_id: session?.profile_id, // Use profileId here
            paginate: false,
            country_code: UserLocation.country_code
        };

        const requestBody = JSON.stringify(requestData);

        if (types == "NewReleaseTvshow") {

            setheading("New Release TV Shows")


            fetch(`${API_URL_BASE}${API_URLS.TVSHOW_NEW_RELEASE}`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-type": "application/json",
                    'X-APP-SECRET': X_APP_SECRET,
                    Authorization: "Bearer " + token,
                },
                body: requestBody,
            })
                .then((result) => {
                    if (!result.ok) {
                        throw new Error("API request failed");
                    }
                    return result.json();
                })
                .then((resp) => {
                    if (resp.response === true) {

                        setTvshowdata(resp.data);
                        setLoading(false);

                    } else {
                        alert("API request succeeded, but the response indicates an error");
                    }
                })
                .catch((error) => {
                    console.error("API request error:", error);
                });
        } else {
            setheading("Trending Now TV Shows")
            fetch(`${API_URL_BASE}${API_URLS.TVSHOW_TRENDING}`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-type": "application/json",
                    'X-APP-SECRET': X_APP_SECRET,
                    Authorization: "Bearer " + token,
                },
                body: requestBody,
            })
                .then((result) => {
                    if (!result.ok) {
                        throw new Error("API request failed");
                    }
                    return result.json();
                })
                .then((resp) => {
                    if (resp.response === true) {
                        setTvshowdata(resp.data);
                        setLoading(false);
                    } else {
                        alert("API request succeeded, but the response indicates an error");
                    }
                })
                .catch((error) => {
                    console.error("API request error:", error);
                });

        }

    }, []);

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setEpisode(undefined);
        setEpisodeLoading(true)
        setEpisodeOrSeasonchCheck(true)
        const SeasonsId = event.target.value;

        const Episoderequest = {
            profile_id: session?.profile_id, // Use profileId here
            tvshow_id: tvshowid,
            season_id: SeasonsId,
            paginate: false,
            limit: -1,
            order_by: ["created_at", "asc"],
            country_code: UserLocation.country_code
        };
        const EpisoderequestBody = JSON.stringify(Episoderequest);

        fetch(`${API_URL_BASE}${API_URLS.TVSHOW_SEASON_EPISODE}`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-type": "application/json",
                'X-APP-SECRET': X_APP_SECRET,
                Authorization: "Bearer " + token,
            },
            body: EpisoderequestBody,
        }).then((result) => {

            result.json().then((resp) => {

                if (resp.response == true) {

                    setEpisode(resp.data);
                    setEpisodeLoading(false)
                }else{
                    setEpisodeOrSeasonchCheck(false)
                }
            })
        })

    };

    const [watchlistStatus, setWatchlistStatus] = useState<WatchlistStatusInterface>([]);
    const [WatchlistStatusInterface, setNewWatchlistStatus] = useState<WatchlistStatusInterface>([]);


    const WatchlistAdd = (data_id: number) => {
        if(!token){
            handleClose()
            setshowModal(true)
            return; 
        }
        const action = watchlistStatus[data_id] ? 0 : 1; // Toggle action
        const requestData = {
            profile_id: session?.profile_id, // Use profileId here
            data_type: "tvshow",
            data_id: data_id,
            action: action,
            country_code: UserLocation.country_code
        };
        const requestBody = JSON.stringify(requestData);

        fetch(`${API_URL_BASE}${API_URLS.WATCHLIST_ADD_REMOVE}`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-type": "application/json",
                'X-APP-SECRET': X_APP_SECRET,
                Authorization: "Bearer " + token,
            },
            body: requestBody,
        })
            .then((result) => result.json())
            .then((resp) => {
                if (resp.response === true) {

                    setKey((prevKey) => prevKey + 1);

                    setWatchlistStatus((prevStatus: any) => ({
                        ...prevStatus,
                        [data_id]: action === 1, // If action is 1, the item is not in the watchlist, otherwise it's in the watchlist
                    }));
                }
            })
            .catch((error) => {
                console.error("Error while updating watchlist:", error);
            });

    };

    useEffect(() => {

        const filteredData = Tvshow && Tvshow.filter((item) => item.is_watchlist === "true");
        const watchlistStatusDATA: any = [];
        filteredData.forEach((item) => {
            watchlistStatusDATA[item.data_id] = true;
        });
        setWatchlistStatus(watchlistStatusDATA);

    }, [Tvshow]);


    useEffect(() => {

        const initialWatchlistStatus: any = {};
        Tvshow.forEach((item) => {
            initialWatchlistStatus[item.data_id] = item.is_watchlist === "true";
        });
        setNewWatchlistStatus(initialWatchlistStatus);


    }, [key]);

    const [favoriteStatus, setFavoriteStatus] = useState<FavoriteStatusInterface>([]);
    const [NewFavoriteStatus, setNewFavotiteStatus] = useState<FavoriteStatusInterface>([]);

    const FavoriteAdd = (data_id: number) => {
        
        if(!token){
            handleClose()
            setshowModal(true)
            return; 
        }
        const action = favoriteStatus[data_id] ? 0 : 1; // Toggle action

        const requestData = {
            profile_id: session?.profile_id, // Use profileId here
            data_type: "tvshow",
            data_id: data_id,
            action: action,
            country_code: UserLocation.country_code
        };
        const requestBody = JSON.stringify(requestData);

        fetch(`${API_URL_BASE}${API_URLS.FAVORITE_ADD_REMOVE}`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-type": "application/json",
                'X-APP-SECRET': X_APP_SECRET,
                Authorization: "Bearer " + token,
            },
            body: requestBody,
        })
            .then((result) => result.json())
            .then((resp) => {
                if (resp.response === true) {

                    setKey((prevKey) => prevKey + 1);

                    setFavoriteStatus((prevStatus: any) => ({
                        ...prevStatus,
                        [data_id]: action === 1, // If action is 1, the item is not in the watchlist, otherwise it's in the watchlist
                    }));
                }
            })
            .catch((error) => {
                console.error("Error while updating watchlist:", error);
            });

    };

    useEffect(() => {

        const filteredData = Tvshow.filter((item) => item.is_favourite === "true");
        const FavoriteStatusDATA: any = [];
        filteredData.forEach((item) => {
            FavoriteStatusDATA[item.data_id] = true;
        });
        setFavoriteStatus(FavoriteStatusDATA);

    }, [Tvshow]);


    useEffect(() => {

        const initialWatchlistStatus: any = {};
        Tvshow.forEach((item) => {
            initialWatchlistStatus[item.data_id] = item.is_favourite === "true";
        });
        setNewFavotiteStatus(initialWatchlistStatus);


    }, [key]);

    return (
        <div>
            <Header />
            <ToastContainer />
            <Container fluid className='cust_container inner_section'>
                <div className="account_setting_title">
                    <h3>{heading}</h3>
                </div>
                <div className="view_all_section">
                    <div className="slick-slider episode_details">

                        {
                            !loading ?
                                Array.isArray(Tvshow) && Tvshow.map((item) => (
                                    <div className='item_box'>
                                        <div onClick={() => handleShow(item.data_id)}>
                                            <img className="img" src={item.media_poster} />
                                        </div>
                                        <div className="item_img_details">
                                            <div className="item_img_header" key={item.title}>
                                                <h5>{item.title}</h5>
                                                <ul>
                                                <li onClick={() => handleShow(item.data_id)}><img src="/img/play-icon.png" alt="" /></li>
                                                {watchlistStatus && watchlistStatus[item.data_id] ? (
                                                        <li key={item.data_id} onClick={() => WatchlistAdd(item.data_id)}>
                                                            {watchlistStatus[item.data_id] ? (
                                                                <img src="/img/check-white.png" alt="" />
                                                            ) : (
                                                                <img src="/img/add-icon.png" alt="" />
                                                            )}

                                                        </li>
                                                    ) : (
                                                        <li onClick={() => WatchlistAdd(item.data_id)}>
                                                            {Object.keys(watchlistStatus).length === 0 && item.is_watchlist == "true" ? (
                                                                <img src="/img/check-white.png" alt="" />
                                                            ) : (
                                                                <img src="/img/add-icon.png" alt="" />
                                                            )}
                                                        </li>
                                                    )}
                                                    <li key={item.data_id} onClick={() => FavoriteAdd(item.data_id)}>
                                                        {favoriteStatus[item.data_id] ? (
                                                            <li><img src="/img/fav.png" alt="" /></li>
                                                        ) : (
                                                            <li><img src="/img/like stroke.png" alt="" /></li>
                                                        )}

                                                    </li>
                                                 
                                                </ul>
                                            </div>
                                            <div className="item_img_number">
                                                <h3> {item.maturity_level}</h3>
                                                <h4>{item.release_date ? (new Date(item.release_date)).getFullYear() : ""} </h4>
                                            </div>
                                        </div>
                                        {
                                        item.is_premium == true && item.is_paid == false ? 
                                            <div className='premium_icon'>
                                            <Link to={"#"}><img src='img/premium_icon.png' /></Link>
                                        </div>: "" 
                                        }
                                    </div>
                                )) : (
                                    Array.from({ length: 10 }).map((_, index) => (
                                        <div className="shimmer_card" key={index}></div>
                                    ))
                                )
                        }
                    </div>
                </div>
            </Container>
            <Footer />
            <Modal className='series_modal' show={show} onHide={handleClose} backdrop="static" size="lg" keyboard={false} centered>
                <div className="modal_close" onClick={handleClose}>
                    <Link to={""}><img src="/img/modal_close.png" alt=""></img></Link>
                </div>

                <Modal.Body>
                    <div className="series_img">

                        {modeldata ? (
                            <img src={modeldata ? modeldata['media_banner'] : 'img/default-banner.png'} className="series_big_imf" alt=""></img>
                        ) : (
                            <div className='series_img_shimmer'></div>
                        )}
                        <div className="series_tool">
                            <div className="series_name">
                                <h2>{modeldata ? modeldata['title'] : ''}</h2>
                            </div>
                            <div className="series_controller">
                                <div className="series_left_controller">
                                {modeldata && Object.keys(modeldata['premium']).length > 0  && modeldata['premium']['is_paid'] == false ?
                                    // <button className="music_play_btn" onClick={handleShowTwo}>Pay & Play</button>
                                    <button className="music_play_btn" onClick={() => handleShowTwo(modeldata['data_id'],'tvshow')}><img src="img/play_icon.jpg"></img>Pay & Play</button>
                                    :<button className="music_play_btn" onClick={() => FirstepisodeID && modeldata && PlayEpisode(FirstepisodeID, modeldata['data_id'])}><img src="img/play_icon.jpg"></img>Play</button>}


                                    {watchlistStatus && modeldata && watchlistStatus[modeldata['data_id']] ? (
                                        <div onClick={() => WatchlistAdd(modeldata['data_id'])}>
                                            {watchlistStatus[modeldata['data_id']] ? (

                                                <button className="music_small_btn"><img src="/img/check-white.png"></img></button>
                                            ) : (
                                                <button className="music_small_btn"><img src="/img/music_add.png"></img></button>
                                            )}

                                        </div>
                                    ) : (
                                        <div onClick={() => modeldata && WatchlistAdd(modeldata['data_id'])}>
                                            {modeldata && Object.keys(watchlistStatus).length === 0 && modeldata && modeldata['is_watchlist'] === "true" ? (
                                                <button className="music_small_btn"><img src="/img/check-white.png" alt="" /></button>
                                            ) : (
                                                <button className="music_small_btn"><img src="/img/music_add.png" alt="" /></button>
                                            )}
                                        </div>
                                    )}

                                    {favoriteStatus && modeldata && favoriteStatus[modeldata['data_id']] ? (
                                        <div onClick={() => FavoriteAdd(modeldata['data_id'])}>
                                            {favoriteStatus[modeldata['data_id']] ? (

                                                <button className="music_small_btn"><img src="/img/fav.png"></img></button>
                                            ) : (
                                                <button className="music_small_btn"><img src="/img/like stroke.png"></img></button>
                                            )}

                                        </div>
                                    ) : (
                                        <div onClick={() => modeldata && FavoriteAdd(modeldata['data_id'])}>
                                            {modeldata && Object.keys(favoriteStatus).length === 0 && modeldata && modeldata['is_watchlist'] === "true" ? (
                                                <button className="music_small_btn"><img src="/img/fav.png" alt="" /></button>
                                            ) : (
                                                <button className="music_small_btn"><img src="/img/like stroke.png" alt="" /></button>
                                            )}
                                        </div>
                                    )}




                                </div>
                                <div className="series_right_controller">
                                    <button className='music_small_btn'><img src="/img/music_volum.png" className=" m-0 d-none"></img></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="series_full_description">
                        {
                            modeldata ? (
                                <div className="series_details_content">
                                    <div className="series_description">
                                        <div className="more_second_section mt-0">
                                            <h3> {modeldata ? modeldata['maturity_level'] : ''}</h3>
                                            <h4>{modeldata && modeldata['release_date'] ? (new Date(modeldata['release_date'])).getFullYear() : ""} </h4>
                                            <h5 className='movie_price'>
                                                {!isUndefined(tvShowPrice) ? currencySymbol + tvShowPrice: ""}
                                            </h5>
                                        </div>
                                        <p>{modeldata ? modeldata['description'] : ''}</p>
                                    </div>
                                    <div className="series_categories">
                                        <h4>Cast & Crew : <span className="white_text">
                                            {modeldata && modeldata['celebs'] && Array.isArray(modeldata['celebs']) ? (modeldata['celebs'] as { title: string }[]).map((cast) => cast.title).join(', ') : ""}
                                        </span></h4>
                                        <h4>Genres : <span className="white_text"> {modeldata && modeldata['genres'] && Array.isArray(modeldata['genres']) ? (modeldata['genres'] as { genre_title: string }[]).map((genre) => genre.genre_title).join(', ') : ""}</span></h4>
                                    </div>
                                </div>
                            ) : (
                                null
                            )
                        }
                        <div className="series_episode_details">
                            <div className="series_episode_header">
                                <h3>Episode</h3>
                                <div className='genres_section'>
                                    <h3>Seasons</h3>
                                    <div className="select_box">
                                        <Form.Select aria-label="Default select example" className='cust_seleted' onChange={handleSelectChange}>
                                            {
                                                modeldata && modeldata.season && modeldata.season.length > 0 ? (
                                                    modeldata.season.map((season: any, index: any) => (

                                                        <option key={season.season_id} value={season.season_id} > {season.title}</option>
                                                    ))
                                                ) : (
                                                    <p style={{ color: "red" }}>No Seasons available</p>
                                                )
                                            }

                                        </Form.Select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="series_episode_list">
                            {
                                EpisodeOrSeasonchCheck === true ? (
                                    EpisodeLoading === false && episode && episode.length > 0 ? (
                                        episode.map((episode: any, index: any) => (
                                            <div key={index} className="series_episode_list_box" onClick={() => modeldata &&  PlayEpisode(episode.data_id,modeldata['data_id'])}>
                                                <div className="series_episode_list_box_details">
                                                    <h4>{episode.episode_number}</h4>
                                                    <div className="episode_details_img">
                                                        <img src={episode.media_banner} className="episode_img" alt={episode.title} />
                                                        <a href={episode.media_content}>
                                                            <img src="img/play_video.png" className="play_video_icon" alt="Play Video" />
                                                        </a>
                                                    </div>
                                                    <div className="series_episode_list_box_description">
                                                        <h3>{episode.title}</h3>
                                                        <p>{episode.description}</p>
                                                    </div>
                                                </div>
                                                <div className="series_episode_list_box_min">
                                                    <h2>{FormatTimeWithHourMinute(episode.duration)}</h2>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        Array.from({ length: 3 }).map((_, index) => (
                                            <div className='series_episode_list_box_shimmer' key={index}></div>
                                        ))
                                    )
                                ) : (
                                    <h5 className='white_text'>Episode Not Found.</h5>
                                )
                            }

                        </div>
                        <div className="more_like_this">
                            <div className="more_like_this_title">
                                <h3>More Like This</h3>
                            </div>
                            <div className="row">

                                {
                                    Array.isArray(MoreLikeThisTVShowdata) && MoreLikeThisTVShowdata.length > 0 ? (
                                        MoreLikeThisTVShowdata.map((item) => (
                                            <div className="col-lg-4 col-md-4 col-sm-6">
                                                <div className="more_like_box" >
                                                    <div className="more_like_box_img" onClick={() => handleShow(item.data_id)}>
                                                        <img src={item.media_banner}></img>
                                                    </div>
                                                    {
                                                        item.is_premium == true && item.is_paid == false ? 
                                                        <div className='premium_icon'>
                                                            <Link to={"#"}><img src='img/premium_icon.png' /></Link>
                                                        </div>: "" 
                                                    }
                                                    <div className="more_like_content_box">
                                                        <div className="more_like_left_info">
                                                            <h4>{item.title}</h4>
                                                            {watchlistStatus && watchlistStatus[item.data_id] ? (
                                                                <li key={item.data_id} onClick={() => WatchlistAdd(item.data_id)}>
                                                                    {watchlistStatus[item.data_id] ? (
                                                                        <img src="/img/check-white.png" alt="" />
                                                                    ) : (
                                                                        <img src="/img/add-icon.png" alt="" />
                                                                    )}

                                                                </li>
                                                            ) : (
                                                                <li onClick={() => WatchlistAdd(item.data_id)}>
                                                                    {Object.keys(watchlistStatus).length === 0 && item.is_watchlist == "true" ? (
                                                                        <img src="/img/check-white.png" alt="" />
                                                                    ) : (
                                                                        <img src="/img/add-icon.png" alt="" />
                                                                    )}
                                                                </li>
                                                            )}
                                                        </div>
                                                        <div className="more_second_section">
                                                            <h3> {item.maturity_level}</h3>
                                                            <h4>
                                                                {item.release_date ? (new Date(item.release_date)).getFullYear() : ""}
                                                            </h4>
                                                        </div>
                                                        <div className="more_second_description">
                                                            <p>{item.description}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <div className="no-movies-found-message">
                                        </div>
                                    )
                                }
                            </div>
                            <div className="more_img_load">
                                <Link to={""}><img src="/img/down_arrowpng.png" alt=""></img></Link>
                            </div>
                        </div>
                        <div className="about_movie">
                            <div className="more_like_this_title">
                            <h3>About {modeldata ? modeldata['title'] : ''}</h3>
                            </div>
                            <div className="about_movie_details">
                                <div className="series_categories">
                                    <h4>Cast & Crew : <span className="white_text">{modeldata && modeldata['celebs'] && Array.isArray(modeldata['celebs']) ? (modeldata['celebs'] as { title: string }[]).map((cast) => cast.title).join(', ') : ""}</span></h4>
                                    <h4>Genres : <span className="white_text">{modeldata && modeldata['genres'] && Array.isArray(modeldata['genres']) ? (modeldata['genres'] as { genre_title: string }[]).map((genre) => genre.genre_title).join(', ') : ""}</span></h4>
                                    <h4>Maturity Rating: <span className="white_text white_text_border"> {modeldata ? modeldata['maturity_level'] : ''}</span></h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal className='login_modal' show={showModal} onHide={handleClosee} centered   backdrop="static">
                <Modal.Body>
                    <div className='login_modal_img'>
                        <img src='/img/login.svg'></img>            
                    </div>
                    <div className='login_modal_text text-center'>
                         <h3>Login Now</h3>
                         <h4>You have to login to use this feature</h4>       
                    </div>
                    <div className='login_modal_btn'>
                         <Link to={routes.signin}>Login</Link>
                         <Link onClick={handleClosee}  to={""}>Continue</Link>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal className='movie_name_modal' show={showTwo} onHide={handleCloseTwo}  centered backdrop="static" keyboard={false}>
                
                <Modal.Body>
                    <div className='movie_name_close_modal' onClick={movie_name_modal_close}>
                        <Link to={"#"}><img src='img/modal_close.png'/></Link>
                    </div>
                    <div className='movie_name_details'>
                        <div className='movie_name_img'>
                            <img src='img/tv.svg'/>
                        </div>
                        <div className='movine_name_section'>
                            <h3>{itemTitle}</h3>
                            <h4>{currencySymbol}{itemPrice}</h4>
                            <button onClick={handleShowThree} >Continue to Pay</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal className='movie_name_modal' show={showThree} onHide={handleCloseThree}  centered backdrop="static" keyboard={false}>
                <Modal.Body>
                    {paymentLoder == true ? (
                    <div className='loader'>
                            <img src='img/loader.gif' alt="Loading..." />
                    </div>
                    ) : (
                        null
                    )}
                        <>
                            <div className='movie_name_close_modal' onClick={handleCloseThree}>
                                <Link to="#">
                                    <img src='img/modal_close.png' alt="Close Modal" />
                                </Link>
                            </div>
                            <div className='stripe_header'>
                                <h3>Payment for - <span>{itemTitle}</span></h3>
                                <h3>Total Amount - <span>{currencySymbol}{itemPrice}</span></h3>
                            </div>
                            <div className='stripe_details'>
                                {itemDataType && itemVideoId ? (
                                    <Elements stripe={stripePromise}>
                                        <ItemPurchase 
                                            data_id={itemVideoId} 
                                            data_type={itemDataType}  
                                            updatePaymentStatus={updateVideoPaymentStatus} 
                                            errorInVideoPayment={errorInVideoPayment} 
                                            loderShow={loderShow} 
                                            loderHide={loderHide}
                                        />
                                    </Elements>
                                ) : ""}
                            </div>
                        </>
                </Modal.Body>
            </Modal>
        </div>
    )
}