import React from 'react'
import Header from './Layout/Header'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import routes from './Routes'
import { useEffect, useState } from 'react'
import { User } from './Helper'
import { API_URLS, X_APP_SECRET, headers } from './utils'
import { API_URL_BASE } from './utils';
import { UserInfo, UserPlanDetailsInterface } from './Model/Interface'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';


export default function AccountSetting() {

    function showSuccessNotification(message: any) {
        toast.success(message, {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    }

    function showErrorNotification(message: any) {
        toast.error(message, {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    }

    var user = User();
    const token = user?.data.token
    const user_email = user?.data.email
    const [UserProfile, setUserprofile] = useState<UserInfo>();
    const [UserPlanDetails, setUserPlanDetails] = useState<UserPlanDetailsInterface>();
    const [CheckUserPlanDetails, setCheckUserPlanDetails] = useState(true);

    useEffect(() => {

        if (token) {

            fetch(`${API_URL_BASE}${API_URLS.USER_PROFILE_LIST}`, {

                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-type": "application/json",
                    'X-APP-SECRET': X_APP_SECRET,
                    Authorization: "Bearer " + token,
                  },
            }).then((result) => {

                result.json().then((resp) => {

                    if (resp.response == true) {

                        setUserprofile(resp.data)

                    }
                })
            })

            fetch(`${API_URL_BASE}${API_URLS.USER_ACTIVE_PLAN}`, {

                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-type": "application/json",
                    'X-APP-SECRET': X_APP_SECRET,
                    Authorization: "Bearer " + token,
                  },
                  

            }).then((result) => {

                result.json().then((resp) => {
                    if (resp.response == true) {
                        setUserPlanDetails(resp.data)
                    }else{
                        setCheckUserPlanDetails(false)
                    }
                })
            })
        }
    }, [])
    const CancelPlan =  () => {

        fetch(`${API_URL_BASE}${API_URLS.USER_PLAN_CANCEL}`, {

            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-type": "application/json",
                'X-APP-SECRET': X_APP_SECRET,
                Authorization: "Bearer " + token,
            },
        }).then((result) => {

            result.json().then((resp) => {
                
                if (resp.response == true) {
                    showSuccessNotification("Plan Was Successfully Canceled.")
                    setTimeout(() => {
                        window.location.reload();
                    }, 3000);

                }else{
                    showErrorNotification("Please Try Again Latter.")
                }
            })
        })

    }
    return (
        <div>
            <Header />
            <ToastContainer />
            <div className="account_setting">
                <Container fluid className='cust_container'>
                    <div className="account_setting_title">
                        <h3>Account Settings</h3>
                    </div>
                    <div className="account_setting_table px-3">
                        <div className="account_setting_box">
                            <div className="account_setting_left_section">
                                <div className="account_setting_left_first_text">
                                    <h4>Membership & Billing</h4>
                                </div>
                                <div className="account_setting_left_second_text">
                                    <h5>{user_email}</h5>
                                    <h5>Password : **********</h5>
                                </div>
                            </div>
                            <div className="account_setting_right_section change_pwd_text">
                                <Link to={routes.accountChangePassword}>Change Password</Link>
                                <Link to={routes.closeAccount} className='text-red'>Close Account</Link>
                            </div>
                        </div>
                        <div className="account_setting_box">
                            <div className="account_setting_left_section">
                                <div className="account_setting_left_first_text">
                                    <h4>Plan Details</h4>
                                </div>

                                {   
                                    CheckUserPlanDetails?(
                                        UserPlanDetails ? (
                                        <>
                                            <div className="account_setting_left_second_text">
                                                <h5>{UserPlanDetails ? UserPlanDetails['plan_name'] : "" }</h5>
                                                <h5>Start date: {UserPlanDetails ? UserPlanDetails['start_date'] : "" } &nbsp; End date: {UserPlanDetails ? UserPlanDetails['end_date'] : "" }</h5>
                                            </div>
                                            <div className="account_setting_right_section">
                                                
                                            </div>
                                        </>
                                        ):(
                                            null
                                        )                                    
                                    ):(
                                        <div className="account_setting_left_second_text">
                                            <h5>Subscription Not Found.</h5>
                                        </div>
                                    )
                                }                    
                            </div>
                            <div className="account_setting_right_section ">
                            {
                            UserPlanDetails ? (
                                <Link to={""} onClick={CancelPlan}>Cancel Plan</Link>
                            ):(
                                null
                            )

                            }

                                <Link to={"/billing-details"}>Billing details</Link>
                                
                            </div>
                        </div>

                        <div className="account_setting_box">
                            <div className="account_setting_left_section">
                                <div className="account_setting_left_first_text">
                                    <h4>Profiles</h4>
                                </div>
                                <div className="account_setting_left_second_text">
                                    <div className="account_profile_list">
                                        <div className="account_profile_list_box">
                                            {
                                                Array.isArray(UserProfile) && UserProfile.map((item) =>
                                                    <ul>
                                                        <li>
                                                            <div className="profile_img">
                                                                <Link to={""}><img src={item.profile_image} alt=""></img></Link>
                                                            </div>
                                                            <div className="profile_name">
                                                                <a href="#" onClick={(event) => {event.preventDefault();}} >{item.profile_name}</a>
                                                                <a href="#" onClick={(event) => {event.preventDefault();}}>{item.maturity_level == 0 ? "All" : item.maturity_level + '+'}</a>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
}
