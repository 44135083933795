import { Container, Table } from "react-bootstrap";
import Header from "./Layout/Header";


export default function Purchase() {
    return (
        <>
            <div>
            <Header />
            <div className="account_setting">
                <Container fluid className='cust_container'>
                    <div className="account_setting_title">
                        <h3>Purchase History</h3>
                    </div>
                    <div className="account_setting_table pb-0 mt-4 table-responsive purchase_table">
                        <Table striped bordered hover responsive>
                            <thead>
                                <tr>
                                    <th scope="col">Type</th>
                                    <th scope="col">Title</th>
                                    <th scope="col">Price</th>
                                    <th scope="col">Purchase Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Movie</td>
                                    <td>Movie Title</td>
                                    <td>$20</td>
                                    <td>05-05-2024</td>
                                </tr>
                                <tr>
                                    <td>Series</td>
                                    <td>Series Title</td>
                                    <td>$30</td>
                                    <td>06-03-2024</td>
                                </tr>
                                <tr>
                                    <td>Series</td>
                                    <td>Series Title</td>
                                    <td>$25</td>
                                    <td>08-02-2024</td>
                                </tr>
                                <tr>
                                    <td>Movie</td>
                                    <td>Movie Title</td>
                                    <td>$20</td>
                                    <td>05-05-2024</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </Container>
            </div>
        </div>
        </>
    )
}
