import Form from 'react-bootstrap/Form';
import routes from '../Routes'
import { Link } from 'react-router-dom'
import { useNavigate, Navigate } from 'react-router-dom'
import { User, Session } from '../Helper';
import { UserInfo, Genresdatatype } from '../Model/Interface';
import React, { useEffect, useState } from 'react'
import { API_URL_BASE, X_APP_SECRET, headers } from '../utils';
import { API_URLS } from '../utils';

interface MyComponentProps {
  genresId: number;
}

export default function TvshowGenres<MyComponentProps>(props: any) {

  var user = User();
  const token = user?.data.token

  var session = Session();

  const [Genresdata, setGeneresdata] = useState<Genresdatatype>()

  const navigate = useNavigate();
  useEffect(() => {
      const requestData = {
        profile_id: session?.profile_id, // Use profileId here
        paginate: false,
        limit: 20,
        order_by: ["created_at", "asc"]
      };
      const requestBody = JSON.stringify(requestData);
      fetch(`${API_URL_BASE}${API_URLS.GENRES_LIST}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
          'X-APP-SECRET': X_APP_SECRET,
          Authorization: "Bearer " + token,
        },
        body: requestBody
      }).then((result) => {

        result.json().then((resp) => {

          if (resp.response == true) {

            setGeneresdata(resp.data);
          }
        })
      })
  }, []);


  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedGenresId = event.target.value;

    if (selectedGenresId !== 'Genres') {
      navigate(`/genres/wise/tvshow/${selectedGenresId}`);

    }
  };


  return (
    <>
      <div className='genres_section'>
        <h3>TV Shows </h3>
        <div className="select_box">
          <Form.Select aria-label="Default select example" className='cust_seleted' onChange={handleSelectChange}>
            <option> Genres </option>
            {
              Array.isArray(Genresdata) && Genresdata.map((item) => (

                <option key={item.genre_id} value={item.genre_id} selected={props.GenresId && props.GenresId === item.genre_id} >{item.genre_title}</option>

              ))
            }
          </Form.Select>

        </div>
      </div>
    </>
  )
}
