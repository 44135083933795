import React, { useEffect, useState } from 'react'
import Header from './Layout/Header'
import Carousel from 'react-bootstrap/Carousel';
import { CarouselItem, Container } from 'react-bootstrap';
import OwlCarousel from 'react-owl-carousel2';
import routes from './Routes';
import { Link, useNavigate, useParams } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal';
import Genres from './Layout/MovieGenres';
import Footer from './Layout/Footer';
import { FeaturesData, FormatTimeWithHourMinute, Session, User, UserLocationData, useHomePageApi, usePlayVideo } from './Helper';
import { API_URL_BASE, STRIPE_TEST_KEY, X_APP_SECRET, headers } from './utils';
import { API_URLS } from './utils';
import { CountinueWatchdatatype, Moviedatatype, TvShowdataTypes } from './Model/Interface';
import Form from 'react-bootstrap/Form';
import { WatchlistStatusInterface, FavoriteStatusInterface, Tvshowdatatype } from './Model/Interface';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { Elements } from '@stripe/react-stripe-js';
import ItemPurchase from './ItemPurchase';
import { loadStripe } from '@stripe/stripe-js';
import { isUndefined } from 'lodash';


export default function ViewAll() {

    function showSuccessNotification(message: any) {
        toast.success(message, {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    }
    
    function showErrorNotification(message: any) {
        toast.error(message, {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    }
    
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setCheckMoreLikeThisMovie(true);
        setShow(false);
    }
    var { types } = useParams();
    var user = User();
    const token = user?.data.token
    var session = Session();
    const userSubscription  =  user?.data.user_subscription;
    const [loading, setLoading] = useState(true);
    const [MoreLikeThisMoviedata, setMoreLikeThisMoviedata] = useState<Moviedatatype[] | undefined>([]);
    const [heading, setheading] = useState("");
    const [key, setKey] = useState(0);
    const [modeldata, setModeldata] = useState<{
        data_id: number;
        data_type: string;
        title: string;
        description: string;
        maturity_level: string;
        release_date: string;
        media_banner: string;
        media_poster: string;
        genres: { genre_id: string; genre_title: string; genre_image: string }[];
        celebs: any[]; // Adjust the type as needed
        duration: string;
        premium: {
          subscriber_price: string;
          nonsubscriber_price: string;
          currency: string;
          currency_symbol: string;
          is_paid: boolean;
        };
        is_favourite: any;
        is_watchlist: any;
      }>();

    const [tvshowmodeldata, setTvshowmodeldata] = useState<Tvshowdatatype>();
    const navigate = useNavigate();
    const [CountinueWatchdata, setCountinueWatchdata] = useState<CountinueWatchdatatype | undefined>()
    const [continueWatchloading, setcontinueWatchloading] = useState(true);
    const [ContinueWatchCheck, setContinueWatchCheck] = useState(true);

    const [showTVShow, setTVShow] = useState(false);
    const [episode, setEpisode] = useState<any>();
    const [tvshowid, setTvshowid] = useState<any>();
    const [SeasonId, setSeasonId] = useState("");
    const [tvmodelshow, setTvmodelshow] = useState(false);

    const [MoreLikeThisTVShowdata, setMoreLikeThisTVShowdata] = useState<TvShowdataTypes | undefined>()
    const [FirstepisodeID, setFirstepisodeID] = useState<number>();
    const [MorelikeThisLoading, setMorelikeThisLoading] = useState(true);
    const [EpisodeLoading, setEpisodeLoading] = useState(true);
    const [EpisodeOrSeasonchCheck, setEpisodeOrSeasonchCheck] = useState(true);
    const [CheckMoreLikeThisMovie, setCheckMoreLikeThisMovie] = useState(true);

    const [TopSearchMovie, setTopSearchMovie] = useState<Moviedatatype[] | any[]>([]);
    const [TopSearchMovieLoading, setTopSearchMovieLoading] = useState(true);
    const { Moviedata, Trendingloading, TrendingMoviedata, TrendingTvshow, TrendingTvshowLoading, NewreleseTvshow, NewreleseTvshowLoading } = useHomePageApi();
    const FeaturesList = FeaturesData();
    const UserLocation = UserLocationData();

    const [showTwo, setShowTwo] = useState(false);
    const [moviePrice, setmoviePrice] = useState("");
    const [currencySymbol, setcurrencySymbol] = useState("");
    const [tvShowPrice, settvShowPrice] = useState("");
    const [itemVideoId, setitemVideoId] = useState("");
    const [itemDataType, setitemDataType] = useState("");
    const [itemPrice, setitemPrice] = useState("");
    const [itemTitle, setitemTitle] = useState("");
    const [showModal, setshowModal] = useState(false);
    const [showThree, setShowThree] = useState(false);
    const [paymentLoder, setpaymentLoder] = useState(false);
    const stripePromise = loadStripe(STRIPE_TEST_KEY);

    const handleShowTwo = (data_id:any,data_type:string) =>{

        if (!token) {
            if (data_type == "movie") {
                handleClose()
            } else {
                tvshowhandleClose()
            }
            setshowModal(true)
            return;
        }

        setShowTwo(true);
        setitemVideoId(data_id)
        setitemDataType(data_type)
    }
    
    const handleCloseTwo = () => {
        setShowTwo(false);
    }

    let movie_name_modal_close = () =>{
        setShowTwo(false)
        
        if(itemDataType == 'movie'){
            setShow(true)
        }else{
            setTvmodelshow(true)
        }
    }

    const payPerView        =  FeaturesList?.data.pay_per_view;


    const handleShowThree = () => {
        setShowThree(true);
        setShowTwo(false);
        setShow(false)
        setTvmodelshow(false)

    }

    const handleCloseThree = () =>{
        setpaymentLoder(false);
        setShowThree(false)
    }

    const updateVideoPaymentStatus = () => {

        handleCloseThree()
        
        if(itemDataType == 'movie'){
            if (modeldata && typeof modeldata === 'object' && 'premium' in modeldata) {
                
                setModeldata({
                    ...modeldata,
                    premium: {
                    ...modeldata.premium,
                    is_paid: true,
                    },
                });

                movie_name_modal_close();
                // PlayVideo(Number(itemVideoId))
            } else {
                
                console.error('Invalid modeldata structure');
            }
            
        }else{

            if (tvshowmodeldata && typeof tvshowmodeldata === 'object' && 'premium' in tvshowmodeldata && FirstepisodeID) {
                
                setTvshowmodeldata({
                    ...tvshowmodeldata,
                    premium: {
                    ...tvshowmodeldata.premium,
                    is_paid: true,
                    },
                });
                
                movie_name_modal_close();
                // PlayEpisode(FirstepisodeID,Number(itemVideoId))
            }
            
        }
    };

    const errorInVideoPayment = () => {

        handleCloseThree()
        if(itemDataType == 'movie'){
            movie_name_modal_close();
        }else{
            movie_name_modal_close();
        }
    };

    const loderShow = () => {
        setpaymentLoder(true);
    };

    const loderHide = () => {
        
        // setShowThree(false)
        // setpaymentLoder(false);
    };
    

    const PlayVideo = (data_id: number, watching_time: string = "") => {
        const data_type: string = "movie";
        navigate(routes.VideoWatch, { state: { data_id,data_type,watching_time } });
    };

    const PlayEpisode = (data_id: number, TVShow_id : number | null = null,watching_time: string = "") => {
        const data_type: string = "episode";
        navigate(routes.VideoWatch, { state: { data_id,data_type,watching_time,TVShow_id } });
    };

    const tvshowhandleClose = () => {
        setTvmodelshow(false)
        setTvshowid("")
        setSeasonId("")
        setEpisodeLoading(true)
        setEpisodeOrSeasonchCheck(true)
    }
    const state = {
        responsive: {
            325: {
                items: 2,
            },
            425: {
                items: 2,
            },
            575: {
                items: 3,
            },
            767: {
                items: 4,
            },
            991: {
                items: 5,
            },
            1199: {
                items: 5,
            },
            1024: {
                items: 7,
            },
            1920: {
                items: 9,
            },


        },

    }

    const handleShow = (data_id: number) => {
        setShow(true);
        setModeldata(undefined);
        setMoreLikeThisMoviedata(undefined)
        const requestData = {
            profile_id: session?.profile_id, // Use profileId here
            movie_id: data_id,
            country_code: UserLocation.country_code
        };
        const requestBody = JSON.stringify(requestData);

        fetch(`${API_URL_BASE}${API_URLS.MOVIE_DETIALS}`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-type": "application/json",
                'X-APP-SECRET': X_APP_SECRET,
                Authorization: "Bearer " + token,
            },
            body: requestBody,
        }).then((result) => {

            result.json().then((resp) => {

                if (resp.response == true) {
                    setModeldata(resp.data);
                    setitemTitle(resp.data.title)
                    if(payPerView ==  true){

                        setcurrencySymbol(resp.data.premium.currency_symbol)
                        if(userSubscription == true){   

                            setmoviePrice(resp.data.premium.subscriber_price)
                            setitemPrice(resp.data.premium.subscriber_price)

                        }else{
                            setmoviePrice(resp.data.premium.nonsubscriber_price)
                            setitemPrice(resp.data.premium.nonsubscriber_price)
                        }
                    }
                    
                }
            })
        })

        fetch(`${API_URL_BASE}${API_URLS.MORE_LIKE_THIS_MOVIES}`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-type": "application/json",
                'X-APP-SECRET': X_APP_SECRET,
                Authorization: "Bearer " + token,
            },
            body: requestBody,

        }).then((result) => {

            result.json().then((resp) => {

                if (resp.response == true) {

                    setMoreLikeThisMoviedata(resp.data)
                    setMorelikeThisLoading(false)
                } else {
                    setCheckMoreLikeThisMovie(false)
                }
            })
        })

    }


    useEffect(() => {
        setEpisode(undefined);
        const Episoderequest = {
            profile_id: session?.profile_id, // Use profileId here
            tvshow_id: tvshowid,
            season_id: SeasonId,
            paginate: false,
            limit: -1,
            order_by: ["created_at", "asc"],
            country_code: UserLocation.country_code
        };

        const EpisoderequestBody = JSON.stringify(Episoderequest);

        fetch(`${API_URL_BASE}${API_URLS.TVSHOW_SEASON_EPISODE}`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-type": "application/json",
                'X-APP-SECRET': X_APP_SECRET,
                Authorization: "Bearer " + token,
            },
            body: EpisoderequestBody,
        }).then((result) => {

            result.json().then((resp) => {

                if (resp.response == true) {
                    setEpisode(resp.data);
                    setFirstepisodeID(resp.data[0]['data_id'])
                    setEpisodeLoading(false)
                } else {
                    setEpisodeOrSeasonchCheck(false)
                }
            })
        })
    }, [tvshowid, SeasonId]);

    const fetchDataContinueWatch = () => {
        setcontinueWatchloading(true);

        if (types == 'movie') {
            setheading("Continue Watching Movie")
        } else if (types == 'tvshow') {
            setheading("Continue Watching TVshow")
        } else if (types == 'topsearch') {
            setheading("Top Searches")
        }
        else {
            setheading("Continue Watching")
        }


        if (types == 'topsearch') {
            const requestData = {
                profile_id: session?.profile_id,
                paginate: false,
                limit: -1,
                country_code: UserLocation.country_code
            };
            const requestBody = JSON.stringify(requestData);
            fetch(`${API_URL_BASE}${API_URLS.TOP_SEARCH_LIST}`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-type": "application/json",
                    'X-APP-SECRET': X_APP_SECRET,
                    Authorization: "Bearer " + token,
                },
                body: requestBody,
            }).then((result) => {

                result.json().then((resp) => {
                    if (resp.response == true) {
                        setTopSearchMovie(resp.data);
                        setcontinueWatchloading(false);
                    } else {
                        setTopSearchMovieLoading(false)
                    }
                })
            })
        } else {

            setLoading(true)
            setcontinueWatchloading(true);
            setCountinueWatchdata(undefined);
            const requestData = {
                profile_id: session?.profile_id,
                data_type: types == "all" ? "" : types,
                paginate: false,
                limit: -1,
                country_code: UserLocation.country_code
            };
            const requestBody = JSON.stringify(requestData);
            fetch(`${API_URL_BASE}${API_URLS.CONTINUE_WATCHING_LIST}`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-type": "application/json",
                    'X-APP-SECRET': X_APP_SECRET,
                    Authorization: "Bearer " + token,
                },
                body: requestBody,
            })
                .then((result) => {
                    result.json().then((resp) => {
                        
                        if (resp.response === true) {
                            setCountinueWatchdata(resp.data);
                            setcontinueWatchloading(false);
                            setLoading(false)

                        } else {
                            setContinueWatchCheck(false);
                        }
                    });
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });
        }
    };

    useEffect(() => {
        fetchDataContinueWatch();
    }, []);

    const [favoriteStatus, setFavoriteStatus] = useState<FavoriteStatusInterface>([]);
    const [NewFavoriteStatus, setNewFavotiteStatus] = useState<FavoriteStatusInterface>([]);


    const FavoriteAdd = (data_id: number, data_type: string) => {
        const action = favoriteStatus[data_id] ? 0 : 1; // Toggle action

        const requestData = {
            profile_id: session?.profile_id, // Use profileId here
            data_type: data_type == "movie" ? "movie" : "tvshow",
            data_id: data_id,
            action: action,
            country_code: UserLocation.country_code
        };
        const requestBody = JSON.stringify(requestData);

        fetch(`${API_URL_BASE}${API_URLS.FAVORITE_ADD_REMOVE}`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-type": "application/json",
                'X-APP-SECRET': X_APP_SECRET,
                Authorization: "Bearer " + token,
            },
            body: requestBody,
        })
            .then((result) => result.json())
            .then((resp) => {
                if (resp.response === true) {

                    setKey((prevKey) => prevKey + 1);

                    setFavoriteStatus((prevStatus: any) => ({
                        ...prevStatus,
                        [data_id]: action === 1, // If action is 1, the item is not in the watchlist, otherwise it's in the watchlist
                    }));
                }
            })
            .catch((error) => {
                console.error("Error while updating watchlist:", error);
            });

    };


    useEffect(() => {

        const filteredData = Moviedata && Moviedata.filter((item) => item.is_favourite === "true");
        const FavoriteStatusDATA: any = [];
        filteredData && filteredData.forEach((item) => {
            FavoriteStatusDATA[item.data_id] = true;
        });
        setFavoriteStatus(FavoriteStatusDATA);

    }, [Moviedata]);


    useEffect(() => {

        const initialWatchlistStatus: any = {};
        Moviedata && Moviedata.forEach((item) => {
            initialWatchlistStatus[item.data_id] = item.is_favourite === "true";
        });
        setNewFavotiteStatus(initialWatchlistStatus);


    }, [key]);

    const [watchlistStatus, setWatchlistStatus] = useState<WatchlistStatusInterface>([]);
    const [NewWatchlistStatus, setNewWatchlistStatus] = useState<WatchlistStatusInterface>([]);


    const WatchlistAdd = (data_id: number, data_type: string) => {
        const action = watchlistStatus[data_id] ? 0 : 1; // Toggle action
        const requestData = {
            profile_id: session?.profile_id, // Use profileId here
            data_type: data_type == "movie" ? "movie" : "tvshow",
            data_id: data_id,
            action: action,
            country_code: UserLocation.country_code
        };
        const requestBody = JSON.stringify(requestData);

        fetch(`${API_URL_BASE}${API_URLS.WATCHLIST_ADD_REMOVE}`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-type": "application/json",
                'X-APP-SECRET': X_APP_SECRET,
                Authorization: "Bearer " + token,
            },
            body: requestBody,
        })
            .then((result) => result.json())
            .then((resp) => {
                if (resp.response === true) {

                    setKey((prevKey) => prevKey + 1);

                    setWatchlistStatus((prevStatus: any) => ({
                        ...prevStatus,
                        [data_id]: action === 1, // If action is 1, the item is not in the watchlist, otherwise it's in the watchlist
                    }));
                }
            })
            .catch((error) => {
                console.error("Error while updating watchlist:", error);
            });

    };

    useEffect(() => {

        const filteredData = Moviedata && Moviedata.filter((item) => item.is_watchlist === "true");
        const watchlistStatusDATA: any = [];
        filteredData && filteredData.forEach((item) => {
            watchlistStatusDATA[item.data_id] = true;
        });
        setWatchlistStatus(watchlistStatusDATA);

    }, [Moviedata]);


    useEffect(() => {

        const initialWatchlistStatus: any = {};
        Moviedata && Moviedata.forEach((item) => {
            initialWatchlistStatus[item.data_id] = item.is_watchlist === "true";
        });
        setNewWatchlistStatus(initialWatchlistStatus);

    }, [key]);



    const handleTvShow = (tvshow_id: number) => {
        
        setTvmodelshow(true)
        setTVShow(true);
        setTvshowmodeldata(undefined);
        setEpisode(undefined)
        setMoreLikeThisTVShowdata(undefined);
        setEpisodeLoading(true)
        setEpisodeOrSeasonchCheck(true)
        const requestData = {
            profile_id: session?.profile_id, // Use profileId here
            tvshow_id: tvshow_id,
            country_code: UserLocation.country_code
        };
        const requestBody = JSON.stringify(requestData);


        fetch(`${API_URL_BASE}${API_URLS.TVSHOW_DETAILS}`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-type": "application/json",
                'X-APP-SECRET': X_APP_SECRET,
                Authorization: "Bearer " + token,
            },
            body: requestBody,
        }).then((result) => {

            result.json().then((resp) => {

                if (resp.response == true) {
                        
                    setitemTitle(resp.data.title)

                    if(payPerView ==  true){
                        setcurrencySymbol(resp.data.premium.currency_symbol)
                        if(userSubscription == true){   

                            settvShowPrice(resp.data.premium.subscriber_price)
                            setitemPrice(resp.data.premium.subscriber_price)

                        }else{
                            settvShowPrice(resp.data.premium.nonsubscriber_price)
                            setitemPrice(resp.data.premium.nonsubscriber_price)
                        }
                    }
                    setTvshowmodeldata(resp.data);
                    setTvshowid(tvshow_id);

                    if (resp.data['season'].length > 0) {
                        setSeasonId(resp.data['season'][0]['season_id']);
                    } else {
                        setEpisodeOrSeasonchCheck(false)
                    }
                }
            })
        })

        fetch(`${API_URL_BASE}${API_URLS.TVSHOW_MORE_LIKE_THIS}`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-type": "application/json",
                'X-APP-SECRET': X_APP_SECRET,
                Authorization: "Bearer " + token,
            },
            body: requestBody,

        }).then((result) => {

            result.json().then((resp) => {

                if (resp.response == true) {
                    setMoreLikeThisTVShowdata(resp.data.tvshows)
                } else {
                    console.warn("Responce False Please Tru Again")
                }
            })
        })
    }

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setEpisode(undefined);
        setEpisodeLoading(true)
        setEpisodeOrSeasonchCheck(true)
        const SeasonsId = event.target.value;

        const Episoderequest = {
            profile_id: session?.profile_id, // Use profileId here
            tvshow_id: tvshowid,
            season_id: SeasonsId,
            paginate: false,
            limit: -1,
            order_by: ["created_at", "asc"],
            country_code: UserLocation.country_code
        };
        const EpisoderequestBody = JSON.stringify(Episoderequest);

        fetch(`${API_URL_BASE}${API_URLS.TVSHOW_SEASON_EPISODE}`, {
            method: "POST",
            headers: headers,
            body: EpisoderequestBody,
        }).then((result) => {

            result.json().then((resp) => {

                if (resp.response == true) {

                    setEpisode(resp.data);
                    setEpisodeLoading(false)
                } else {
                    setEpisodeOrSeasonchCheck(false)
                }
            })
        })

    };

    return (
        <div>
            <Header />
            <ToastContainer />
            <Container fluid className='cust_container inner_section'>
                <div className="account_setting_title">
                    <h3>{heading}</h3>
                </div>
                <div className="view_all_section">
                    <div className='slick-slider episode_details'>
                        {
                            continueWatchloading === false ? (
                                CountinueWatchdata && Array.isArray(CountinueWatchdata) && CountinueWatchdata.map((item) => (
                                    item.data_type === "movie" ? (
                                        <div className="item_box" key={item.data_detail.data_id}>
                                            <div onClick={() => handleShow(item.data_detail.data_id)}>
                                                <img src={item.data_detail.media_poster} alt={item.data_detail.title} />
                                            </div>
                                            <div className="item_img_details">
                                                <div className="item_img_header">
                                                    <h5>{item.data_detail.title}</h5>
                                                    <ul>
                                                        <li onClick={() => PlayVideo(item.data_detail.data_id, item.watching_duration)}>
                                                            <img src="img/play-icon.png" alt="" />
                                                        </li>
                                                        <li onClick={() => WatchlistAdd(item.data_detail.data_id, item.data_type)}>
                                                            {watchlistStatus[item.data_detail.data_id] ? (
                                                                <img src="img/check-white.png" alt="" />
                                                            ) : (
                                                                <img src="img/add-icon.png" alt="" />
                                                            )}
                                                        </li>
                                                        <li onClick={() => FavoriteAdd(item.data_detail.data_id, item.data_type)}>
                                                            {favoriteStatus[item.data_detail.data_id] ? (
                                                                <img src="img/fav.png" alt="" />
                                                            ) : (
                                                                <img src="img/like stroke.png" alt="" />
                                                            )}
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="item_img_number">
                                                    <h3> {item.data_detail.maturity_level}</h3>
                                                    <h4>{item.data_detail.release_date ? (new Date(item.data_detail.release_date)).getFullYear() : ""} </h4>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="item_box" key={item.data_detail.data_id}>
                                            <div onClick={() => handleTvShow(item.data_detail.data_id)}>
                                                <img src={item.data_detail.media_poster} alt={item.data_detail.title} />
                                            </div>
                                            <div className="item_img_details">
                                                <div className="item_img_header">
                                                    <h5>{item.data_detail.episode.title}</h5>
                                                    <ul>
                                                        <li onClick={() => PlayEpisode(item.data_detail.episode.data_id, item.data_detail.data_id,item.watching_duration)}>
                                                            <img src="img/play-icon.png" alt="" />
                                                        </li>
                                                        <li onClick={() => WatchlistAdd(item.data_detail.data_id, item.data_type)}>
                                                            {watchlistStatus[item.data_detail.data_id] ? (
                                                                <img src="img/check-white.png" alt="" />
                                                            ) : (
                                                                <img src="img/add-icon.png" alt="" />
                                                            )}
                                                        </li>
                                                        <li onClick={() => FavoriteAdd(item.data_detail.data_id, item.data_type)}>
                                                            {favoriteStatus[item.data_detail.data_id] ? (
                                                                <img src="img/fav.png" alt="" />
                                                            ) : (
                                                                <img src="img/like stroke.png" alt="" />
                                                            )}
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="item_img_number">
                                                    <h3> {item.data_detail.maturity_level}</h3>
                                                    <h4>{item.data_detail.release_date ? (new Date(item.data_detail.release_date)).getFullYear() : ""} </h4>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                ))
                            ) : (
                                Array.from({ length: 3 }).map((_, index) => (
                                    <div className="shimmer_card" key={index}></div>
                                ))
                            )
                        }

                        {
                            continueWatchloading === false ? (
                                TopSearchMovie && Array.isArray(TopSearchMovie) && TopSearchMovie.map((item) => (
                                    item.data_type === "movie" ? (
                                        <div className="item_box" key={item.data_id}>
                                            <div onClick={() => handleShow(item.data_id)}>
                                                <img src={item.media_poster} alt={item.title} />
                                            </div>
                                            <div className="item_img_details">
                                                <div className="item_img_header">
                                                    <h5>{item.title}</h5>
                                                    <ul>
                                                        
                                                    {item.is_paid == true ? <li onClick={() => PlayVideo(item.data_id)}><img src="img/play-icon.png" alt="" /></li>:<li onClick={() => handleShow(item.data_id)}><img src="img/play-icon.png" alt="" /></li>}
                                                        
                                                        <li onClick={() => WatchlistAdd(item.data_id, item.data_type)}>
                                                            {watchlistStatus[item.data_id] ? (
                                                                <img src="/img/check-white.png" alt="" />
                                                            ) : (
                                                                <img src="/img/add-icon.png" alt="" />
                                                            )}
                                                        </li>
                                                        <li onClick={() => FavoriteAdd(item.data_id, item.data_type)}>
                                                            {favoriteStatus[item.data_id] ? (
                                                                <img src="/img/fav.png" alt="" />
                                                            ) : (
                                                                <img src="/img/like stroke.png" alt="" />
                                                            )}
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="item_img_number">
                                                    <h3> {item.maturity_level}</h3>
                                                    <h4>{item.release_date ? (new Date(item.release_date)).getFullYear() : ""} </h4>
                                                </div>
                                            </div>
                                                {
                                                    item.is_premium == true && item.is_paid == false ? 
                                                    <div className='premium_icon'>
                                                        <Link to={"#"}><img src='img/premium_icon.png' /></Link>
                                                    </div> : ""
                                                }
                                        </div>
                                    ) : (
                                        <div className="item_box" key={item.data_id}>
                                            <div onClick={() => handleTvShow(item.data_id)}>
                                                <img src={item.media_poster} alt={item.title} />
                                            </div>
                                            <div className="item_img_details">
                                                <div className="item_img_header">
                                                    <h5>{item.title}</h5>
                                                    <ul>
                                                        <li onClick={() => handleTvShow(item.data_id)}>
                                                            <img src="/img/play-icon.png" alt="" />
                                                        </li>
                                                        <li onClick={() => WatchlistAdd(item.data_id, item.data_type)}>
                                                            {watchlistStatus[item.data_id] ? (
                                                                <img src="/img/check-white.png" alt="" />
                                                            ) : (
                                                                <img src="/img/add-icon.png" alt="" />
                                                            )}
                                                        </li>
                                                        <li onClick={() => FavoriteAdd(item.data_id, item.data_type)}>
                                                            {favoriteStatus[item.data_id] ? (
                                                                <img src="/img/fav.png" alt="" />
                                                            ) : (
                                                                <img src="/img/like stroke.png" alt="" />
                                                            )}
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="item_img_number">
                                                    <h3> {item.maturity_level}</h3>
                                                    <h4>{item.release_date ? (new Date(item.release_date)).getFullYear() : ""} </h4>
                                                </div>
                                            </div>
                                            {
                                                item.is_premium == true && item.is_paid == false ? 
                                                <div className='premium_icon'>
                                                    <Link to={"#"}><img src='img/premium_icon.png' /></Link>
                                                </div> : ""
                                            }
                                        </div>
                                    )
                                ))
                            ) : (
                                Array.from({ length: 5 }).map((_, index) => (
                                    <div className="shimmer_card" key={index}></div>
                                ))
                            )
                        }
                    </div>
                </div>
            </Container>

            <Footer />
            <Modal className='series_modal' show={show} onHide={handleClose} backdrop="static" size="lg" keyboard={false} centered>
                <div className="modal_close" onClick={handleClose}>
                    <Link to={""}><img src="/img/modal_close.png" alt=""></img></Link>
                </div>

                <Modal.Body>
                    <div className="series_img">
                        {modeldata ? (
                            <img src={modeldata ? modeldata['media_banner'] : 'img/default-banner.png'} className="series_big_imf" alt=""></img>
                        ) : (
                            <div className='series_img_shimmer'></div>
                        )}
                        <div className="series_tool">
                            <div className="series_name">
                                <h2>{modeldata ? modeldata['title'] : ''}</h2>
                            </div>
                            <div className="series_controller">
                                <div className="series_left_controller">
                                    {modeldata && Object.keys(modeldata['premium']).length > 0  && modeldata['premium']['is_paid']  == false?
                                        <button className="music_play_btn" onClick={() => handleShowTwo(modeldata['data_id'],'movie')}><img src="img/play_icon.jpg"></img>Pay & Play</button>
                                     :  <button onClick={() => modeldata && PlayVideo(modeldata['data_id'])} className="music_play_btn"><img src="img/play_icon.jpg"></img>Play</button>}
                                    {watchlistStatus && modeldata && watchlistStatus[modeldata['data_id']] ? (

                                        <div onClick={() => WatchlistAdd(modeldata['data_id'], modeldata['data_type'])}>
                                            {watchlistStatus[modeldata['data_id']] ? (

                                                <button className="music_small_btn"><img src="/img/check-white.png"></img></button>
                                            ) : (
                                                <button className="music_small_btn"><img src="/img/music_add.png"></img></button>
                                            )}

                                        </div>
                                    ) : (
                                        <div onClick={() => modeldata && WatchlistAdd(modeldata['data_id'], modeldata['data_type'])}>
                                            {modeldata && Object.keys(watchlistStatus).length === 0 && modeldata && modeldata['is_watchlist'] === "true" ? (
                                                <button className="music_small_btn"><img src="/img/check-white.png" alt="" /></button>
                                            ) : (
                                                <button className="music_small_btn"><img src="/img/music_add.png" alt="" /></button>
                                            )}
                                        </div>
                                    )}

                                    {favoriteStatus && modeldata && favoriteStatus[modeldata['data_id']] ? (
                                        <div onClick={() => FavoriteAdd(modeldata['data_id'], modeldata['data_type'])}>
                                            {favoriteStatus[modeldata['data_id']] ? (

                                                <button className="music_small_btn"><img src="/img/fav.png"></img></button>
                                            ) : (
                                                <button className="music_small_btn"><img src="/img/like stroke.png"></img></button>
                                            )}


                                        </div>
                                    ) : (
                                        <div onClick={() => modeldata && FavoriteAdd(modeldata['data_id'], modeldata['data_type'])}>
                                            {modeldata && Object.keys(favoriteStatus).length === 0 && modeldata && modeldata['is_watchlist'] === "true" ? (
                                                <button className="music_small_btn"><img src="/img/fav.png" alt="" /></button>
                                            ) : (
                                                <button className="music_small_btn"><img src="/img/like stroke.png" alt="" /></button>
                                            )}
                                        </div>
                                    )}

                                </div>
                                <div className="series_right_controller">
                                    <button className='music_small_btn'><img src="/img/music_volum.png" className=" m-0 d-none"></img></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="series_full_description">
                        {
                            modeldata ? (
                                <div className="series_details_content">
                                    <div className="series_description">
                                        <div className="more_second_section mt-0">
                                            <h3> {modeldata ? modeldata['maturity_level'] : ''}</h3>
                                            <h4>{modeldata && modeldata['release_date'] ? (new Date(modeldata['release_date'])).getFullYear() : ""} &nbsp;|&nbsp; {modeldata && FormatTimeWithHourMinute(modeldata['duration'])}</h4>
                                            <h5 className='movie_price'>
                                                {!isUndefined(moviePrice) ? currencySymbol + moviePrice: ""}
                                            </h5>
                                        </div>
                                        <p>{modeldata ? modeldata['description'] : ''}</p>
                                    </div>
                                    <div className="series_categories">
                                        <h4>Cast & Crew : <span className="white_text">
                                            {modeldata && modeldata['celebs'] && Array.isArray(modeldata['celebs']) ? (modeldata['celebs'] as { title: string }[]).map((cast) => cast.title).join(', ') : ""}
                                        </span></h4>
                                        <h4>Genres  : <span className="white_text">
                                            {modeldata && modeldata['genres'] && Array.isArray(modeldata['genres']) ? (modeldata['genres'] as { genre_title: string }[]).map((genre) => genre.genre_title).join(', ') : ""}
                                        </span></h4>
                                    </div>
                                </div>
                            ) : (
                                null
                            )
                        }

                        <div className="more_like_this">
                            {
                                CheckMoreLikeThisMovie === true ? (
                                    <div className="more_like_this_title">
                                        <h3>More Like This</h3>
                                    </div>
                                ) : (null)
                            }

                            <div className="row">
                                {

                                    CheckMoreLikeThisMovie === true ? (
                                        MorelikeThisLoading === false && Array.isArray(MoreLikeThisMoviedata) && MoreLikeThisMoviedata.length > 0 ? (
                                            MoreLikeThisMoviedata.map((item) => (

                                                <div className="col-lg-4 col-md-4 col-sm-6" key={item.title}>
                                                    <div className="more_like_box" >
                                                        <div className="more_like_box_img" onClick={() => handleShow(item.data_id)}>
                                                            <img src={item.media_banner}></img>
                                                        </div>
                                                        <div className="more_like_content_box">
                                                            <div className="more_like_left_info">
                                                                <h4>{item.title}</h4>
                                                                {watchlistStatus && watchlistStatus[item.data_id] ? (
                                                                    <li key={item.data_id} onClick={() => WatchlistAdd(item.data_id, item.data_type)}>
                                                                        {watchlistStatus[item.data_id] ? (
                                                                            <img src="/img/check-white.png" alt="" />
                                                                        ) : (
                                                                            <img src="/img/add-icon.png" alt="" />
                                                                        )}

                                                                    </li>
                                                                ) : (
                                                                    <li onClick={() => WatchlistAdd(item.data_id, item.data_type)}>
                                                                        {Object.keys(watchlistStatus).length === 0 && item.is_watchlist == "true" ? (
                                                                            <img src="/img/check-white.png" alt="" />
                                                                        ) : (
                                                                            <img src="/img/add-icon.png" alt="" />
                                                                        )}
                                                                    </li>
                                                                )}
                                                            </div>
                                                            <div className="more_second_section">
                                                                <h3> {item.maturity_level}</h3>
                                                                <h4>
                                                                    {item.release_date ? (new Date(item.release_date)).getFullYear() : ""}
                                                                </h4>
                                                            </div>
                                                            <div className="more_second_description">
                                                                <p>{item.description}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            Array.from({ length: 3 }).map((_, index) => (
                                                <div className='more_like_shimmer' key={index}></div>
                                            ))
                                        )
                                    ) : (null)

                                }
                            </div>
                            <div className="more_img_load">
                                <Link to={""}><img src="/img/down_arrowpng.png" alt=""></img></Link>
                            </div>
                        </div>
                        <div className="about_movie">
                            <div className="more_like_this_title">
                                <h3>About {modeldata ? modeldata['title'] : ''}</h3>
                            </div>
                            <div className="about_movie_details">
                                <div className="series_categories">
                                    <h4>Cast & Crew : <span className="white_text"> {modeldata && modeldata['celebs'] && Array.isArray(modeldata['celebs']) ? (modeldata['celebs'] as { title: string }[]).map((cast) => cast.title).join(', ') : ""}</span></h4>

                                    <h4>Genres  : <span className="white_text">{modeldata && modeldata['genres'] && Array.isArray(modeldata['genres']) ? (modeldata['genres'] as { genre_title: string }[]).map((genre) => genre.genre_title).join(', ') : ""}</span></h4>
                                    <h4>Maturity Rating: <span className="white_text white_text_border"> {modeldata ? modeldata['maturity_level'] : ''} </span></h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>

            </Modal>

            <Modal className='series_modal' show={tvmodelshow} onHide={tvshowhandleClose} backdrop="static" size="lg" keyboard={false} centered>
                <div className="modal_close" onClick={tvshowhandleClose}>
                    <Link to={""}><img src="/img/modal_close.png" alt=""></img></Link>
                </div>

                <Modal.Body>
                    <div className="series_img">


                        {tvshowmodeldata ? (
                            <img src={tvshowmodeldata ? tvshowmodeldata['media_banner'] : 'img/default-banner.png'} className="series_big_imf" alt=""></img>
                        ) : (
                            <div className='series_img_shimmer'></div>
                        )}

                        <div className="series_tool">
                            <div className="series_name">
                                <h2>{tvshowmodeldata ? tvshowmodeldata['title'] : ''}</h2>
                            </div>
                            <div className="series_controller">
                                <div className="series_left_controller">
                                    
                                {tvshowmodeldata && Object.keys(tvshowmodeldata['premium']).length > 0  && tvshowmodeldata['premium']['is_paid'] == false ?
                                    // <button className="music_play_btn" onClick={handleShowTwo}>Pay & Play</button>
                                    <button className="music_play_btn" onClick={() => handleShowTwo(tvshowmodeldata['data_id'],'tvshow')}><img src="img/play_icon.jpg"></img>Pay & Play</button>
                                    :<button className="music_play_btn" onClick={() => FirstepisodeID && tvshowmodeldata && PlayEpisode(FirstepisodeID, tvshowmodeldata['data_id'])}><img src="img/play_icon.jpg"></img>Play</button>}

                                    {watchlistStatus && tvshowmodeldata && watchlistStatus[tvshowmodeldata['data_id']] ? (
                                        <div onClick={() => WatchlistAdd(tvshowmodeldata['data_id'], tvshowmodeldata['data_type'])}>
                                            {watchlistStatus[tvshowmodeldata['data_id']] ? (

                                                <button className="music_small_btn"><img src="/img/check-white.png"></img></button>
                                            ) : (
                                                <button className="music_small_btn"><img src="/img/music_add.png"></img></button>
                                            )}

                                        </div>
                                    ) : (
                                        <div onClick={() => tvshowmodeldata && WatchlistAdd(tvshowmodeldata['data_id'], tvshowmodeldata['data_type'])}>
                                            {tvshowmodeldata && Object.keys(watchlistStatus).length === 0 && tvshowmodeldata && tvshowmodeldata['is_watchlist'] === "true" ? (
                                                <button className="music_small_btn"><img src="/img/check-white.png" alt="" /></button>
                                            ) : (
                                                <button className="music_small_btn"><img src="/img/music_add.png" alt="" /></button>
                                            )}
                                        </div>
                                    )}
                                   
                                    {favoriteStatus && tvshowmodeldata && favoriteStatus[tvshowmodeldata['data_id']] ? (
                                        <div onClick={() => FavoriteAdd(tvshowmodeldata['data_id'], tvshowmodeldata['data_type'])}>
                                            {favoriteStatus[tvshowmodeldata['data_id']] ? (

                                                <button className="music_small_btn"><img src="/img/fav.png"></img></button>
                                            ) : (
                                                <button className="music_small_btn"><img src="/img/like stroke.png"></img></button>
                                            )}

                                        </div>
                                    ) : (
                                        <div onClick={() => tvshowmodeldata && FavoriteAdd(tvshowmodeldata['data_id'], tvshowmodeldata['data_type'])}>
                                            {tvshowmodeldata && Object.keys(favoriteStatus).length === 0 && tvshowmodeldata && tvshowmodeldata['is_watchlist'] === "true" ? (
                                                <button className="music_small_btn"><img src="/img/fav.png" alt="" /></button>
                                            ) : (
                                                <button className="music_small_btn"><img src="/img/like stroke.png" alt="" /></button>
                                            )}
                                        </div>
                                    )}


                        

                                </div>
                                <div className="series_right_controller">
                                    <button className='music_small_btn'><img src="img/music_volum.png" className=" m-0 d-none"></img></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="series_full_description">
                        {
                            tvshowmodeldata ? (
                                <div className="series_details_content">
                                    <div className="series_description">
                                        <div className="more_second_section mt-0">
                                            <h3> {tvshowmodeldata ? tvshowmodeldata['maturity_level'] : ''}</h3>
                                            <h4>{modeldata && modeldata['release_date'] ? (new Date(modeldata['release_date'])).getFullYear() : ""} &nbsp;|&nbsp; {modeldata && FormatTimeWithHourMinute(modeldata['duration'])}</h4>
                                            <h5 className='movie_price'>
                                                {!isUndefined(tvShowPrice) ? currencySymbol + tvShowPrice: ""}
                                            </h5>
                                        </div>
                                        <p>{tvshowmodeldata ? tvshowmodeldata['description'] : ''}</p>
                                    </div>
                                    <div className="series_categories">
                                        <h4>Cast & Crew : <span className="white_text">
                                            {tvshowmodeldata && tvshowmodeldata['celebs'] && Array.isArray(tvshowmodeldata['celebs']) ? (tvshowmodeldata['celebs'] as { title: string }[]).map((cast) => cast.title).join(', ') : ""}
                                        </span></h4>
                                        <h4>Genres  : <span className="white_text"> {tvshowmodeldata && tvshowmodeldata['genres'] && Array.isArray(tvshowmodeldata['genres']) ? (tvshowmodeldata['genres'] as { genre_title: string }[]).map((genre) => genre.genre_title).join(', ') : ""}</span></h4>
                                    </div>
                                </div>
                            ) : (
                                null
                            )
                        }
                        <div className="series_episode_details">
                            <div className="series_episode_header">
                                <h3>Episode</h3>
                                <div className='genres_section'>
                                    <h3>Seasons</h3>
                                    <div className="select_box">
                                        <Form.Select aria-label="Default select example" className='cust_seleted' onChange={handleSelectChange}>
                                            {
                                                tvshowmodeldata && tvshowmodeldata.season && tvshowmodeldata.season.length > 0 ? (
                                                    tvshowmodeldata.season.map((season: any, index: any) => (

                                                        <option key={season.season_id} value={season.season_id} > {season.title}</option>
                                                    ))
                                                ) : (
                                                    <p style={{ color: "red" }}>No Seasons available</p>
                                                )
                                            }

                                        </Form.Select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="series_episode_list">
                            {
                                EpisodeOrSeasonchCheck === true ? (
                                    EpisodeLoading === false && episode && episode.length > 0 ? (
                                        episode.map((episode: any, index: any) => (
                                            <div key={index} className="series_episode_list_box"
                                            
                                            onClick={() => {
                                                if (modeldata && Object.keys(modeldata['premium']).length > 0 && modeldata['premium']['is_paid'] == false) {
                                                  handleShowTwo(modeldata['data_id'], 'tvshow'); // Open details page
                                                } else {
                                                    if(episode.data_id && modeldata ){
                                                        PlayEpisode(episode.data_id, modeldata['data_id']); // Play episode
                                                    }
                                                }
                                              }}
                                            
                                            >
                                                <div className="series_episode_list_box_details">
                                                    <h4>{episode.episode_number}</h4>
                                                    <div className="episode_details_img">
                                                        <img src={episode.media_banner} className="episode_img" alt={episode.title} />
                                                        <a href={episode.media_content}>
                                                            <img src="img/play_video.png" className="play_video_icon" alt="Play Video" />
                                                        </a>
                                                    </div>
                                                    <div className="series_episode_list_box_description">
                                                        <h3>{episode.title}</h3>
                                                        <p>{episode.description}</p>
                                                    </div>
                                                </div>
                                                <div className="series_episode_list_box_min">
                                                    <h2>{FormatTimeWithHourMinute(episode.duration)}</h2>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        Array.from({ length: 3 }).map((_, index) => (
                                            <div className='series_episode_list_box_shimmer' key={index}></div>
                                        ))
                                    )
                                ) : (
                                    <h5 className='white_text'>Episode Not Found.</h5>
                                )
                            }

                        </div>
                        <div className="more_like_this">
                            <div className="more_like_this_title">
                                <h3>More Like This</h3>
                            </div>

                            <div className="row">

                                {
                                    Array.isArray(MoreLikeThisTVShowdata) && MoreLikeThisTVShowdata.length > 0 ? (
                                        MoreLikeThisTVShowdata.map((item) => (
                                            <div className="col-lg-4 col-md-4 col-sm-6">
                                                <div className="more_like_box" >
                                                    <div className="more_like_box_img" onClick={() => handleShow(item.data_id)}>
                                                        <img src={item.media_banner}></img>
                                                    </div>
                                                    {
                                                        item.is_premium == true && item.is_paid == false ? 
                                                        <div className='premium_icon'>
                                                            <Link to={"#"}><img src='img/premium_icon.png' /></Link>
                                                        </div>: "" 
                                                    }

                                                    <div className="more_like_content_box">
                                                        <div className="more_like_left_info">
                                                            <h4>{item.title}</h4>
                                                            {watchlistStatus && watchlistStatus[item.data_id] ? (
                                                                <li onClick={() => WatchlistAdd(item.data_id, item.data_type)}>
                                                                    {watchlistStatus[item.data_id] ? (
                                                                        <img src="/img/check-white.png" alt="" />
                                                                    ) : (
                                                                        <img src="/img/add-icon.png" alt="" />
                                                                    )}

                                                                </li>
                                                            ) : (
                                                                <li onClick={() => WatchlistAdd(item.data_id, item.data_type)}>
                                                                    {Object.keys(watchlistStatus).length === 0 && item.is_watchlist == "true" ? (
                                                                        <img src="/img/check-white.png" alt="" />
                                                                    ) : (
                                                                        <img src="/img/add-icon.png" alt="" />
                                                                    )}
                                                                </li>
                                                            )}
                                                        </div>
                                                        <div className="more_second_section">
                                                            <h3> {item.maturity_level}</h3>
                                                            <h4>
                                                                {item.release_date ? (new Date(item.release_date)).getFullYear() : ""}
                                                            </h4>
                                                        </div>
                                                        <div className="more_second_description">
                                                            <p>{item.description}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <div className="no-movies-found-message">
                                        </div>
                                    )
                                }
                            </div>
                            <div className="more_img_load">
                                <Link to={""}><img src="img/down_arrowpng.png" alt=""></img></Link>
                            </div>
                        </div>
                        <div className="about_movie">
                            <div className="more_like_this_title">
                                <h3>About {tvshowmodeldata ? tvshowmodeldata['title'] : ''}</h3>
                            </div>
                            <div className="about_movie_details">
                                <div className="series_categories">
                                    <h4>Cast & Crew : <span className="white_text">{tvshowmodeldata && tvshowmodeldata['celebs'] && Array.isArray(tvshowmodeldata['celebs']) ? (tvshowmodeldata['celebs'] as { title: string }[]).map((cast) => cast.title).join(', ') : ""}</span></h4>
                                    <h4>Genres  : <span className="white_text">{tvshowmodeldata && tvshowmodeldata['genres'] && Array.isArray(tvshowmodeldata['genres']) ? (tvshowmodeldata['genres'] as { genre_title: string }[]).map((genre) => genre.genre_title).join(', ') : ""}</span></h4>
                                    <h4>Maturity Rating: <span className="white_text white_text_border"> {tvshowmodeldata ? tvshowmodeldata['maturity_level'] : ''}</span></h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal className='movie_name_modal' show={showTwo} onHide={handleCloseTwo}  centered backdrop="static" keyboard={false}>
                
                <Modal.Body>
                    <div className='movie_name_close_modal' onClick={movie_name_modal_close}>
                        <Link to={"#"}><img src='img/modal_close.png'/></Link>
                    </div>
                    <div className='movie_name_details'>
                        <div className='movie_name_img'>
                            <img src='img/tv.svg'/>
                        </div>
                        <div className='movine_name_section'>
                            <h3>{itemTitle}</h3>
                            <h4>{currencySymbol}{itemPrice}</h4>
                            <button onClick={handleShowThree} >Continue to Pay</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal className='movie_name_modal' show={showThree} onHide={handleCloseThree}  centered backdrop="static" keyboard={false}>
                <Modal.Body>
                    {paymentLoder == true ? (
                    <div className='loader'>
                            <img src='img/loader.gif' alt="Loading..." />
                    </div>
                    ) : (
                        null
                    )}
                        <>
                            <div className='movie_name_close_modal' onClick={handleCloseThree}>
                                <Link to="#">
                                    <img src='img/modal_close.png' alt="Close Modal" />
                                </Link>
                            </div>
                            <div className='stripe_header'>
                                <h3>Payment for - <span>{itemTitle}</span></h3>
                                <h3>Total Amount - <span>{currencySymbol}{itemPrice}</span></h3>
                            </div>
                            <div className='stripe_details'>
                                {itemDataType && itemVideoId ? (
                                    <Elements stripe={stripePromise}>
                                        <ItemPurchase 
                                            data_id={itemVideoId} 
                                            data_type={itemDataType}  
                                            updatePaymentStatus={updateVideoPaymentStatus} 
                                            errorInVideoPayment={errorInVideoPayment} 
                                            loderShow={loderShow} 
                                            loderHide={loderHide}
                                        />
                                    </Elements>
                                ) : ""}
                            </div>
                        </>
                </Modal.Body>
            </Modal>
        </div>
    )
}
