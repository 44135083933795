import React from 'react'
import { Link } from 'react-router-dom'
import Footer from './Layout/Footer';

export default function Landing() {
  return (
    <div>
        {/* index_bg */}
        <div className="index_bg">
            <div className="login_content">
                <div className="landing_login_logo">
                    <img src="img/play_now_logo.png" alt="" className="logo"></img>
                    <Link to={"/signin"} className="sign_in_btn">Sign in</Link>
                </div>
                <div className="index_first_content">
                    <h3>Unlimited movies, TV shows and more.</h3>
                    <Link to={"/signin"} className="join_now_btn">Join Now!</Link>
                </div>
            </div>
            <div className="index_second_content">
                <div className="container">
                    <div className="index_content_text">
                        <div className="index_left_content">
                            <h3>Watch anywhere. Cancel anytime</h3>
                            <h4>Ready to watch? Enter your email to create or restart your membership.</h4>
                            <Link to={"/signin"} className="watch_now_btn">Watch Now!</Link>
                        </div>
                        <div className="index_right_img">
                            <img src="img/pc.png" alt="" className="img-responsive"></img>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <Footer/>
    </div>
  )
}
