import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { redirect } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import routes from './Routes'
import { Session, User } from './Helper';
import { exitCode } from 'process';
import { API_URLS, X_APP_SECRET, headers } from './utils';
import { API_URL_BASE } from './utils';
import Form from 'react-bootstrap/Form';
import { UserInfo, Usertype } from './Model/Interface';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns';

import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import secureLocalStorage from 'react-secure-storage';

export default function SwitchProfile() {

    function showErrorNotification(message: any) {
        toast.error(message, {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    }
    var user = User();
    const token = user?.data.token
    var session = Session();

    const navigate = useNavigate();

    const [show, setShow] = useState(false);
    const [createshow, setCreateshow] = useState(false);
    const [ImgErrormsg, setImageErrormsg] = useState('');
    const [isVisible, setIsVisible] = useState(true);
    const [Profileloading, setProfileloading] = useState(true); 

    const toggleVisibility = () => {
        setIsVisible(false);
    };

    
    const handleClose = () => {
        setErrormsg('')
        setImageErrormsg('')
        setShow(false);
        setProfile(false)
        setBg(current => !current);
        setButtonText('Manage Profile');
        setImagePreviewSrc(undefined)
        setIsVisible(true);

    }

    const CreatehandleClose = () => {
        setImageErrormsg('')
        setErrormsg('')
        setCreateshow(false);
        setProfile(false)
        setBg(current => !current);
        setButtonText('Manage Profile');
        setImagePreviewSrc(undefined)
        setIsVisible(true);

    }

 
    const [userid, Setuserid] = useState<Usertype>()
    const [primaryid, SetPrimaryid] = useState<Usertype>()

    const [profileDetail, setProfiledetails] = useState<Usertype>();
    const [profilename, setProfilename] = React.useState<string>("");
    const [maturitylevel, setMaturitylevel] = useState("");
    const [errormsg, setErrormsg] = useState('');
    const [maturity, setMaturity] = useState<{ key: number; value: string }[]>([]);
    const [profileid, setProfileid] = useState("");
    const [dob, setDob] = useState<string | null | number | undefined | any>(null); 
    const [startDate, setStartDate] = useState("");
    const [gender, setGender] = useState("2");
    const [image, setImage] = useState("");
    const [isImageSelected, setIsImageSelected] = useState(false)
    const [imagePreviewSrc, setImagePreviewSrc] = useState<string | undefined>(undefined)

    const createhandleShow = () => {
        setImageErrormsg('')
        setProfilename("")
        setMaturitylevel("0")
        setDob("")
        setGender("2")
        setImagePreviewSrc(undefined)

        setCreateshow(true)
    }

    const handleShow = (profile_id: number) => {
        setShow(true);
        setImageErrormsg('')
        setProfilename("")
        setMaturitylevel("")
        setDob("")
        setGender("")
        setImage("")
        var data = { profile_id, userid }
        if (token) {
            fetch(`${API_URL_BASE}${API_URLS.USER_PROFILE_DETAIL}`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-type": "application/json",
                    'X-APP-SECRET': X_APP_SECRET,
                    Authorization: "Bearer " + token,
                },
                body: JSON.stringify(data)
            }).then((result) => {

                result.json().then((resp) => {
                    if (resp.response == true) {

                        setProfiledetails(resp.data)
                        setProfilename(resp.data.profile_name)
                        setMaturitylevel(resp.data.maturity_level)
                        setProfileid(resp.data.profile_id)
                        setGender(resp.data.gender)
                        setDob(resp.data.birth_date)
                        setImage(resp.data.profile_image)

                    }
                })
            })
        }
    }
    const [isDivVisible, setDivVisible] = useState(false);

    const selectProfile = (profile_id: number) => {
    
        var user_location = "US";
        var data = { profile_id, user_location }
        
        if (token) {
            fetch(`${API_URL_BASE}${API_URLS.USER_PROFILE_SELECT}`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-type": "application/json",
                    'X-APP-SECRET': X_APP_SECRET,
                    Authorization: "Bearer " + token,
                },
                body: JSON.stringify(data)
            }).then((result) => {
                
                result.json().then((resp) => {
                    
                    if (resp.response == true) {
                        secureLocalStorage.setItem("userProfile", JSON.stringify(resp.data));
                        
                            navigate(routes.home);
                    }
                })
            })
            // setDivVisible(true);
        }
    }


    const [bg, setBg] = useState(false);
    const [buttonText, setButtonText] = useState('Manage Profile');
    const [profile, setProfile] = useState(false)

    const manageProfile = () => {

        setProfile(true)
        setBg(current => !current);
        setButtonText('Done');
        if (buttonText == 'Done') {
            setButtonText('Manage Profile');
            setProfile(false)
            setBg(current => current);
            Setprimaryaccerr('')
        }

    }
    const [UserProfile, setUserprofile] = useState<UserInfo>()

    const [data, Setdata] = useState();

    useEffect(() => {
        getProfile()
        if (token) {

            fetch(`${API_URL_BASE}${API_URLS.MATURITY_LEVEL}`, {

                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-type": "application/json",
                    'X-APP-SECRET': X_APP_SECRET,
                    Authorization: "Bearer " + token,
                },

            }).then((result) => {

                result.json().then((resp) => {

                    if (resp.response == true) {
                        setMaturity(resp.data);
                    }
                })
            })


        }
    }, [])

    const [selectedImage, setSelectedImage] = useState<{ file: File | null; base64: string | null }>({
        file: null,
        base64: null,
    });


    const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {

        setImageErrormsg('');
        const file = e.target.files?.[0]; // Get the selected file
        if (file) {
            validateImage(file);
        }
    };

    const validateImage = (file: File) => {
        const allowedTypes = ['image/jpeg', 'image/png'];
        const maxSize = 5 * 1024 * 1024; // 5 MB

        if (!allowedTypes.includes(file.type)) {
            setImageErrormsg('Invalid image type. Please select a JPEG or PNG file.');
            setIsVisible(true);
            setImagePreviewSrc(undefined);
            
        } else if (file.size > maxSize) {
            setImageErrormsg('Image size exceeds the maximum limit of 5 MB.');
            setIsVisible(true);
            setImagePreviewSrc(undefined);
            
        } else {
            const reader = new FileReader();
            reader.onload = (e) => {
                const target = e.target as FileReader;
                if (target) {

                    const base64String = target.result as string;

                    console.warn(base64String)
                    setImagePreviewSrc(base64String);

                    setSelectedImage({ file, base64: base64String });
                    setIsVisible(true);
                    setIsImageSelected(true);
                    const inputElement = document.getElementById('fileInput') as HTMLInputElement;
                    if (inputElement) {
                        inputElement.value = '';
                    }
                } else {
                    setIsImageSelected(false);
                }
            };
            reader.readAsDataURL(file);
        }
    };



    const updateProfile = (profileid: string) => {

        var requestData = {
            profile_id: profileid,
            profile_name: profilename,
            gender: gender,
            birth_date: dob,
            maturity_level: maturitylevel,
            user_id: userid,
            profile_image: selectedImage.base64,
        };

        var requestBody = JSON.stringify(requestData);
        if (token) {
            fetch(`${API_URL_BASE}${API_URLS.USER_PROFILE_UPDATE}`, {
                method: 'POST',
                headers: {
                    Accept: "application/json",
                    "Content-type": "application/json",
                    'X-APP-SECRET': X_APP_SECRET,
                    Authorization: "Bearer " + token,
                },
                body: requestBody,
            }).then((result) => {
                result.json().then((resp) => {
                    if (resp.response === true) {
                        setIsVisible(true);
                        setShow(false);
                        setProfile(false)
                        setBg(current => !current);
                        setButtonText('Manage Profile');
                        getProfile()
                        setSelectedImage({ file: null, base64: null })

                    } else {

                        setImageErrormsg(resp.message);
                        setIsVisible(true);
                    }

                })
            })
        }
    }

    function getProfile() {
        
        if (token) {
            
            fetch(`${API_URL_BASE}${API_URLS.USER_PROFILE_LIST}`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-type": "application/json",
                    'X-APP-SECRET': X_APP_SECRET,
                    Authorization: "Bearer " + token,
                },
            }).then((result) => {

                result.json().then((resp) => {
                    if (resp.response == true) {

                        Setuserid(resp.data[0].user_id)
                        SetPrimaryid(resp.data[0].profile_id)
                        setUserprofile(resp.data)
                        setProfileloading(false)


                    }
                })
            })
        }
    }
    const [primaryaccerr, Setprimaryaccerr] = useState('');
    const profileRemove = (profile_id: Usertype | undefined) => {

        if (primaryid != profile_id) {
            var data = { profile_id, userid }
            if (token) {
                fetch(`${API_URL_BASE}${API_URLS.USER_PROFILE_REMOVE}`, {
                    method: 'POST',
                    headers: {
                        Accept: "application/json",
                        "Content-type": "application/json",
                        'X-APP-SECRET': X_APP_SECRET,
                        Authorization: "Bearer " + token,
                    },
                    body: JSON.stringify(data)
                }).then((result) => {
                    result.json().then((resp) => {
                        setProfile(false)
                        setBg(current => !current);
                        Setprimaryaccerr('')
                        setButtonText('Manage Profile');
                        getProfile()
                        showErrorNotification("Profile Deleted.");
                    })
                })
            }
        } else {
            Setprimaryaccerr("This is Primary Account , Not deleted!")
        }

    }

    const createProfile = () => {
        setErrormsg('');
        var formattedDate = "";

        if (dob != "") {
            var formattedDate = format(dob, 'yyyy-MM-dd');
        }

        var profile_name = profilename
        var user_id = userid
        var maturity_level = maturitylevel
        var birth_date = formattedDate
        var profile_image = selectedImage.base64
        console.warn(profile_image);
        var data = { profile_name, birth_date, maturity_level, gender, profile_image }

        if (user) {
            fetch(`${API_URL_BASE}${API_URLS.USER_PROFILE_CREATE}`, {
                method: 'POST',
                headers: {
                    Accept: "application/json",
                    "Content-type": "application/json",
                    'X-APP-SECRET': X_APP_SECRET,
                    Authorization: "Bearer " + token,
                },
                body: JSON.stringify(data)
            }).then((result) => {

                result.json().then((resp) => {

                    if (resp.response == false) {
                        setErrormsg(resp.message)

                    } else {
                        setErrormsg('');
                        setSelectedImage({ file: null, base64: null })
                        setCreateshow(false);
                        setImagePreviewSrc(undefined)
                    }

                    setIsVisible(true)

                    getProfile()

                })
            })
        }

    }

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setMaturitylevel(event.target.value);
    }

    const Changegender = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setGender(event.target.value);
    }



    return (
        <>
            <ToastContainer />
            <div className="switch_profile">
                <div className="switch_profile_title">
                    <h3>Who’s Watching?</h3>
                    <h6 className='text-white' >{primaryaccerr}</h6>
                </div>
                <div className="switch_profile_section">
                    <div className="switch_profile_box">
                        {
                            !Profileloading ? (
                                Array.isArray(UserProfile) && UserProfile.map((item) => (
                                    <div className="switch_profile_img" key={item.profile_name}>
                                        <img src={item.profile_image} alt="" onClick={() => selectProfile(item.profile_id)} style={{ width: '200px', height: '200px' }} />

                                        <h5>{item.profile_name}</h5>
                                        {profile ? (
                                            <div className="profile_action">
                                                <div className="profile_icon_action">
                                                    <button onClick={() => handleShow(item.profile_id)}>
                                                        <img src="img/pencile.png" className="mx-0 action_icon" alt="" />
                                                    </button>
                                                    <button onClick={() => profileRemove(item.profile_id)}>
                                                        <img src="img/delete.png" className="mx-0 action_icon" alt="" />
                                                    </button>
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                ))
                            ) : (
                                Array.from({ length: 5 }).map((_, index) => (
                                    <div className="profile_shimmer_card" key={index}></div>
                                ))
                            )
                        }
                        {
                            UserProfile?.length <= 4 ? <div className="switch_profile_img">
                                <input type="file" id="file" className='d-none' />
                                <img onClick={() => createhandleShow()} src="img/profile_five.png" alt="" style={{ width: '200px', height: '200px',objectFit:'contain' }}></img>
                            </div>
                                :
                                null


                        }

                    </div>
                    <div className="manage_pro">
                        <button className={bg ? 'bg-white' : 'manage_profile'} onClick={manageProfile} >{buttonText}</button>
                    </div>
                </div>

                <Modal show={show} centered onHide={handleClose} backdrop="static" size="lg" keyboard={false} className='switch_profile_modal'>

                    <Modal.Body>
                        <div className="modal_header">
                            <h3>Edit Profile</h3>
                        </div>
                        <h6 className='text-white'>{ImgErrormsg}</h6>
                        <div className="profile_change">
                            <div className="row">
                                <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2 res-center text-center profile_select_img">
                                    {/* <img src={image} className="w-100" alt=""></img> */}
                                    <label htmlFor="file">

                                        {imagePreviewSrc != undefined ?
                                            <img src={imagePreviewSrc} className="img-fluid rounded-start" alt="..." />
                                            :
                                            <img src={image} className="w-100" alt=""></img>
                                        }
                                    </label>
                                    <input type="file" id="fileInput" onChange={handleImageUpload} accept="image/*" className="form_box d-none" placeholder="Upload Image" />
                                    {/* <input type="text" value={imagePreviewSrc} onChange={handleImageUpload}  className="form_box d-none"  /> */}
                                    <label htmlFor="fileInput" className="custom-button" onClick={toggleVisibility}>
                                        {isVisible &&
                                            <img src='img/profile_select.png' className='select_img'></img>
                                        }

                                    </label>

                                </div>
                                <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-10 col-sm-10">
                                    <form>
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="form_group">
                                                    <div className="form_label">
                                                        <h4>Name</h4>
                                                    </div>
                                                    <input type="text" value={profilename} onChange={(e) => setProfilename(e.target.value)} className="form_box" placeholder='Name' />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form_group">
                                                    <div className="form_label">
                                                        <h4>Maturity Rating</h4>
                                                    </div>
                                                    <select className='form_box' value={maturitylevel} onChange={handleChange}>
                                                        {maturity.map((item) => (
                                                            <option value={item.key}>
                                                                {item.value}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="form_group">
                                            <div className="row">

                                                <div className="col-lg-6">
                                                    <div className="form_group">
                                                        <div className="form_label">
                                                            <h4>Gender</h4>
                                                        </div>
                                                        <select className='form_box' value={gender} onChange={Changegender}>
                                                            <option value="3">Select Gender</option>
                                                            <option value="0">Male</option>
                                                            <option value="1">Female</option>
                                                            <option value="2">Prefer not to say</option>
                                                        </select>
                                                    </div>

                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form_group">
                                                        <div className="form_label">
                                                            <h4>Birthdate</h4>
                                                        </div>
                                                        <DatePicker
                                                            selected={dob ? new Date(dob) : null}
                                                            dateFormat="dd/MM/yyyy"
                                                            className="input_fild form_box"

                                                            onChange={(dob) => setDob(dob)}
                                                            maxDate={new Date()}
                                                            placeholderText='Birthdate'
                                                            peekNextMonth
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            dropdownMode="select"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form_modal_action">
                                            <button onClick={(e) => { e.preventDefault(); updateProfile(profileid); }} className="update">Update</button>
                                            <input type="submit" onClick={(e) => { e.preventDefault(); handleClose(); }} className="cancel" value="Cancel" />
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal show={createshow} centered onHide={handleClose} backdrop="static" size="lg" keyboard={false} className='switch_profile_modal'>

                    <Modal.Body>
                        <div className="modal_header">
                            <h3>Create Profile</h3>
                        </div>
                        <h6 className='text-white'>{errormsg}</h6>
                        <h6 className='text-white'>{ImgErrormsg}</h6>
                        <div className="profile_change">
                            <div className="row">
                                <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2 res-center text-center profile_select_img">
                                    {/* <input type="file" id="file" className="switch_porfile_img " />
                                    <label htmlFor="file">
                                        <img src="img/swicth_one.png"  className="w-100" alt=""></img>
                                        <input type="file" onChange={handleImageUpload} accept="image/*" className="form_box"
                                            placeholder="Upload Image" />
                                    </label> */}
                                    <label htmlFor="file">

                                        {imagePreviewSrc != undefined ?
                                            <img src={imagePreviewSrc} className="img-fluid rounded-start create_profile_img" alt="..." />
                                            :
                                            <img src='img/profile_select.png' className='select_img'></img>
                                        }
                                        <input type="file" id="fileInput" onChange={handleImageUpload} accept="image/*" className="form_box d-none" placeholder="Upload Image" />
                                    </label>
                                    <label htmlFor="fileInput" className="custom-button" onClick={toggleVisibility}>
                                        {isVisible &&
                                            <img src='img/profile_select.png' className='select_img'></img>
                                        }

                                    </label>
                                </div>
                                <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-10 col-sm-10">
                                    <form>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form_group">
                                                    <div className="form_label">
                                                        <h4>Name</h4>
                                                    </div>
                                                    <input type="text" value={profilename} onChange={(e) => setProfilename(e.target.value)} className="form_box" placeholder='Name' />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form_group">
                                                    <div className="form_label">
                                                        <h4>Maturity Rating</h4>
                                                    </div>

                                                    <select className='form_box' value={maturitylevel} onChange={handleChange}>
                                                        {maturity.map((item) => (
                                                            <option value={item.key}>
                                                                {item.value}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form_group">
                                                    <div className="form_label">
                                                        <h4>Gender</h4>
                                                    </div>

                                                    <select className='form_box' value={gender} onChange={Changegender}>
                                                        <option value="3">Select Gender</option>
                                                        <option value="0">Male</option>
                                                        <option value="1">Female</option>
                                                        <option value="2">Prefer not to say</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form_group">
                                                    <div className="form_label">
                                                        <h4>Birthdate</h4>
                                                    </div>
                                                    {/* <input type="date" value={dob} onChange={(e) => setDob(e.target.value)} className="form_box" placeholder='Maturity Level' /> */}
                                                    <DatePicker
                                                        selected={dob ? new Date(dob) : null}
                                                        dateFormat="dd/MM/yyyy"
                                                        className="input_fild form_box"

                                                        onChange={(dob) => setDob(dob)}
                                                        maxDate={new Date()}
                                                        placeholderText='Birthdate'
                                                        peekNextMonth
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form_modal_action">

                                            <button onClick={(e) => { e.preventDefault(); createProfile(); }} className="update" >Create</button>
                                            <input type="submit" onClick={(e) => { e.preventDefault(); CreatehandleClose(); }} className="cancel" value="Cancel" />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
            {isDivVisible && (
                <div className='black_box'></div>
            )}
        </>
    )
}
