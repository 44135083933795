import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {

    const currentYear = new Date().getFullYear();

    return (
        <>
            <div className="footer_section">
                <div className="container">
                    <div className="footer_text">
                        <div className="footer_logo">
                            <img src="./img/play_now_logo.png" alt=""></img>
                            <p>Alphanso Tech is a leading IT Consulting company Our commitment to quality and innovation has given us the
                                privilege over 200+</p>
                        </div>
                        <div className="footer_contant">
                            <div className="footer_title">
                                <h3>Quick links</h3>
                            </div>
                            <div className="footer_links">
                                <ul>
                                    <li><Link to={""}>Help Center</Link></li>
                                    <li><Link to={""}>Careers</Link></li>
                                    <li><Link to={"https://www.alphansotech.com/contact"}>Contact Us</Link></li>
                                </ul>
                            </div>

                        </div>
                        <div className="footer_contant">
                            <div className="footer_title">
                                <h3>Our Policy</h3>
                            </div>
                            <div className="footer_links">
                                <ul>
                                    <li><Link to={"/privacy-policy"}>Privacy policy</Link></li>
                                    <li><Link to={""}>Refund Policy</Link></li>
                                    <li><Link to={""}>Terms and Conditions</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="footer_contant">
                            <div className="footer_title">
                                <h3>Our Policy</h3>
                            </div>
                            <div className="footer_links">
                                <ul>

                                    <li><Link to={"/privacy-policy"}>Privacy policy</Link></li>
                                    <li><Link to={""}>Refund Policy</Link></li>
                                    <li><Link to={""}>Terms and Conditions</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sub_footer">
                    <div className="container">
                        <div className="sub_footer_text">
                            <p>Alphanso Tech © {currentYear} | All rights reserved</p>
                            <ul>
                                <li><Link to={""}><img src="img/facebook.png" alt=""></img></Link></li>
                                <li><Link to={""}><img src="img/twitter.png" alt=""></img></Link></li>
                                <li><Link to={""}><img src="img/youtube-footer.png" alt=""></img></Link></li>
                                <li><Link to={""}><img src="img/linkdin.png" alt=""></img></Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
