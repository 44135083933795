import React, { useEffect, useState } from 'react'
import Carousel from 'react-bootstrap/Carousel';
import { CarouselItem, Container } from 'react-bootstrap';
import OwlCarousel from 'react-owl-carousel2';
import Header from './Layout/Header';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Footer from './Layout/Footer';
import Modal from 'react-bootstrap/Modal';
import { FeaturesData, FormatTimeWithHourMinute, Session, User, UserCountry, UserLocationData, useHomePageApi, usePlayVideo } from './Helper';
import { UserInfo, Moviedatatype, sliderdatatype } from './Model/Interface';
import { API_URL_BASE, STRIPE_TEST_KEY, X_APP_SECRET, headers } from './utils';
import { API_URLS } from './utils';
import Watchlist from './Watchlist';
import { isArray, isEmpty, isNumber, isUndefined } from 'lodash';
import { WatchlistStatusInterface, FavoriteStatusInterface } from './Model/Interface';
import { TvShowdataTypes } from './Model/Interface';
import { Tvshowdatatype } from './Model/Interface';
import { CountinueWatchdatatype } from './Model/Interface';
import routes from './Routes';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import { exit, exitCode } from 'process';
import { Elements } from '@stripe/react-stripe-js';
import ItemPurchase from './ItemPurchase';
import { loadStripe } from '@stripe/stripe-js';

export default function Home() {


    const [showThree, setShowThree] = useState(false);

    const handleShowThree = () => {
        setShowThree(true);
        setShowTwo(false);
        setShowStripe(false)
        setShow(false)
        setTVShow(false)

    }


    const userCountry = UserCountry();
    const [showModal, setshowModal] = useState(false);
    const handleClosee = () => setshowModal(false);
    const loginModal = () => setshowModal(true);
    const FeaturesList = FeaturesData();
    const UserLocation = UserLocationData();
    const stripePromise = loadStripe(STRIPE_TEST_KEY);
    

    const [showTwo, setShowTwo] = useState(false);
    const [showStripe, setShowStripe] = useState(false);

    const [moviePrice, setmoviePrice] = useState("");
    const [currencySymbol, setcurrencySymbol] = useState("");
    const [tvShowPrice, settvShowPrice] = useState("");
    const [itemVideoId, setitemVideoId] = useState("");
    const [itemDataType, setitemDataType] = useState("");
    const [itemPrice, setitemPrice] = useState("");
    const [itemTitle, setitemTitle] = useState("");
    

    const handleCloseTwo = () => {
        setShowTwo(false);
        setShowStripe(false)
        // setitemVideoId("")
        // setitemDataType("")
    }
    const handleCloseThree = () =>{
        setpaymentLoder(false);
        setShowThree(false)
    }

    const handleShowTwo = (data_id:any,data_type:string) =>{

        if (!token) {
            if (data_type == "movie") {
                handleClose()
            } else {
                handleCloseTvshow()
            }
            setshowModal(true)
            return;
        }

        setShowTwo(true);
        setitemVideoId(data_id)
        setitemDataType(data_type)
    }
    
    let movie_name_modal_close = () =>{
        setShowTwo(false)
        
        if(itemDataType == 'movie'){
            setShow(true)
        }else{
            setTVShow(true)
        }
    }



    function showSuccessNotification(message: any) {
        toast.success(message, {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    }

    function showErrorNotification(message: any) {
        toast.error(message, {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    }


    const [showTVShow, setTVShow] = useState(false);
    const handleCloseTvshow = () => {
        setTVShow(false)
        setTvshowid("")
        setSeasonId("")
        setEpisodeOrSeasonchCheck(true)
    }
    const PlayVideo = (data_id: number, watching_time: string = "") => {
        const data_type: string = "movie";
        navigate(routes.VideoWatch, { state: { data_id, data_type, watching_time } });
    };

    const PlayEpisode = (data_id: number, TVShow_id: number | null = null, watching_time: string = "",) => {
        const data_type: string = "episode";
        navigate(routes.VideoWatch, { state: { data_id, data_type, watching_time, TVShow_id } });
    };

    const [Tvshowmodeldata, setTvshowmodeldata] = useState<Tvshowdatatype>();
    const [FirstepisodeID, setFirstepisodeID] = useState<number>();
    const [episode, setEpisode] = useState<any>();
    const [EpisodeLoading, setEpisodeLoading] = useState(true);
    const [tvshowid, setTvshowid] = useState<any>();
    const [SeasonId, setSeasonId] = useState("");
    const [MoreLikeThisTVShowdata, setMoreLikeThisTVShowdata] = useState<TvShowdataTypes | undefined>()
    // const [CountinueWatchdata, setCountinueWatchdata] = useState<CountinueWatchdatatype | undefined>()
    const [CountinueWatchdata, setCountinueWatchdata] = useState<CountinueWatchdatatype[] | any[]>([]);
    const [ContinueWatchCheck, setContinueWatchCheck] = useState(false);
    const [NewreleseTvshow, setNewreleseTvshow] = useState<Tvshowdatatype[] | any[]>([]);
    const [NewreleseTvshowLoading, setNewreleseTvshowLoading] = useState(true);
    const [NewreleseTvshowCheck, setNewreleseTvshowCheck] = useState(true);
    const [CheckMoreLikeThisMovie, setCheckMoreLikeThisMovie] = useState(true);

    // const { PlayEpisode } = usePlayVideo();

    const [show, setShow] = useState(false);

    var user = User();

    const payPerView        =  FeaturesList?.data.pay_per_view;
    const userSubscription  =  user?.data.user_subscription;
    
    const token = user?.data.token
    var session = Session();
    const handleClose = () => {
        setCheckMoreLikeThisMovie(true);
        setShow(false);
    }

    const [modeldata, setModeldata] = useState<{
        data_id: number;
        data_type: string;
        title: string;
        description: string;
        maturity_level: string;
        release_date: string;
        media_banner: string;
        media_poster: string;
        genres: { genre_id: string; genre_title: string; genre_image: string }[];
        celebs: any[]; // Adjust the type as needed
        duration: string;
        premium: {
          subscriber_price: string;
          nonsubscriber_price: string;
          currency: string;
          currency_symbol: string;
          is_paid: boolean;
        };
        is_favourite: any;
        is_watchlist: any;
      }>();
      
    const [MoviedataLength, setMoviedataLength] = useState();
    const [paymentLoder, setpaymentLoder] = useState(false);
    const [slider, setSlider] = useState<sliderdatatype>();
    const [MoreLikeThisMoviedata, setMoreLikeThisMoviedata] = useState<Moviedatatype[] | undefined>([]);
    const [MorelikeThisLoading, setMorelikeThisLoading] = useState(true);

    const [continueWatchloading, setcontinueWatchloading] = useState(true);
    const [Sliderloading, setSliderloading] = useState(true);
    const [Watchlist, setWatchlist] = useState("");
    const [key, setKey] = useState(0);
    const navigate = useNavigate();
    const { CheckMovieData, loading, Moviedata, Trendingloading, TrendingMoviedata, TrendingTvshow, TrendingTvshowLoading, TrendingMovieCheck, TrendingTvshowCheck } = useHomePageApi();
    const [TopSearchMovie, setTopSearchMovie] = useState<Moviedatatype[] | any[]>([]);
    const [TopSearchMovieLoading, setTopSearchMovieLoading] = useState(true);
    const [TopsearchCheck, setTopSearchcheck] = useState(false);
    const [EpisodeOrSeasonchCheck, setEpisodeOrSeasonchCheck] = useState(true);

    const viewAllCountinueWatchdata = CountinueWatchdata.length >= 10;
    const viewAllMoviedata = Moviedata.length >= 10;
    const viewAllTrendingMoviedata = TrendingMoviedata.length >= 10;
    const viewAllNewreleseTvshow = NewreleseTvshow.length >= 10;
    const viewAllTrendingTvshow = TrendingTvshow.length >= 10;
    const viewAllTopSearchMovie = TopSearchMovie.length === 10;


    const updateVideoPaymentStatus = () => {

        handleCloseThree()
        
        if(itemDataType == 'movie'){
            if (modeldata && typeof modeldata === 'object' && 'premium' in modeldata) {
                
                setModeldata({
                    ...modeldata,
                    premium: {
                    ...modeldata.premium,
                    is_paid: true,
                    },
                });

                movie_name_modal_close();
                // PlayVideo(Number(itemVideoId))
            } else {
                
                console.error('Invalid modeldata structure');
            }
            
        }else{

            if (Tvshowmodeldata && typeof Tvshowmodeldata === 'object' && 'premium' in Tvshowmodeldata && FirstepisodeID) {
                
                setTvshowmodeldata({
                    ...Tvshowmodeldata,
                    premium: {
                    ...Tvshowmodeldata.premium,
                    is_paid: true,
                    },
                });
                
                movie_name_modal_close();
                // PlayEpisode(FirstepisodeID,Number(itemVideoId))
            }
            
        }
    };

    const errorInVideoPayment = () => {

        handleCloseThree()
        if(itemDataType == 'movie'){
            movie_name_modal_close();
        }else{
            movie_name_modal_close();
        }
    };

    const loderShow = () => {
        setpaymentLoder(true);
    };

    const loderHide = () => {
        
        // setShowThree(false)
        // setpaymentLoder(false);
    };

    


    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setEpisode(undefined);
        setEpisodeLoading(true)
        setEpisodeOrSeasonchCheck(true)

        const SeasonsId = event.target.value;

        const Episoderequest = {
            profile_id: session?.profile_id, // Use profileId here
            tvshow_id: tvshowid,
            season_id: SeasonsId,
            paginate: false,
            limit: -1,
            order_by: ["created_at", "asc"],
            country_code: UserLocation.country_code
        };
        const EpisoderequestBody = JSON.stringify(Episoderequest);

        fetch(`${API_URL_BASE}${API_URLS.TVSHOW_SEASON_EPISODE}`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-type": "application/json",
                'X-APP-SECRET': X_APP_SECRET,
                Authorization: "Bearer " + token,
            },
            body: EpisoderequestBody,
        }).then((result) => {

            result.json().then((resp) => {

                if (resp.response == true) {
                    setEpisode(resp.data);
                    setEpisodeLoading(false)
                } else {
                    setEpisodeOrSeasonchCheck(false)
                }
            })
        })

    };

    const handleTvShow = (tvshow_id: number) => {
        setTVShow(true);
        setTvshowmodeldata(undefined);
        setEpisode(undefined)
        setMoreLikeThisTVShowdata(undefined);
        setEpisodeLoading(true)
        const requestData = {
            profile_id: session?.profile_id, // Use profileId here
            tvshow_id: tvshow_id,
            country_code: UserLocation.country_code
        };
        const requestBody = JSON.stringify(requestData);


        fetch(`${API_URL_BASE}${API_URLS.TVSHOW_DETAILS}`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-type": "application/json",
                'X-APP-SECRET': X_APP_SECRET,
                Authorization: "Bearer " + token,
            },
            body: requestBody,
        }).then((result) => {

            result.json().then((resp) => {

                if (resp.response == true) {

                    setitemTitle(resp.data.title)
                    
                    if(payPerView ==  true){
                        setcurrencySymbol(resp.data.premium.currency_symbol)
                        if(userSubscription == true){   

                            settvShowPrice(resp.data.premium.subscriber_price)
                            setitemPrice(resp.data.premium.subscriber_price)

                        }else{
                            settvShowPrice(resp.data.premium.nonsubscriber_price)
                            setitemPrice(resp.data.premium.nonsubscriber_price)
                        }
                    }
                    setTvshowmodeldata(resp.data);
                    setTvshowid(tvshow_id);

                    if (resp.data['season'].length > 0) {
                        setSeasonId(resp.data['season'][0]['season_id']);
                    } else {
                        setEpisodeOrSeasonchCheck(false)
                    }
                }
            })
        })

        fetch(`${API_URL_BASE}${API_URLS.TVSHOW_MORE_LIKE_THIS}`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-type": "application/json",
                'X-APP-SECRET': X_APP_SECRET,
                Authorization: "Bearer " + token,
            },
            body: requestBody,

        }).then((result) => {

            result.json().then((resp) => {

                if (resp.response == true) {
                    setMoreLikeThisTVShowdata(resp.data.tvshows)
                } else {
                    console.warn("Responce False Please Tru Again")
                }
            })
        })
    }
    useEffect(() => {
        setEpisode(undefined);
        if (tvshowid && SeasonId) {


            const Episoderequest = {
                profile_id: session?.profile_id, // Use profileId here
                tvshow_id: tvshowid,
                season_id: SeasonId,
                paginate: false,
                limit: -1,
                order_by: ["created_at", "asc"],
                country_code: UserLocation.country_code
            };

            const EpisoderequestBody = JSON.stringify(Episoderequest);

            fetch(`${API_URL_BASE}${API_URLS.TVSHOW_SEASON_EPISODE}`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-type": "application/json",
                    'X-APP-SECRET': X_APP_SECRET,
                    Authorization: "Bearer " + token,
                },
                body: EpisoderequestBody,
            }).then((result) => {

                result.json().then((resp) => {

                    if (resp.response == true) {
                        setEpisode(resp.data);
                        setFirstepisodeID(resp.data[0]['data_id'])
                        setEpisodeLoading(false)
                    } else {
                        setEpisodeOrSeasonchCheck(false)
                    }
                })
            })
        }

    }, [tvshowid, SeasonId]);

    const handleShow = (data_id: number) => {
        setmoviePrice("")
        setShow(true);
        setModeldata(undefined);
        setMoreLikeThisMoviedata(undefined)
        const requestData = {
            profile_id: session?.profile_id, // Use profileId here
            movie_id: data_id,
            country_code: UserLocation.country_code
        };
        const requestBody = JSON.stringify(requestData);


        fetch(`${API_URL_BASE}${API_URLS.MOVIE_DETIALS}`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-type": "application/json",
                'X-APP-SECRET': X_APP_SECRET,
                Authorization: "Bearer " + token,
            },
            body: requestBody,
        }).then((result) => {

            result.json().then((resp) => {

                if (resp.response == true) {
                    setModeldata(resp.data);
                    setitemTitle(resp.data.title)
                    if(payPerView ==  true){

                        setcurrencySymbol(resp.data.premium.currency_symbol)
                        if(userSubscription == true){   

                            setmoviePrice(resp.data.premium.subscriber_price)
                            setitemPrice(resp.data.premium.subscriber_price)

                        }else{
                            setmoviePrice(resp.data.premium.nonsubscriber_price)
                            setitemPrice(resp.data.premium.nonsubscriber_price)
                        }
                    }
                    
                }
            })
        })

        fetch(`${API_URL_BASE}${API_URLS.MORE_LIKE_THIS_MOVIES}`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-type": "application/json",
                'X-APP-SECRET': X_APP_SECRET,
                Authorization: "Bearer " + token,
            },
            body: requestBody,

        }).then((result) => {

            result.json().then((resp) => {

                if (resp.response == true) {

                    setMoreLikeThisMoviedata(resp.data)
                    setMorelikeThisLoading(false)
                } else {
                    setCheckMoreLikeThisMovie(false)
                }
            })
        })

    }


    const [profiledata, setProfiledata] = React.useState<string>();



    useEffect(() => {
        setSliderloading(true);
        const requestData = {
            profile_id: session?.profile_id, // Use profileId here
            paginate: false,
            limit: -1,
            country_code: UserLocation.country_code
        };

        const requestBody = JSON.stringify(requestData);

        const sliderData = {
            profile_id: session?.profile_id,
            data_type: 'movie',
            country_code: UserLocation.country_code
        };
        const request = JSON.stringify(sliderData);

        fetch(`${API_URL_BASE}${API_URLS.HOME_SLIDER}`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-type": "application/json",
                'X-APP-SECRET': X_APP_SECRET,
                Authorization: "Bearer " + token,
            },
            body: request,
        })
            .then((result) => result.json())
            .then((resp) => {
                if (resp.response === true) {

                    setSlider(resp.data);
                    setSliderloading(false)
                } else {
                    console.warn("API request succeeded, but the response indicates an error");
                }
            })
            .catch((error) => {
                console.error("API request error:", error);
            });


        fetch(`${API_URL_BASE}${API_URLS.TOP_SEARCH_LIST}`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-type": "application/json",
                'X-APP-SECRET': X_APP_SECRET,
                Authorization: "Bearer " + token,
            },
            body: requestBody,
        })
            .then((result) => result.json())
            .then((resp) => {
                if (resp.response === true) {
                    setTopSearchMovie(resp.data);
                    setTopSearchMovieLoading(false);
                    setTopSearchcheck(true);
                }
            })
            .catch((error) => {
                console.error("API request error:", error);
            });


        fetch(`${API_URL_BASE}${API_URLS.TVSHOW_NEW_RELEASE}`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-type": "application/json",
                'X-APP-SECRET': X_APP_SECRET,
                Authorization: "Bearer " + token,
            },
            body: requestBody,
        })
            .then((result) => result.json())
            .then((resp) => {
                if (resp.response === true) {

                    setNewreleseTvshow(resp.data);
                    setNewreleseTvshowLoading(false);
                } else {
                    setNewreleseTvshowCheck(false);
                }
            })
            .catch((error) => {
                console.error("API request error:", error);
            });
    }, []);




    useEffect(() => {
        const fetchDataContinueWatch = () => {
            setcontinueWatchloading(true);
            const requestData = {
                profile_id: session?.profile_id,
                paginate: false,
                limit: -1,
                country_code: UserLocation.country_code
            };
            const requestBody = JSON.stringify(requestData);
            fetch(`${API_URL_BASE}${API_URLS.CONTINUE_WATCHING_LIST}`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-type": "application/json",
                    'X-APP-SECRET': X_APP_SECRET,
                    Authorization: "Bearer " + token,
                },
                body: requestBody,
            })
                .then((result) => {
                    result.json().then((resp) => {
                        if (resp.response === true) {
                            setCountinueWatchdata(resp.data);
                            setcontinueWatchloading(false);
                            setContinueWatchCheck(true);
                        }
                    });
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });
        };
        fetchDataContinueWatch();

    }, []);


    const [watchlistStatus, setWatchlistStatus] = useState<WatchlistStatusInterface>([]);
    const [NewWatchlistStatus, setNewWatchlistStatus] = useState<WatchlistStatusInterface>([]);

    const WatchlistAdd = (data_id: number, data_type: string) => {
        if (!token) {
            if (data_type == "movie") {
                handleClose()
            } else {
                handleCloseTvshow()
            }
            setshowModal(true)
            return;
        }
        const action = watchlistStatus[data_id] ? 0 : 1; // Toggle action
        const requestData = {
            profile_id: session?.profile_id, // Use profileId here
            data_type: data_type == "movie" ? "movie" : "tvshow",
            data_id: data_id,
            action: action,
            country_code: UserLocation.country_code
        };
        const requestBody = JSON.stringify(requestData);

        fetch(`${API_URL_BASE}${API_URLS.WATCHLIST_ADD_REMOVE}`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-type": "application/json",
                'X-APP-SECRET': X_APP_SECRET,
                Authorization: "Bearer " + token,
            },
            body: requestBody,
        })
            .then((result) => result.json())
            .then((resp) => {
                if (resp.response === true) {

                    setKey((prevKey) => prevKey + 1);


                    setWatchlistStatus((prevStatus: any) => ({
                        ...prevStatus,
                        [data_id]: action === 1, // If action is 1, the item is not in the watchlist, otherwise it's in the watchlist
                    }));
                }
            })
            .catch((error) => {
                console.error("Error while updating watchlist:", error);
            });

    };

    useEffect(() => {

        const filteredData = Moviedata.filter((item) => item.is_watchlist === "true");
        const watchlistStatusDATA: any = [];
        filteredData.forEach((item) => {
            watchlistStatusDATA[item.data_id] = true;
        });
        setWatchlistStatus(watchlistStatusDATA);

    }, [Moviedata]);


    useEffect(() => {

        const initialWatchlistStatus: any = {};
        Moviedata.forEach((item) => {
            initialWatchlistStatus[item.data_id] = item.is_watchlist === "true";
        });
        setNewWatchlistStatus(initialWatchlistStatus);

    }, [key]);

    const [favoriteStatus, setFavoriteStatus] = useState<FavoriteStatusInterface>([]);
    const [NewFavoriteStatus, setNewFavotiteStatus] = useState<FavoriteStatusInterface>([]);


    const FavoriteAdd = (data_id: number, data_type: string) => {
        if (!token) {
            if (data_type == "movie") {
                handleClose()
            } else {
                handleCloseTvshow()
            }
            setshowModal(true)
            return;
        }
        const action = favoriteStatus[data_id] ? 0 : 1; // Toggle action

        const requestData = {
            profile_id: session?.profile_id, // Use profileId here
            data_type: data_type == "movie" ? "movie" : "tvshow",
            data_id: data_id,
            action: action,
            country_code: UserLocation.country_code
        };
        const requestBody = JSON.stringify(requestData);

        fetch(`${API_URL_BASE}${API_URLS.FAVORITE_ADD_REMOVE}`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-type": "application/json",
                'X-APP-SECRET': X_APP_SECRET,
                Authorization: "Bearer " + token,
            },
            body: requestBody,
        })
            .then((result) => result.json())
            .then((resp) => {
                if (resp.response === true) {

                    setKey((prevKey) => prevKey + 1);

                    setFavoriteStatus((prevStatus: any) => ({
                        ...prevStatus,
                        [data_id]: action === 1, // If action is 1, the item is not in the watchlist, otherwise it's in the watchlist
                    }));
                }
            })
            .catch((error) => {
                console.error("Error while updating watchlist:", error);
            });

    };


    useEffect(() => {

        const filteredData = Moviedata.filter((item) => item.is_favourite === "true");
        const FavoriteStatusDATA: any = [];
        filteredData.forEach((item) => {
            FavoriteStatusDATA[item.data_id] = true;
        });
        setFavoriteStatus(FavoriteStatusDATA);

    }, [Moviedata]);


    useEffect(() => {

        const initialWatchlistStatus: any = {};
        Moviedata.forEach((item) => {
            initialWatchlistStatus[item.data_id] = item.is_favourite === "true";
        });
        setNewFavotiteStatus(initialWatchlistStatus);


    }, [key]);

    const options = {
        nav: true,
        dots: false,
        stagePadding: 40,
        navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"],
        responsive: {
            325: {
                items: 2,
            },
            425: {
                items: 2,
            },
            575: {
                items: 3,
            },
            767: {
                items: 4,
            },
            991: {
                items: 5,
            },
            1199: {
                items: 5,
            },
            1024: {
                items: 7,
            },
            1920: {
                items: 9,
            },


        },
    };

    return (

        <>
        {console.warn(slider)}
            <Header />
            <ToastContainer />
            <div className='slider_section'>

                <Carousel controls indicators pause={false} >
                    {
                        !Sliderloading ?
                            Array.isArray(slider) && slider.map((item) => (
                                
                                <CarouselItem key={item.title}>
                                    <div className={`slider_common_banner ${item.media_banner}`} style={{ backgroundImage: `url(${item.media_banner})` }}   >
                                        <Container fluid>
                                            <div className="img_content">
                                                <h1>{item.title}</h1>
                                                <p>{item.description}</p>
                                                <div className="img_btn">
                                                {item.is_paid == true ? <button className="play_btn btn_img" onClick={() => PlayVideo(item.data_id)}><img src="/img/play.png" alt=""></img>Play</button>:<button className="play_btn btn_img" onClick={() => handleShow(item.data_id)}><img src="/img/play.png" alt=""></img>Play</button>}
                                                    <button className="play_info btn_img" onClick={() => handleShow(item.data_id)} ><img src="/img/info.png" alt=""></img>More info</button>
                                                </div>
                                            </div>
                                        </Container>
                                    </div>
                                </CarouselItem>
                            ))
                            : <>
                                <div className='animation_section'>
                                    <div className='title_animation'></div>
                                    <div className='text_animation'></div>
                                    <div className='btn_animation'>
                                        <div className='sm_animate'></div>
                                        <div className='lg_animate'></div>
                                    </div>
                                </div>
                            </>
                    }
                </Carousel>
            </div>

            {
                ContinueWatchCheck ? (
                    <div className='index_second_section index_more_content_first'>
                        <Container fluid className='cust_container'>
                            <div className='index_more_content'>
                                <div className="episode_title">
                                    <h4>Continue Watching</h4>
                                    {viewAllCountinueWatchdata && (
                                        <Link to={"/ContinueWatching/all"}>View All &nbsp;<img src='img/next_icon.png'></img></Link>
                                    )}
                                </div>
                                <div className="episode_slider">
                                    <OwlCarousel
                                        options={options}
                                    >
                                        {
                                            !continueWatchloading && CountinueWatchdata !== undefined ? (
                                                CountinueWatchdata && Array.isArray(CountinueWatchdata) && CountinueWatchdata.map((item) => (
                                                    item.data_type === "movie" ? (
                                                        <div className="item_box" key={item.data_detail.data_id}>
                                                            <div onClick={() => handleShow(item.data_detail.data_id)}>
                                                                <img src={item.data_detail.media_poster} alt={item.data_detail.title} />
                                                            </div>
                                                            <div className="item_img_details">
                                                                <div className="item_img_header">
                                                                    <h5>{item.data_detail.title}</h5>
                                                                    <ul>
                                                                        <li onClick={() => PlayVideo(item.data_detail.data_id, item.watching_duration)}>
                                                                            <img src="img/play-icon.png" alt="" />
                                                                        </li>
                                                                        {/* <li onClick={() => WatchlistAdd(item.data_detail.data_id, item.data_type)}> */}
                                                                        <li>
                                                                            {watchlistStatus[item.data_detail.data_id] ? (
                                                                                <img src="img/check-white.png" alt="" />
                                                                            ) : (
                                                                                <img src="img/add-icon.png" alt="" />
                                                                            )}
                                                                        </li>
                                                                        {/* <li onClick={() => FavoriteAdd(item.data_detail.data_id, item.data_type)}> */}
                                                                        <li >
                                                                            {favoriteStatus[item.data_detail.data_id] ? (
                                                                                <img src="img/fav.png" alt="" /> // Display the 'fav.png' image if it's a favorite
                                                                            ) : (
                                                                                <img src="img/like stroke.png" alt="" /> // Display the 'like-stroke.png' image if it's not a favorite
                                                                            )}
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div className="item_img_number">
                                                                    <h3> {item.data_detail.maturity_level}</h3>
                                                                    <h4>{item.data_detail.release_date ? (new Date(item.data_detail.release_date)).getFullYear() : ""} </h4>
                                                                </div>
                                                            </div>
                                                            {/* {
                                                            item.data_detail.is_premium == true ?
                                                            <div className='premium_icon'>
                                                                <Link to={"#"}><img src='img/premium_icon.png' /></Link>
                                                            </div>: "" 
                                                            } */}
                                                        </div>
                                                    ) : (

                                                        <div className="item_box" key={item.data_detail.data_id}>
                                                            <div onClick={() => handleTvShow(item.data_detail.data_id)}>
                                                                <img src={item.data_detail.media_poster} alt={item.data_detail.title} />
                                                            </div>
                                                            <div className="item_img_details">
                                                                <div className="item_img_header">
                                                                    <h5>{item.data_detail.episode.title}</h5>
                                                                    <ul>
                                                                        <li onClick={() => PlayEpisode(item.data_detail.episode.data_id, item.data_detail.data_id, item.watching_duration)}>
                                                                            <img src="img/play-icon.png" alt="" />
                                                                        </li>
                                                                        {/* <li onClick={() => WatchlistAdd(item.data_detail.data_id, item.data_type)}> */}
                                                                        <li>
                                                                            {watchlistStatus[item.data_detail.data_id] ? (
                                                                                <img src="img/check-white.png" alt="" />
                                                                            ) : (
                                                                                <img src="img/add-icon.png" alt="" />
                                                                            )}
                                                                        </li>
                                                                        {/* <li onClick={() => FavoriteAdd(item.data_detail.data_id, item.data_type)}> */}
                                                                        <li>
                                                                            {favoriteStatus[item.data_detail.data_id] ? (
                                                                                <img src="img/fav.png" alt="" />
                                                                            ) : (
                                                                                <img src="img/like stroke.png" alt="" />
                                                                            )}
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div className="item_img_number">
                                                                    <h3> {item.data_detail.maturity_level}</h3>
                                                                    <h4>{item.data_detail.release_date ? (new Date(item.data_detail.release_date)).getFullYear() : ""} </h4>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    )
                                                ))
                                            ) : (
                                                Array.from({ length: 10 }).map((_, index) => (
                                                    <div className="shimmer_card" key={index}></div>
                                                ))
                                            )
                                        }
                                    </OwlCarousel>
                                </div>
                            </div>
                        </Container>
                    </div>
                ) : null
            }

            {
                CheckMovieData ? (
                    <div className='index_second_section '>
                        <Container fluid className='cust_container'>
                            <div className='index_more_content'>
                                <div className="episode_title">

                                    <h4>New Release Movies</h4>

                                    {viewAllMoviedata && (
                                        <Link to={"/view/NewRelease"}>View All &nbsp;<img src='img/next_icon.png'></img></Link>
                                    )}
                                </div>
                                <div className="episode_slider">
                                    <OwlCarousel
                                        options={options}
                                    >
                                        {
                                            (!loading && Array.isArray(Moviedata)) ? (
                                                Moviedata.map((item) => (
                                                    <div className="item_box" key={item.data_id}>
                                                        <div onClick={() => handleShow(item.data_id)}>
                                                            <img src={item.media_poster} alt={item.title} />
                                                        </div>
                                                        <div className="item_img_details">
                                                            <div className="item_img_header">
                                                                <h5>{item.title}</h5>
                                                                <ul>
                                                                    
                                                                    {item.is_paid == true ? <li onClick={() => PlayVideo(item.data_id)}><img src="img/play-icon.png" alt="" /></li>:<li onClick={() => handleShow(item.data_id)}><img src="img/play-icon.png" alt="" /></li>}

                                                                    {/* <li onClick={() => WatchlistAdd(item.data_id, item.data_type)}> */}
                                                                    <li >
                                                                        {watchlistStatus[item.data_id] ? (
                                                                            <img src="img/check-white.png" alt="" />
                                                                        ) : (
                                                                            <img src="img/add-icon.png" alt="" />
                                                                        )}

                                                                    </li>

                                                                    {/* <li onClick={() => FavoriteAdd(item.data_id, item.data_type)}> */}
                                                                    <li>
                                                                        {favoriteStatus[item.data_id] ? (
                                                                            <img src="img/fav.png" alt="" /> // Display the 'fav.png' image if it's a favorite
                                                                        ) : (
                                                                            <img src="img/like stroke.png" alt="" /> // Display the 'like-stroke.png' image if it's not a favorite
                                                                        )}
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <div className="item_img_number">
                                                                <h3> {item.maturity_level}</h3>
                                                                <h4>{item.release_date ? (new Date(item.release_date)).getFullYear() : ""} </h4>
                                                            </div>
                                                        </div>
                                                        {
                                                        item.is_premium == true && item.is_paid == false ? <div className='premium_icon'>
                                                            <Link to={"#"}><img src='img/premium_icon.png' /></Link>
                                                        </div>: "" 
                                                        }
                                                    </div>
                                                ))
                                            ) : (
                                                Array.from({ length: 10 }).map((_, index) => (
                                                    <div className="shimmer_card" key={index}></div>
                                                ))
                                            )
                                        }

                                    </OwlCarousel>
                                </div>
                            </div>
                        </Container>
                    </div>
                ) : null
            }

            {
                TrendingMovieCheck ? (
                    <div className='index_second_section '>
                        <Container fluid className='cust_container'>
                            <div className='index_more_content'>
                                <div className="episode_title">
                                    <h4>Trending Now Movies</h4>
                                    {viewAllTrendingMoviedata && (
                                        <Link to={"/view/TrendingNow"}>View All &nbsp;<img src='img/next_icon.png'></img></Link>
                                    )}
                                </div>
                                <div className="episode_slider">
                                    <OwlCarousel
                                        options={options}
                                    >
                                        {
                                            !Trendingloading ?
                                                Array.isArray(TrendingMoviedata) && TrendingMoviedata.map((item) => (

                                                    <div className="item_box" key={item.title}>
                                                        <div onClick={() => handleShow(item.data_id)}>
                                                            <img src={item.media_poster} />
                                                        </div>
                                                        <div className="item_img_details">
                                                            <div className="item_img_header">
                                                                <h5>{item.title}</h5>
                                                                <ul>
                                                                {item.is_paid == true ? <li onClick={() => PlayVideo(item.data_id)}><img src="img/play-icon.png" alt="" /></li>:<li onClick={() => handleShow(item.data_id)}><img src="img/play-icon.png" alt="" /></li>}
                                                                    {watchlistStatus && watchlistStatus[item.data_id] ? (
                                                                        <li onClick={() => WatchlistAdd(item.data_id, item.data_type)}>
                                                                            {watchlistStatus[item.data_id] ? (
                                                                                <img src="img/check-white.png" alt="" />
                                                                            ) : (
                                                                                <img src="img/add-icon.png" alt="" />
                                                                            )}

                                                                        </li>
                                                                    ) : (
                                                                        // <li onClick={() => WatchlistAdd(item.data_id, item.data_type)}>
                                                                        <li>

                                                                            {Object.keys(watchlistStatus).length === 0 && item.is_watchlist == "true" ? (
                                                                                <img src="img/check-white.png" alt="" />
                                                                            ) : (
                                                                                <img src="img/add-icon.png" alt="" />
                                                                            )}
                                                                        </li>
                                                                    )}
                                                                    {/* <li onClick={() => FavoriteAdd(item.data_id, item.data_type)}> */}
                                                                    <li >
                                                                        {favoriteStatus[item.data_id] ? (
                                                                            <img src="img/fav.png" alt="" /> // Display the 'fav.png' image if it's a favorite
                                                                        ) : (
                                                                            <img src="img/like stroke.png" alt="" /> // Display the 'like-stroke.png' image if it's not a favorite
                                                                        )}
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <div className="item_img_number">
                                                                <h3> {item.maturity_level}</h3>
                                                                <h4>{item.release_date ? (new Date(item.release_date)).getFullYear() : ""} </h4>
                                                            </div>
                                                        </div>
                                                        {
                                                        item.is_premium == true && item.is_paid == false ? 
                                                         <div className='premium_icon'>
                                                            <Link to={"#"}><img src='img/premium_icon.png' /></Link>
                                                        </div>: "" 
                                                        }
                                                    </div>
                                                )) : (
                                                    Array.from({ length: 10 }).map((_, index) => (
                                                        <div className="shimmer_card" key={index} ></div>
                                                    ))
                                                )
                                        }
                                    </OwlCarousel>
                                </div>
                            </div>
                        </Container>
                    </div>
                ) : null
            }

            {
                NewreleseTvshowCheck ? (
                    <div className='index_second_section '>
                        <Container fluid className='cust_container'>
                            <div className='index_more_content'>
                                <div className="episode_title">
                                    <h4>New Release TV Shows</h4>
                                    {viewAllNewreleseTvshow && (
                                        <Link to={"/viewall/tvshows/NewReleaseTvshow"}>View All &nbsp;<img src='img/next_icon.png'></img></Link>
                                    )}
                                </div>
                                <div className="episode_slider">
                                    <OwlCarousel
                                        options={options}
                                    >
                                        {
                                            !NewreleseTvshowLoading ?
                                                Array.isArray(NewreleseTvshow) && NewreleseTvshow.map((item) => (
                                                    <div className='item_box' key={item.data_id}>
                                                        <div onClick={() => handleTvShow(item.data_id)}>
                                                            <img className="img" src={item.media_poster} />

                                                        </div>
                                                        <div className="item_img_details">
                                                            <div className="item_img_header" key={item.title}>
                                                                <h5>{item.title}</h5>
                                                                <ul>
                                                                    <li onClick={() => handleTvShow(item.data_id)}><img src="/img/play-icon.png" alt="" /></li>
                                                                    {watchlistStatus && watchlistStatus[item.data_id] ? (
                                                                        // <li onClick={() => WatchlistAdd(item.data_id, item.data_type)}>
                                                                        <li >
                                                                            {watchlistStatus[item.data_id] ? (
                                                                                <img src="img/check-white.png" alt="" />
                                                                            ) : (
                                                                                <img src="img/add-icon.png" alt="" />
                                                                            )}

                                                                        </li>
                                                                    ) : (
                                                                        // <li onClick={() => WatchlistAdd(item.data_id, item.data_type)}>
                                                                        <li>
                                                                            {Object.keys(watchlistStatus).length === 0 && item.is_watchlist == "true" ? (
                                                                                <img src="img/check-white.png" alt="" />
                                                                            ) : (
                                                                                <img src="img/add-icon.png" alt="" />
                                                                            )}
                                                                        </li>
                                                                    )}

                                                                    {/* <li onClick={() => FavoriteAdd(item.data_id, item.data_type)}> */}
                                                                    <li>
                                                                        {favoriteStatus[item.data_id] ? (
                                                                            <img src="img/fav.png" alt="" /> // Display the 'fav.png' image if it's a favorite
                                                                        ) : (
                                                                            <img src="img/like stroke.png" alt="" /> // Display the 'like-stroke.png' image if it's not a favorite
                                                                        )}
                                                                    </li>

                                                                </ul>
                                                            </div>
                                                            <div className="item_img_number">
                                                                <h3> {item.maturity_level}</h3>
                                                                <h4>{item.release_date ? (new Date(item.release_date)).getFullYear() : ""} </h4>
                                                            </div>
                                                        </div>
                                                        {
                                                        item.is_premium == true && item.is_paid == false ? 
                                                         <div className='premium_icon'>
                                                            <Link to={"#"}><img src='img/premium_icon.png' /></Link>
                                                        </div>: "" 
                                                        }
                                                    </div>
                                                )) : (
                                                    Array.from({ length: 10 }).map((_, index) => (
                                                        <div className="shimmer_card" key={index}></div>
                                                    ))
                                                )
                                        }
                                    </OwlCarousel>
                                </div>
                            </div>
                        </Container>
                    </div>
                ) : null
            }

            {
                TrendingTvshowCheck ? (
                    <div className='index_second_section '>
                        <Container fluid className='cust_container'>
                            <div className='index_more_content'>
                                <div className="episode_title">
                                    <h4>Trending Now TV Shows</h4>
                                    {viewAllTrendingTvshow && (
                                        <Link to={"/viewall/tvshows/TrendingNowTvshow"}>View All &nbsp;<img src='img/next_icon.png'></img></Link>
                                    )}
                                </div>
                                <div className="episode_slider">
                                    <OwlCarousel
                                        options={options}
                                    >
                                        {
                                            !TrendingTvshowLoading ?
                                                Array.isArray(TrendingTvshow) && TrendingTvshow.map((item) => (
                                                    <div className='item_box' key={item.data_id}>
                                                        <div onClick={() => handleTvShow(item.data_id)}>
                                                            <img className="img" src={item.media_poster} />
                                                        </div>
                                                        <div className="item_img_details">
                                                            <div className="item_img_header" key={item.title}>
                                                                <h5>{item.title}</h5>
                                                                <ul>
                                                                    <li onClick={() => handleTvShow(item.data_id)}><img src="/img/play-icon.png" alt="" /></li>
                                                                    {watchlistStatus && watchlistStatus[item.data_id] ? (
                                                                        // <li onClick={() => WatchlistAdd(item.data_id, item.data_type)}>
                                                                        <li>
                                                                            {watchlistStatus[item.data_id] ? (
                                                                                <img src="img/check-white.png" alt="" />
                                                                            ) : (
                                                                                <img src="img/add-icon.png" alt="" />
                                                                            )}

                                                                        </li>
                                                                    ) : (
                                                                        // <li onClick={() => WatchlistAdd(item.data_id, item.data_type)}>
                                                                        <li >
                                                                            {Object.keys(watchlistStatus).length === 0 && item.is_watchlist == "true" ? (
                                                                                <img src="img/check-white.png" alt="" />
                                                                            ) : (
                                                                                <img src="img/add-icon.png" alt="" />
                                                                            )}
                                                                        </li>
                                                                    )}

                                                                    {/* <li onClick={() => FavoriteAdd(item.data_id, item.data_type)}> */}
                                                                    <li >
                                                                        {favoriteStatus[item.data_id] ? (
                                                                            <img src="img/fav.png" alt="" /> // Display the 'fav.png' image if it's a favorite
                                                                        ) : (
                                                                            <img src="img/like stroke.png" alt="" /> // Display the 'like-stroke.png' image if it's not a favorite
                                                                        )}
                                                                    </li>

                                                                </ul>
                                                            </div>
                                                            <div className="item_img_number">
                                                                <h3> {item.maturity_level}</h3>
                                                                <h4>{item.release_date ? (new Date(item.release_date)).getFullYear() : ""} </h4>
                                                            </div>
                                                        </div>
                                                        {
                                                            item.is_premium == true && item.is_paid == false ? 
                                                            <div className='premium_icon'>
                                                                <Link to={"#"}><img src='img/premium_icon.png' /></Link>
                                                            </div>: "" 
                                                        }
                                                    </div>
                                                )) : (
                                                    Array.from({ length: 10 }).map((_, index) => (
                                                        <div className="shimmer_card" key={index}></div>
                                                    ))
                                                )
                                        }
                                    </OwlCarousel>
                                </div>
                            </div>
                        </Container>
                    </div>
                ) : null
            }

            {
                TopsearchCheck ? (
                    <div className='index_second_section '>
                        <Container fluid className='cust_container'>
                            <div className='index_more_content'>
                                <div className="episode_title">
                                    <h4>Top Searches</h4>
                                    {viewAllTopSearchMovie && (
                                        <Link to={"/ContinueWatching/topsearch"}>View All &nbsp;<img src='img/next_icon.png'></img></Link>
                                    )}
                                </div>
                                <div className="episode_slider">
                                    <OwlCarousel
                                        options={options}
                                    >
                                        {
                                            !TopSearchMovieLoading ?
                                                Array.isArray(TopSearchMovie) && TopSearchMovie.map((item) => (
                                                    <div className='item_box' key={item.data_id}>
                                                        <div>
                                                            {item.data_type === 'movie' ? (
                                                                <div onClick={() => handleShow(item.data_id)}>
                                                                    <img src={item.media_poster} alt={item.title} />
                                                                </div>
                                                            ) : (
                                                                <div onClick={() => handleTvShow(item.data_id)}>
                                                                    <img src={item.media_poster} alt={item.title} />
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="item_img_details">
                                                            <div className="item_img_header" key={item.title}>
                                                                <h5>{item.title}</h5>
                                                                <ul>

                                                                    {item.data_type === 'movie' ? (
                                                                        item.is_paid == true ? <li onClick={() => PlayVideo(item.data_id)}><img src="img/play-icon.png" alt="" /></li>:<li onClick={() => handleShow(item.data_id)}><img src="img/play-icon.png" alt="" /></li>
                                                                    ) : (
                                                                        <li onClick={() => handleTvShow(item.data_id)}><img src="/img/play-icon.png" alt="" /></li>
                                                                    )}

                                                                    {watchlistStatus && watchlistStatus[item.data_id] ? (
                                                                        // <li onClick={() => WatchlistAdd(item.data_id, item.data_type)}>
                                                                        <li >
                                                                            {watchlistStatus[item.data_id] ? (
                                                                                <img src="img/check-white.png" alt="" />
                                                                            ) : (
                                                                                <img src="img/add-icon.png" alt="" />
                                                                            )}

                                                                        </li>
                                                                    ) : (
                                                                        // <li onClick={() => WatchlistAdd(item.data_id, item.data_type)}>
                                                                        <li >
                                                                            {Object.keys(watchlistStatus).length === 0 && item.is_watchlist == "true" ? (
                                                                                <img src="img/check-white.png" alt="" />
                                                                            ) : (
                                                                                <img src="img/add-icon.png" alt="" />
                                                                            )}
                                                                        </li>
                                                                    )}

                                                                    {/* <li onClick={() => FavoriteAdd(item.data_id, item.data_type)}> */}
                                                                    <li>
                                                                        {favoriteStatus[item.data_id] ? (
                                                                            <img src="img/fav.png" alt="" /> // Display the 'fav.png' image if it's a favorite
                                                                        ) : (
                                                                            <img src="img/like stroke.png" alt="" /> // Display the 'like-stroke.png' image if it's not a favorite
                                                                        )}
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <div className="item_img_number">
                                                                <h3> {item.maturity_level}</h3>
                                                                <h4>{item.release_date ? (new Date(item.release_date)).getFullYear() : ""} </h4>
                                                            </div>
                                                        </div>
                                                        {
                                                            item.is_premium == true && item.is_paid == false ? 
                                                            <div className='premium_icon'>
                                                                <Link to={"#"}><img src='img/premium_icon.png' /></Link>
                                                            </div>: "" 
                                                        }
                                                    </div>
                                                )) : (
                                                    Array.from({ length: 10 }).map((_, index) => (
                                                        <div className="shimmer_card" key={index}></div>
                                                    ))
                                                )
                                        }
                                    </OwlCarousel>
                                </div>
                            </div>
                        </Container>
                    </div>
                ) : null
            }
            {TopsearchCheck === false && TrendingTvshowCheck === false && NewreleseTvshowCheck === false && TrendingMovieCheck === false && CheckMovieData === false && ContinueWatchCheck === false && (
                <div className="App">
                    <div className="error_page">
                        <img src="/img/404.svg" alt="404"></img>
                        <h1>Data Not Available.</h1>
                    </div>
                </div>
            )}
            <Footer />

            <Modal className='series_modal' show={show} onHide={handleClose} backdrop="static" size="lg" keyboard={false} centered>
                <div className="modal_close" onClick={handleClose}>
                    <Link to={""}><img src="img/modal_close.png" alt=""></img></Link>
                </div>

                <Modal.Body>
                    <div className="series_img">
                        {modeldata ? (
                            <img src={modeldata ? modeldata['media_banner'] : 'img/default-banner.png'} className="series_big_imf" alt=""></img>
                        ) : (
                            <div className='series_img_shimmer'></div>
                        )}

                        <div className="series_tool">
                            <div className="series_name">
                                <h2>{modeldata ? modeldata['title'] : ''}</h2>
                            </div>
                            <div className="series_controller">
                                <div className="series_left_controller">

                                    
                                    {modeldata && Object.keys(modeldata['premium']).length > 0  && modeldata['premium']['is_paid']  == false?
                                        <button className="music_play_btn" onClick={() => handleShowTwo(modeldata['data_id'],'movie')}><img src="img/play_icon.jpg"></img>Pay & Play</button>
                                     :  <button onClick={() => modeldata && PlayVideo(modeldata['data_id'])} className="music_play_btn"><img src="img/play_icon.jpg"></img>Play</button>}
                                    

                                    {watchlistStatus && modeldata && watchlistStatus[modeldata['data_id']] ? (

                                        <div onClick={() => WatchlistAdd(modeldata['data_id'], modeldata['data_type'])}>
                                            {watchlistStatus[modeldata['data_id']] ? (

                                                <button className="music_small_btn"><img src="img/check-white.png"></img></button>
                                            ) : (
                                                <button className="music_small_btn"><img src="img/music_add.png"></img></button>
                                            )}

                                        </div>
                                    ) : (
                                        <div onClick={() => modeldata && WatchlistAdd(modeldata['data_id'], modeldata['data_type'])}>
                                            {modeldata && Object.keys(watchlistStatus).length === 0 && modeldata && modeldata['is_watchlist'] === "true" ? (
                                                <button className="music_small_btn"><img src="img/check-white.png" alt="" /></button>
                                            ) : (
                                                <button className="music_small_btn"><img src="img/music_add.png" alt="" /></button>
                                            )}
                                        </div>
                                    )}


                                    {favoriteStatus && modeldata && favoriteStatus[modeldata['data_id']] ? (
                                        <div onClick={() => FavoriteAdd(modeldata['data_id'], modeldata['data_type'])}>
                                            {favoriteStatus[modeldata['data_id']] ? (

                                                <button className="music_small_btn"><img src="img/fav.png"></img></button>
                                            ) : (
                                                <button className="music_small_btn"><img src="img/like stroke.png"></img></button>
                                            )}


                                        </div>
                                    ) : (
                                        <div onClick={() => modeldata && FavoriteAdd(modeldata['data_id'], modeldata['data_type'])}>
                                            {modeldata && Object.keys(favoriteStatus).length === 0 && modeldata && modeldata['is_watchlist'] === "true" ? (
                                                <button className="music_small_btn"><img src="img/fav.png" alt="" /></button>
                                            ) : (
                                                <button className="music_small_btn"><img src="img/like stroke.png" alt="" /></button>
                                            )}
                                        </div>
                                    )}


                                </div>
                                <div className="series_right_controller">
                                    <button className='music_small_btn'><img src="img/music_volum.png" className=" m-0 d-none"></img></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="series_full_description">
                        {
                            modeldata ? (
                                <div className="series_details_content">
                                    <div className="series_description">
                                        <div className="more_second_section mt-0">
                                            <h3> {modeldata ? modeldata['maturity_level'] : ''}</h3>
                                            <h4>{modeldata && modeldata['release_date'] ? (new Date(modeldata['release_date'])).getFullYear() : ""} &nbsp;|&nbsp; {modeldata && FormatTimeWithHourMinute(modeldata['duration'])}</h4>
 
                                            <h5 className='movie_price'>
                                                {!isUndefined(moviePrice) ? currencySymbol + moviePrice: ""}
                                            </h5>

                                        </div>
                                        <p>{modeldata ? modeldata['description'] : ''}</p>
                                    </div>
                                    <div className="series_categories">
                                        <h4>Cast & Crew : <span className="white_text">
                                            {modeldata && modeldata['celebs'] && Array.isArray(modeldata['celebs']) ? (modeldata['celebs'] as { title: string }[]).map((cast) => cast.title).join(', ') : ""}
                                        </span></h4>
                                        <h4>Genres : <span className="white_text">
                                            {modeldata && modeldata['genres'] && Array.isArray(modeldata['genres']) ? (modeldata['genres'] as { genre_title: string }[]).map((genre) => genre.genre_title).join(', ') : ""}
                                        </span></h4>
                                    </div>
                                </div>

                            ) : (
                                null
                            )
                        }
                        <div className="more_like_this">
                            {
                                CheckMoreLikeThisMovie === true ? (
                                    <div className="more_like_this_title">
                                        <h3>More Like This</h3>
                                    </div>
                                ) : (null)
                            }

                            <div className="row">
                                {
                                    CheckMoreLikeThisMovie === true ? (
                                        MorelikeThisLoading === false && Array.isArray(MoreLikeThisMoviedata) && MoreLikeThisMoviedata.length > 0 ? (
                                            MoreLikeThisMoviedata.map((item) => (

                                                <div className="col-lg-4 col-md-4 col-sm-6" key={item.title}>
                                                    <div className="more_like_box" >
                                                        <div className="more_like_box_img" onClick={() => handleShow(item.data_id)}>
                                                            <img src={item.media_banner}></img>
                                                        </div>
                                                        {
                                                            item.is_premium == true && item.is_paid == false ? 
                                                            <div className='premium_icon'>
                                                                <Link to={"#"}><img src='img/premium_icon.png' /></Link>
                                                            </div>: "" 
                                                        }
                                                        <div className="more_like_content_box">
                                                            <div className="more_like_left_info">
                                                                <h4>{item.title}</h4>
                                                                {watchlistStatus && watchlistStatus[item.data_id] ? (
                                                                    <li onClick={() => WatchlistAdd(item.data_id, item.data_type)}>
                                                                        {watchlistStatus[item.data_id] ? (
                                                                            <img src="img/check-white.png" alt="" />
                                                                        ) : (
                                                                            <img src="img/add-icon.png" alt="" />
                                                                        )}

                                                                    </li>
                                                                ) : (
                                                                    <li onClick={() => WatchlistAdd(item.data_id, item.data_type)}>
                                                                        {Object.keys(watchlistStatus).length === 0 && item.is_watchlist == "true" ? (
                                                                            <img src="img/check-white.png" alt="" />
                                                                        ) : (
                                                                            <img src="img/add-icon.png" alt="" />
                                                                        )}
                                                                    </li>
                                                                )}
                                                            </div>
                                                            <div className="more_second_section">
                                                                <h3> {item.maturity_level}</h3>
                                                                <h4>
                                                                    {item.release_date ? (new Date(item.release_date)).getFullYear() : ""}
                                                                </h4>
                                                            </div>
                                                            <div className="more_second_description">
                                                                <p>{item.description}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        ) : (

                                            Array.from({ length: 3 }).map((_, index) => (
                                                <div className='more_like_shimmer' key={index}></div>
                                            ))

                                        )

                                    ) : (null)
                                }

                            </div>
                            <div className="more_img_load">
                                <Link to={""}><img src="img/down_arrowpng.png" alt=""></img></Link>
                            </div>
                        </div>
                        <div className="about_movie">
                            <div className="more_like_this_title">
                                <h3>About {modeldata ? modeldata['title'] : ''}</h3>
                            </div>
                            <div className="about_movie_details">
                                <div className="series_categories">
                                    <h4>Cast & Crew : <span className="white_text"> {modeldata && modeldata['celebs'] && Array.isArray(modeldata['celebs']) ? (modeldata['celebs'] as { title: string }[]).map((cast) => cast.title).join(', ') : ""}</span></h4>
                                    {/* <h4>Crew : <span className="white_text">Andrzej Sapkowski, Andrzej Sapkowski, Lauren Schmidt, Matthew D'Ambrosio, Haily Hall, Declan De Barra</span></h4> */}
                                    <h4>Genres : <span className="white_text">{modeldata && modeldata['genres'] && Array.isArray(modeldata['genres']) ? (modeldata['genres'] as { genre_title: string }[]).map((genre) => genre.genre_title).join(', ') : ""}</span></h4>
                                    <h4>Maturity Rating: <span className="white_text white_text_border"> {modeldata ? modeldata['maturity_level'] : ''} </span></h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>

            </Modal>



            <Modal className='series_modal' show={showTVShow} onHide={handleCloseTvshow} backdrop="static" size="lg" keyboard={false} centered>
                <div className="modal_close" onClick={handleCloseTvshow}>
                    <Link to={""}><img src="img/modal_close.png" alt=""></img></Link>
                </div>

                <Modal.Body>
                    <div className="series_img">

                        {Tvshowmodeldata ? (
                            <img src={Tvshowmodeldata ? Tvshowmodeldata['media_banner'] : 'img/default-banner.png'} className="series_big_imf" alt=""></img>
                        ) : (
                            <div className='series_img_shimmer'></div>
                        )}
                        <div className="series_tool">
                            <div className="series_name">
                                <h2>{Tvshowmodeldata ? Tvshowmodeldata['title'] : ''}</h2>
                            </div>
                            <div className="series_controller">
                                <div className="series_left_controller">

                                    {Tvshowmodeldata && Object.keys(Tvshowmodeldata['premium']).length > 0  && Tvshowmodeldata['premium']['is_paid'] == false ?
                                    // <button className="music_play_btn" onClick={handleShowTwo}>Pay & Play</button>
                                    <button className="music_play_btn" onClick={() => handleShowTwo(Tvshowmodeldata['data_id'],'tvshow')}><img src="img/play_icon.jpg"></img>Pay & Play</button>
                                    :<button className="music_play_btn" onClick={() => FirstepisodeID && Tvshowmodeldata && PlayEpisode(FirstepisodeID, Tvshowmodeldata['data_id'])}><img src="img/play_icon.jpg"></img>Play</button>}

                                    {watchlistStatus && Tvshowmodeldata && watchlistStatus[Tvshowmodeldata['data_id']] ? (
                                        <div onClick={() => WatchlistAdd(Tvshowmodeldata['data_id'], Tvshowmodeldata['data_type'])}>
                                            {watchlistStatus[Tvshowmodeldata['data_id']] ? (

                                                <button className="music_small_btn"><img src="img/check-white.png"></img></button>
                                            ) : (
                                                <button className="music_small_btn"><img src="img/music_add.png"></img></button>
                                            )}

                                        </div>
                                    ) : (
                                        <div onClick={() => Tvshowmodeldata && WatchlistAdd(Tvshowmodeldata['data_id'], Tvshowmodeldata['data_type'])}>
                                            {Tvshowmodeldata && Object.keys(watchlistStatus).length === 0 && Tvshowmodeldata && Tvshowmodeldata['is_watchlist'] === "true" ? (
                                                <button className="music_small_btn"><img src="img/check-white.png" alt="" /></button>
                                            ) : (
                                                <button className="music_small_btn"><img src="img/music_add.png" alt="" /></button>
                                            )}
                                        </div>
                                    )}

                                    {favoriteStatus && Tvshowmodeldata && favoriteStatus[Tvshowmodeldata['data_id']] ? (
                                        <div onClick={() => FavoriteAdd(Tvshowmodeldata['data_id'], Tvshowmodeldata['data_type'])}>
                                            {favoriteStatus[Tvshowmodeldata['data_id']] ? (

                                                <button className="music_small_btn"><img src="img/fav.png"></img></button>
                                            ) : (
                                                <button className="music_small_btn"><img src="img/like stroke.png"></img></button>
                                            )}

                                        </div>
                                    ) : (
                                        <div onClick={() => Tvshowmodeldata && FavoriteAdd(Tvshowmodeldata['data_id'], Tvshowmodeldata['data_type'])}>
                                            {Tvshowmodeldata && Object.keys(favoriteStatus).length === 0 && Tvshowmodeldata && Tvshowmodeldata['is_watchlist'] === "true" ? (
                                                <button className="music_small_btn"><img src="img/fav.png" alt="" /></button>
                                            ) : (
                                                <button className="music_small_btn"><img src="img/like stroke.png" alt="" /></button>
                                            )}
                                        </div>
                                    )}




                                </div>
                                <div className="series_right_controller">
                                    <button className='music_small_btn'><img src="img/music_volum.png" className=" m-0 d-none"></img></button>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className="series_full_description">
                        

                        {
                            Tvshowmodeldata ? (
                                <div className="series_details_content">
                                    <div className="series_description">
                                        <div className="more_second_section mt-0">
                                            <h3> {Tvshowmodeldata ? Tvshowmodeldata['maturity_level'] : ''}</h3>
                                            <h4>{Tvshowmodeldata && Tvshowmodeldata['release_date'] ? (new Date(Tvshowmodeldata['release_date'])).getFullYear() : ""} </h4>
                                            <h5 className='movie_price'>
                                                {!isUndefined(tvShowPrice) ? currencySymbol + tvShowPrice: ""}
                                            </h5>
                                        </div>
                                        <p>{Tvshowmodeldata ? Tvshowmodeldata['description'] : ''}</p>
                                    </div>
                                    <div className="series_categories">
                                        <h4>Cast & Crew : <span className="white_text">
                                            {Tvshowmodeldata && Tvshowmodeldata['celebs'] && Array.isArray(Tvshowmodeldata['celebs']) ? (Tvshowmodeldata['celebs'] as { title: string }[]).map((cast) => cast.title).join(', ') : ""}
                                        </span></h4>
                                        <h4>Genres : <span className="white_text"> {Tvshowmodeldata && Tvshowmodeldata['genres'] && Array.isArray(Tvshowmodeldata['genres']) ? (Tvshowmodeldata['genres'] as { genre_title: string }[]).map((genre) => genre.genre_title).join(', ') : ""}</span></h4>
                                    </div>
                                </div>

                            ) : (
                                null
                            )
                        }
                        
                        
                        <div className="series_episode_details">
                            <div className="series_episode_header">
                                <h3>Episode</h3>
                                <div className='genres_section'>
                                    <h3>Seasons</h3>
                                    <div className="select_box">
                                        <Form.Select aria-label="Default select example" className='cust_seleted' onChange={handleSelectChange}>
                                            {
                                                Tvshowmodeldata && Tvshowmodeldata.season && Tvshowmodeldata.season.length > 0 ? (
                                                    Tvshowmodeldata.season.map((season: any, index: any) => (

                                                        <option key={season.season_id} value={season.season_id} > {season.title}</option>
                                                    ))
                                                ) : (
                                                    <p style={{ color: "red" }}>No Seasons available</p>
                                                )
                                            }

                                        </Form.Select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="series_episode_list">
                            {
                                EpisodeOrSeasonchCheck === true ? (
                                    EpisodeLoading === false && episode && episode.length > 0 ? (
                                        episode.map((episode: any, index: any) => (
                                            
                                            <div key={index} className="series_episode_list_box" 
                                            
                                                // onClick={() => Tvshowmodeldata && PlayEpisode(episode.data_id, Tvshowmodeldata['data_id'])}
                                                onClick={() => {
                                                    if (Tvshowmodeldata && Object.keys(Tvshowmodeldata['premium']).length > 0 && Tvshowmodeldata['premium']['is_paid'] == false) {
                                                      handleShowTwo(Tvshowmodeldata['data_id'], 'tvshow'); // Open details page
                                                    } else {
                                                        if(episode.data_id && Tvshowmodeldata ){
                                                            PlayEpisode(episode.data_id, Tvshowmodeldata['data_id']); // Play episode
                                                        }
                                                    }
                                                  }}
                                            
                                            >
                                                <div className="series_episode_list_box_details">
                                                    <h4>{episode.episode_number}</h4>
                                                    <div className="episode_details_img">
                                                        <img src={episode.media_banner} className="episode_img" alt={episode.title} />
                                                        <a href={episode.media_content}>
                                                            <img src="img/play_video.png" className="play_video_icon" alt="Play Video" />
                                                        </a>
                                                    </div>
                                                    <div className="series_episode_list_box_description">
                                                        <h3>{episode.title}</h3>
                                                        <p>{episode.description}</p>
                                                    </div>
                                                </div>
                                                <div className="series_episode_list_box_min">
                                                    <h2>{FormatTimeWithHourMinute(episode.duration)}</h2>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        Array.from({ length: 3 }).map((_, index) => (
                                            <div className='series_episode_list_box_shimmer' key={index}></div>
                                        ))
                                    )
                                ) : (
                                    <h5 className='white_text'>Episode Not Found.</h5>
                                )
                            }

                        </div>
                        <div className="more_like_this">
                            <div className="more_like_this_title">
                                <h3>More Like This</h3>
                            </div>
                            <div className="row">

                                {
                                    Array.isArray(MoreLikeThisTVShowdata) && MoreLikeThisTVShowdata.length > 0 ? (
                                        MoreLikeThisTVShowdata.map((item) => (
                                            <div className="col-lg-4 col-md-4 col-sm-6">
                                                <div className="more_like_box">
                                                    <div className="more_like_box_img" onClick={() => handleTvShow(item.data_id)}>
                                                        <img src={item.media_banner}></img>
                                                    </div>
                                                    {
                                                        item.is_premium == true && item.is_paid == false ? 
                                                        <div className='premium_icon'>
                                                            <Link to={"#"}><img src='img/premium_icon.png' /></Link>
                                                        </div>: "" 
                                                    }
                                                    <div className="more_like_content_box">
                                                        <div className="more_like_left_info">
                                                            <h4>{item.title}</h4>
                                                            {watchlistStatus && watchlistStatus[item.data_id] ? (
                                                                <li onClick={() => WatchlistAdd(item.data_id, item.data_type)}>
                                                                    {watchlistStatus[item.data_id] ? (
                                                                        <img src="img/check-white.png" alt="" />
                                                                    ) : (
                                                                        <img src="img/add-icon.png" alt="" />
                                                                    )}

                                                                </li>
                                                            ) : (
                                                                <li onClick={() => WatchlistAdd(item.data_id, item.data_type)}>
                                                                    {Object.keys(watchlistStatus).length === 0 && item.is_watchlist == "true" ? (
                                                                        <img src="img/check-white.png" alt="" />
                                                                    ) : (
                                                                        <img src="img/add-icon.png" alt="" />
                                                                    )}
                                                                </li>
                                                            )}
                                                        </div>
                                                        <div className="more_second_section">
                                                            <h3> {item.maturity_level}</h3>
                                                            <h4>
                                                                {item.release_date ? (new Date(item.release_date)).getFullYear() : ""}
                                                            </h4>
                                                        </div>
                                                        <div className="more_second_description">
                                                            <p>{item.description}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <div className="no-movies-found-message">
                                        </div>
                                    )
                                }
                            </div>
                            <div className="more_img_load">
                                <Link to={""}><img src="img/down_arrowpng.png" alt=""></img></Link>
                            </div>
                        </div>
                        <div className="about_movie">
                            <div className="more_like_this_title">
                                <h3>About {Tvshowmodeldata ? Tvshowmodeldata['title'] : ''}</h3>
                            </div>
                            <div className="about_movie_details">
                                <div className="series_categories">
                                    <h4>Cast & Crew : <span className="white_text">{Tvshowmodeldata && Tvshowmodeldata['celebs'] && Array.isArray(Tvshowmodeldata['celebs']) ? (Tvshowmodeldata['celebs'] as { title: string }[]).map((cast) => cast.title).join(', ') : ""}</span></h4>
                                    <h4>Genres : <span className="white_text">{Tvshowmodeldata && Tvshowmodeldata['genres'] && Array.isArray(Tvshowmodeldata['genres']) ? (Tvshowmodeldata['genres'] as { genre_title: string }[]).map((genre) => genre.genre_title).join(', ') : ""}</span></h4>
                                    <h4>Maturity Rating: <span className="white_text white_text_border"> {Tvshowmodeldata ? Tvshowmodeldata['maturity_level'] : ''}</span></h4>
                                </div>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
            <Modal className='login_modal' show={showModal} onHide={handleClosee} centered backdrop="static">
                <Modal.Body>
                    <div className='login_modal_img'>
                        <img src='/img/login.svg'></img>
                    </div>
                    <div className='login_modal_text text-center'>
                        <h3>Login Now</h3>
                        <h4>You have to login to use this feature</h4>
                    </div>
                    <div className='login_modal_btn'>
                        <Link to={routes.signin}>Login</Link>
                        <Link onClick={handleClosee} to={""}>Continue</Link>
                    </div>
                </Modal.Body>
            </Modal>


            <Modal className='movie_name_modal' show={showTwo} onHide={handleCloseTwo}  centered backdrop="static" keyboard={false}>
                
                <Modal.Body>
                    <div className='movie_name_close_modal' onClick={movie_name_modal_close}>
                        <Link to={"#"}><img src='img/modal_close.png'/></Link>
                    </div>
                    <div className='movie_name_details'>
                        <div className='movie_name_img'>
                            <img src='img/tv.svg'/>
                        </div>
                        <div className='movine_name_section'>
                            <h3>{itemTitle}</h3>
                            <h4>{currencySymbol}{itemPrice}</h4>
                            <button onClick={handleShowThree} >Continue to Pay</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal className='movie_name_modal' show={showThree} onHide={handleCloseThree}  centered backdrop="static" keyboard={false}>
                <Modal.Body>
                    {paymentLoder == true ? (
                    <div className='loader'>
                            <img src='img/loader.gif' alt="Loading..." />
                    </div>
                    ) : (
                        null
                    )}
                        <>
                            <div className='movie_name_close_modal' onClick={handleCloseThree}>
                                <Link to="#">
                                    <img src='img/modal_close.png' alt="Close Modal" />
                                </Link>
                            </div>
                            <div className='stripe_details'>
                                <div className='stripe_header'>
                                    <h3>Payment for - <span>{itemTitle}</span></h3>
                                    <h3>Total Amount - <span>{currencySymbol}{itemPrice}</span></h3>
                                </div>
                                {itemDataType && itemVideoId ? (
                                    <Elements stripe={stripePromise}>
                                        <ItemPurchase 
                                            data_id={itemVideoId} 
                                            data_type={itemDataType}  
                                            updatePaymentStatus={updateVideoPaymentStatus} 
                                            errorInVideoPayment={errorInVideoPayment} 
                                            loderShow={loderShow} 
                                            loderHide={loderHide}
                                        />
                                    </Elements>
                                ) : ""}
                            </div>
                        </>
                </Modal.Body>
            </Modal>            
        </>
    )
}
