import React, { useEffect, useState } from 'react'
import Header from './Layout/Header'
import Footer from './Layout/Footer'
import { Tab, Tabs } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Route } from 'react-router-dom';
import { FeaturesData, FormatTimeWithHourMinute, Session, User, UserLocationData, usePlayVideo } from './Helper';
import { API_URL_BASE, STRIPE_TEST_KEY, X_APP_SECRET, headers } from './utils'
import { API_URLS } from './utils'
import { WatchlistStatusInterface, FavoriteStatusInterface } from './Model/Interface';
import { UserInfo, Moviedatatype, TvShowdataTypes, Tvshowdatatype } from './Model/Interface';
import Form from 'react-bootstrap/Form';
import { type } from 'os'
import { useContext } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { CarouselItem, Container } from 'react-bootstrap';
import OwlCarousel from 'react-owl-carousel2';
import routes from './Routes';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { Elements } from '@stripe/react-stripe-js';
import ItemPurchase from './ItemPurchase';
import { loadStripe } from '@stripe/stripe-js';
import { isUndefined } from 'lodash';

export default function SearchData() {

    var session = Session();
    var user = User();
    const navigate = useNavigate();
    const [tabKey, initTabKey] = useState<string>('one')
    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(false);
    const [tvmodelshow, setTvmodelshow] = useState(false);
    const [searchDataCheck, setsearchDataCheck] = useState(false);
    const [searchDataCheckTvShow, setsearchDataCheckTvShow] = useState(false);
    const FeaturesList = FeaturesData();
    const UserLocation = UserLocationData();

    const stripePromise = loadStripe(STRIPE_TEST_KEY);
    const [showTwo, setShowTwo] = useState(false);
    const [moviePrice, setmoviePrice] = useState("");
    const [currencySymbol, setcurrencySymbol] = useState("");
    const [tvShowPrice, settvShowPrice] = useState("");
    const [itemVideoId, setitemVideoId] = useState("");
    const [itemDataType, setitemDataType] = useState("");
    const [itemPrice, setitemPrice] = useState("");
    const [itemTitle, setitemTitle] = useState("");
    const [showThree, setShowThree] = useState(false);
    const [paymentLoder, setpaymentLoder] = useState(false);
    const payPerView        =  FeaturesList?.data.pay_per_view;
    const userSubscription  =  user?.data.user_subscription;

    
    const handleClose = () => {
        setCheckMoreLikeThisMovie(true);
        setShow(false);
    }
    const tvshowhandleClose = () => {
        setTvshowid("")
        setSeasonId("")
        setTvmodelshow(false)
        setEpisodeOrSeasonchCheck(true)
    }
    const [searchmoviedata, setSearchmoviedata] = useState<Moviedatatype[] | any[] | undefined>([]);
    const [searchtvshowdata, setSearchtvshowdata] = useState<Tvshowdatatype[] | any[] | undefined>([]);
    const [EpisodeOrSeasonchCheck, setEpisodeOrSeasonchCheck] = useState(true);

    const [showModal, setshowModal] = useState(false);
    const handleClosee = () => setshowModal(false);
    const loginModal = () => setshowModal(true);
    const [errormovie, setErrormovie] = useState("");
    const [errortvshow, setErrortvshow] = useState("");
    const [search, setSearch] = useState<string>("");
    
    const token = user?.data.token
    const user_id = user?.data.user_id
    const [tvshowid, setTvshowid] = useState<any>();
    const [Watchlistdata, setWatchlistdata] = useState<Moviedatatype[] | any[] | undefined>([]);
    const [Favouritedata, setFavouritedata] = useState<Moviedatatype[] | any[] | undefined>([]);
    const [moviemodeldata, setMovieModeldata] = useState<Moviedatatype>();
    const [tvshowmodeldata, setTvshowModeldata] = useState<Moviedatatype>();
    const [MoreLikeThisMoviedata, setMoreLikeThisMoviedata] = useState<Moviedatatype[] | undefined>([]);
    const [MoreLikeThisTVShowdata, setMoreLikeThisTVShowdata] = useState<TvShowdataTypes>()
    const [key, setKey] = useState(0);
    const [episode, setEpisode] = useState<any>();
    const [SeasonId, setSeasonId] = useState("");
    const [FirstepisodeID, setFirstepisodeID] = useState<number>();
    const [TopSearchchCheck, setTopSearchchCheck] = useState(false);
    const [CheckMoreLikeThisMovie, setCheckMoreLikeThisMovie] = useState(true);
    const [TopSearchMovie, setTopSearchMovie] = useState<Moviedatatype[] | any[]>([]);
    const [showTVShow, setTVShow] = useState(false);
    const [MorelikeThisLoading, setMorelikeThisLoading] = useState(true);
    const [EpisodeLoading, setEpisodeLoading] = useState(true);

    const viewAllTopSearchMovie = TopSearchMovie.length >= 10;

    const PlayEpisode = (data_id: number, TVShow_id : number | null = null,watching_time: string = "") => {
        
        const data_type: string = "episode";
        navigate(routes.VideoWatch, { state: { data_id,data_type,watching_time,TVShow_id } });
    };

    const PlayVideo = (data_id: number, watching_time: string = "") => {
        const data_type: string = "movie";
        navigate(routes.VideoWatch, { state: { data_id,data_type,watching_time } });
    };

    const handleShowTwo = (data_id:any,data_type:string) =>{

        if (!token) {
            if (data_type == "movie") {
                handleClose()
            } else {
                tvshowhandleClose()
            }
            setshowModal(true)
            return;
        }

        setShowTwo(true);
        setitemVideoId(data_id)
        setitemDataType(data_type)
    }

    
    const handleCloseTwo = () => {
        setShowTwo(false);
    }
    let movie_name_modal_close = () =>{
        setShowTwo(false)
        
        if(itemDataType == 'movie'){
            setShow(true)
        }else{
            setTvmodelshow(true)
        }
    }

    const handleShowThree = () => {
        setShowThree(true);
        setShowTwo(false);
        setShow(false)
        setTvmodelshow(false)

    }

    const handleCloseThree = () =>{
        setpaymentLoder(false);
        setShowThree(false)
    }

    const updateVideoPaymentStatus = () => {

        handleCloseThree()
        
        if(itemDataType == 'movie'){
            if (moviemodeldata && typeof moviemodeldata === 'object' && 'premium' in moviemodeldata) {
                
                setMovieModeldata({
                    ...moviemodeldata,
                    premium: {
                    ...moviemodeldata.premium,
                    is_paid: true,
                    },
                });

                movie_name_modal_close();
                // PlayVideo(Number(itemVideoId))
            } else {
                
                console.error('Invalid modeldata structure');
            }
            
        }else{

            if (tvshowmodeldata && typeof tvshowmodeldata === 'object' && 'premium' in tvshowmodeldata && FirstepisodeID) {
                
                setTvshowModeldata({
                    ...tvshowmodeldata,
                    premium: {
                    ...tvshowmodeldata.premium,
                    is_paid: true,
                    },
                });
                
                movie_name_modal_close();
                // PlayEpisode(FirstepisodeID,Number(itemVideoId))
            }
            
        }
    };


    const errorInVideoPayment = () => {

        handleCloseThree()
        if(itemDataType == 'movie'){
            movie_name_modal_close();
        }else{
            movie_name_modal_close();
        }
    };

    const loderShow = () => {
        setpaymentLoder(true);
    };

    const loderHide = () => {
        
        // setShowThree(false)
        // setpaymentLoder(false);
    };

    


    function showSuccessNotification(message: any) {
        toast.success(message, {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    }

    

    function showErrorNotification(message: any) {
        toast.error(message, {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    }




    useEffect(() => {
        setLoading(true);

        const requestData = {
            profile_id: session?.profile_id, // Use profileId here
            paginate: false,
            limit: -1,
            country_code: UserLocation.country_code
        };

        const requestBody = JSON.stringify(requestData);
        fetch(`${API_URL_BASE}${API_URLS.TOP_SEARCH_LIST}`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-type": "application/json",
                'X-APP-SECRET': X_APP_SECRET,
                Authorization: "Bearer " + token,
            },
            body: requestBody,
        }).then((result) => {

            result.json().then((resp) => {
                if (resp.response == true) {
                    setTopSearchMovie(resp.data);
                    setTopSearchchCheck(true)
                }
            })
        })


    }, []);

    useEffect(() => {
        let timeout = setTimeout(() => {
            searchData();
            getdata();
        }, 750)

        return () => clearTimeout(timeout);

    }, [search]);

    useEffect(() =>{

        searchData()

    },[tabKey])
    
    function searchData() {
        setSearchmoviedata(undefined)
        setSearchtvshowdata(undefined);
        if(tabKey == "one"){
            
            const requestData = {
                profile_id: session?.profile_id, // Use profileId here
                search_text: search,
                data_type: "movie",
                country_code: UserLocation.country_code
            };
    
    
            const requestBody = JSON.stringify(requestData);
    
            fetch(`${API_URL_BASE}${API_URLS.SEARCHDATA}`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-type": "application/json",
                    'X-APP-SECRET': X_APP_SECRET,
                    Authorization: "Bearer " + token,
                },
                body: requestBody,
            })
            .then((result) => {
                if (!result.ok) {
    
                    throw new Error("API request failed");
                }
                return result.json();
            })
            .then((resp) => {
                console.warn(resp)
                if (resp.response === true) {
                    
                    setErrormovie("")
                    setSearchmoviedata(resp.data)
                    setsearchDataCheck(true)
                    setTopSearchchCheck(false);
                } else {
                
                    alert("API request succeeded, but the response indicates an error");
                }
            })
            .catch((error) => {
                
                if (search.length == 0) {
                    setErrormovie("Movies not found !")
                    
                    setsearchDataCheck(false)
                } else {

                    setsearchDataCheck(false)
                    setTopSearchchCheck(false);
                    setErrormovie("Movies not found !")
                }
    
                console.error("API request error:", error);
            });
        }else{
            
            const Data = {
                profile_id: session?.profile_id, // Use profileId here
                search_text: search,
                data_type: "tvshow",
                country_code: UserLocation.country_code
            };
    
    
            const Body = JSON.stringify(Data);
    
            fetch(`${API_URL_BASE}${API_URLS.SEARCHDATA}`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-type": "application/json",
                    'X-APP-SECRET': X_APP_SECRET,
                    Authorization: "Bearer " + token,
                },
                body: Body,
            })
            .then((result) => {
                if (!result.ok) {
    
                    throw new Error("API request failed");
                }
                return result.json();
            })
            .then((resp) => {
                if (resp.response === true) {
                    setErrortvshow("")
                    setsearchDataCheckTvShow(true)
                    setSearchtvshowdata(resp.data)
                    setTopSearchchCheck(false);
                    
                } else {
                    alert("API request succeeded, but the response indicates an error");
                }
            })
            .catch((error) => {
                if (search.length == 0) {
                    setErrortvshow("TV Shows not found !")
                    setsearchDataCheckTvShow(false)
                } else {
                    setTopSearchchCheck(false);
                    setsearchDataCheckTvShow(false)
                    setErrortvshow("TV Shows not found !")
                }
    
                console.error("API request error:", error);
            });

        }

        if (search.length === 1 || search.length === 2) {
            
            setSearchmoviedata(undefined)
            setSearchtvshowdata(undefined);
        }

    }
    function getdata() {
        setLoading(true);
        const requestData = {
            profile_id: session?.profile_id,
            user_id: user_id,
            country_code: UserLocation.country_code
        };
        const requestBody = JSON.stringify(requestData);

        fetch(`${API_URL_BASE}${API_URLS.USER_ITEM_WATCHLIST}`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-type": "application/json",
                'X-APP-SECRET': X_APP_SECRET,
                Authorization: "Bearer " + token,
            },
            body: requestBody,
        })
            .then((result) => {
                if (!result.ok) {
                    // Handle non-successful responses here
                    throw new Error("API request failed");
                }
                return result.json();
            })
            .then((resp) => {
                if (resp.response === true) {

                    setWatchlistdata(resp.data);
                } else {
                    alert("API request succeeded, but the response indicates an error");
                }
            })
            .catch((error) => {
                console.error("API request error:", error);
            });


        fetch(`${API_URL_BASE}${API_URLS.USER_FAVOURITE_LIST}`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-type": "application/json",
                'X-APP-SECRET': X_APP_SECRET,
                Authorization: "Bearer " + token,
            },
            body: requestBody,
        })
            .then((result) => {
                if (!result.ok) {
                    // Handle non-successful responses here
                    throw new Error("API request failed");
                }
                return result.json();
            })
            .then((resp) => {

                if (resp.response === true) {

                    setFavouritedata(resp.data);
                } else {
                    alert("API request succeeded, but the response indicates an error");
                }
            })
            .catch((error) => {
                setFavouritedata(undefined);
                console.error("API request error:", error);
            });

        setLoading(false);
    }

    const [watchlistStatus, setWatchlistStatus] = useState<WatchlistStatusInterface>([]); 
    const [WatchlistStatusInterface, setNewWatchlistStatus] = useState<WatchlistStatusInterface>([]);

    
    const WatchlistAdd = (data_id: number, data_type: string) => {
        if(!token){
            if(data_type == "movie"){
                handleClose()
            }else{
                tvshowhandleClose()
            }
            setshowModal(true)
            return; 
        }
        const action = watchlistStatus[data_id] ? 0 : 1; // Toggle action
        
        const requestData = {
            profile_id: session?.profile_id, // Use profileId here
            data_type: data_type == "movie" ? "movie" : "tvshow",
            data_id: data_id,
            action: action,
            country_code: UserLocation.country_code
        };
        const requestBody = JSON.stringify(requestData);

        fetch(`${API_URL_BASE}${API_URLS.WATCHLIST_ADD_REMOVE}`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-type": "application/json",
                'X-APP-SECRET': X_APP_SECRET,
                Authorization: "Bearer " + token,
            },
            body: requestBody,
        })
            .then((result) => result.json())
            .then((resp) => {
                if (resp.response === true) {

                    setKey((prevKey) => prevKey + 1);

                    setWatchlistStatus((prevStatus: any) => ({
                        ...prevStatus,
                        [data_id]: action === 1, // If action is 1, the item is not in the watchlist, otherwise it's in the watchlist
                    }));
                }
            })
            .catch((error) => {

                console.error("Error while updating watchlist:", error);
            });

    };

    useEffect(() => {

        const filteredData = Watchlistdata && Watchlistdata.filter((item: any) => item.is_watchlist === "true");
        const watchlistStatusDATA: any = [];
        filteredData && filteredData.forEach((item: any) => {
            watchlistStatusDATA[item.data_id] = true;
        });
        setWatchlistStatus(watchlistStatusDATA);

    }, [Watchlistdata]);


    useEffect(() => {

        const initialWatchlistStatus: any = {};
        Watchlistdata && Watchlistdata.forEach((item: any) => {
            initialWatchlistStatus[item.data_id] = item.is_watchlist === "true";
        });
        setNewWatchlistStatus(initialWatchlistStatus);


    }, [key]);

    const [favoriteStatus, setFavoriteStatus] = useState<FavoriteStatusInterface>([]);
    const [NewFavoriteStatus, setNewFavotiteStatus] = useState<FavoriteStatusInterface>([]);

    const FavoriteAdd = (data_id: number, data_type: string) => {

        if(!token){
            if(data_type == "movie"){
                handleClose()
            }else{
                tvshowhandleClose()
            }
            setshowModal(true)
            return; 
        }
        
        const action = favoriteStatus[data_id] ? 0 : 1; // Toggle action
        const requestData = {
            profile_id: session?.profile_id, // Use profileId here
            data_type: data_type == "movie" ? "movie" : "tvshow",
            data_id: data_id,
            action: action,
            country_code: UserLocation.country_code
        };
        const requestBody = JSON.stringify(requestData);

        fetch(`${API_URL_BASE}${API_URLS.FAVORITE_ADD_REMOVE}`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-type": "application/json",
                'X-APP-SECRET': X_APP_SECRET,
                Authorization: "Bearer " + token,
            },
            body: requestBody,
        })
            .then((result) => result.json())
            .then((resp) => {
                if (resp.response === true) {

                    setKey((prevKey) => prevKey + 1);

                    setFavoriteStatus((prevStatus: any) => ({
                        ...prevStatus,
                        [data_id]: action === 1, // If action is 1, the item is not in the watchlist, otherwise it's in the watchlist
                    }));
                }
            })
            .catch((error) => {
                console.error("Error while updating watchlist:", error);
            });

    };

    useEffect(() => {

        const filteredData = Favouritedata && Favouritedata.filter((item: any) => item.is_favourite === "true");
        const FavoriteStatusDATA: any = [];
        filteredData && filteredData.forEach((item: any) => {
            FavoriteStatusDATA[item.data_id] = true;
        });
        setFavoriteStatus(FavoriteStatusDATA);

    }, [Favouritedata]);


    useEffect(() => {

        const initialWatchlistStatus: any = {};
        Favouritedata && Favouritedata.forEach((item) => {
            initialWatchlistStatus[item.data_id] = item.is_favourite === "true";
        });
        setNewFavotiteStatus(initialWatchlistStatus);


    }, [key]);


    const handleShow = (data_id: number, data_type: string) => {

        const requestData = {
            profile_id: session?.profile_id, // Use profileId here
            data_id: data_id,
            data_type: data_type,
            country_code: UserLocation.country_code
        };

        const requestBody = JSON.stringify(requestData);

        fetch(`${API_URL_BASE}${API_URLS.TOP_SEARCH_ADD}`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-type": "application/json",
                'X-APP-SECRET': X_APP_SECRET,
                Authorization: "Bearer " + token,
            },
            body: requestBody,
        })
            .then((result) => {
                if (!result.ok) {

                    throw new Error("API request failed");
                }
                return result.json();
            })
            .then((resp) => {
                if (resp.response === true) {
                    console.warn("Added In Top Search")
                } else {
                    alert("API request succeeded, but the response indicates an error");
                }
            })
            .catch((error) => {
                console.error("API request error:", error);
            });

        if (data_type === "movie") {
            setShow(true);
            setMovieModeldata(undefined);
            setMoreLikeThisMoviedata(undefined)
            const requestData = {
                profile_id: session?.profile_id, // Use profileId here
                movie_id: data_id,
                country_code: UserLocation.country_code
            };
            const requestBody = JSON.stringify(requestData);

            fetch(`${API_URL_BASE}${API_URLS.MOVIE_DETIALS}`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-type": "application/json",
                    'X-APP-SECRET': X_APP_SECRET,
                    Authorization: "Bearer " + token,
                },
                body: requestBody,
            }).then((result) => {

                result.json().then((resp) => {
                   
                    if (resp.response == true) {
                        setMovieModeldata(resp.data);
                        setitemTitle(resp.data.title)
                        if(payPerView ==  true){
    
                            setcurrencySymbol(resp.data.premium.currency_symbol)
                            if(userSubscription == true){   
    
                                setmoviePrice(resp.data.premium.subscriber_price)
                                setitemPrice(resp.data.premium.subscriber_price)
    
                            }else{
                                setmoviePrice(resp.data.premium.nonsubscriber_price)
                                setitemPrice(resp.data.premium.nonsubscriber_price)
                            }
                        }
                        
                    }
                })
            })

            fetch(`${API_URL_BASE}${API_URLS.MORE_LIKE_THIS_MOVIES}`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-type": "application/json",
                    'X-APP-SECRET': X_APP_SECRET,
                    Authorization: "Bearer " + token,
                },
                body: requestBody,

            }).then((result) => {

                result.json().then((resp) => {

                    if (resp.response == true) {
                        setMoreLikeThisMoviedata(resp.data)
                        setMorelikeThisLoading(false)
                    } else {
                        setCheckMoreLikeThisMovie(false)
                    }
                })
            })
        } else {

            setTvmodelshow(true);
            setTvshowModeldata(undefined);
            setEpisode(undefined);
            setMoreLikeThisTVShowdata(undefined);
            const requestData = {
                profile_id: session?.profile_id, // Use profileId here
                tvshow_id: data_id,
                country_code: UserLocation.country_code
            };
            const requestBody = JSON.stringify(requestData);

            fetch(`${API_URL_BASE}${API_URLS.TVSHOW_DETAILS}`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-type": "application/json",
                    'X-APP-SECRET': X_APP_SECRET,
                    Authorization: "Bearer " + token,
                },
                body: requestBody,
            }).then((result) => {

                result.json().then((resp) => {

                    if (resp.response == true) {
                        
                        setitemTitle(resp.data.title)

                        if(payPerView ==  true){
                            setcurrencySymbol(resp.data.premium.currency_symbol)
                            if(userSubscription == true){   
    
                                settvShowPrice(resp.data.premium.subscriber_price)
                                setitemPrice(resp.data.premium.subscriber_price)
    
                            }else{
                                settvShowPrice(resp.data.premium.nonsubscriber_price)
                                setitemPrice(resp.data.premium.nonsubscriber_price)
                            }
                        }
                        setTvshowModeldata(resp.data);
                        setTvshowid(data_id);
    
                        if (resp.data['season'].length > 0) {
                            setSeasonId(resp.data['season'][0]['season_id']);
                        } else {
                            setEpisodeOrSeasonchCheck(false)
                        }
                    }
                })
            })

            fetch(`${API_URL_BASE}${API_URLS.TVSHOW_MORE_LIKE_THIS}`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-type": "application/json",
                    'X-APP-SECRET': X_APP_SECRET,
                    Authorization: "Bearer " + token,
                },
                body: requestBody,

            }).then((result) => {

                result.json().then((resp) => {

                    if (resp.response == true) {
                        setMoreLikeThisTVShowdata(resp.data.tvshows)
                    } else {
                        console.warn("Responce False Please Tru Again")
                    }
                })
            })
        }



    }
    

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setEpisode(undefined);
        setEpisodeLoading(true)
        setEpisodeOrSeasonchCheck(true)
        const SeasonsId = event.target.value;

        const Episoderequest = {
            profile_id: session?.profile_id, // Use profileId here
            tvshow_id: tvshowid,
            season_id: SeasonsId,
            paginate: false,
            limit: -1,
            order_by: ["created_at", "asc"],
            country_code: UserLocation.country_code
        };
        const EpisoderequestBody = JSON.stringify(Episoderequest);

        fetch(`${API_URL_BASE}${API_URLS.TVSHOW_SEASON_EPISODE}`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-type": "application/json",
                'X-APP-SECRET': X_APP_SECRET,
                Authorization: "Bearer " + token,
            },
            body: EpisoderequestBody,
        }).then((result) => {

            result.json().then((resp) => {

                if (resp.response == true) {

                    setEpisode(resp.data);
                    setEpisodeLoading(false)
                } else {
                    setEpisodeOrSeasonchCheck(false)
                }
            })
        })

    };

    const handleTvShow = (tvshow_id: number) => {
        setTvshowModeldata(undefined)
        setTvmodelshow(true);
        setEpisodeLoading(true)
        setMoreLikeThisTVShowdata(undefined);
        const requestData = {
            profile_id: session?.profile_id, // Use profileId here
            tvshow_id: tvshow_id,
            country_code: UserLocation.country_code
        };
        const requestBody = JSON.stringify(requestData);


        fetch(`${API_URL_BASE}${API_URLS.TVSHOW_DETAILS}`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-type": "application/json",
                'X-APP-SECRET': X_APP_SECRET,
                Authorization: "Bearer " + token,
            },
            body: requestBody,
        }).then((result) => {

            result.json().then((resp) => {

                if (resp.response == true) {
                        
                    setitemTitle(resp.data.title)

                    if(payPerView ==  true){
                        setcurrencySymbol(resp.data.premium.currency_symbol)
                        if(userSubscription == true){   

                            settvShowPrice(resp.data.premium.subscriber_price)
                            setitemPrice(resp.data.premium.subscriber_price)

                        }else{
                            settvShowPrice(resp.data.premium.nonsubscriber_price)
                            setitemPrice(resp.data.premium.nonsubscriber_price)
                        }
                    }
                    setTvshowModeldata(resp.data);
                    setTvshowid(tvshow_id);

                    if (resp.data['season'].length > 0) {
                        setSeasonId(resp.data['season'][0]['season_id']);
                    } else {
                        setEpisodeOrSeasonchCheck(false)
                    }
                }
            })
        })

        fetch(`${API_URL_BASE}${API_URLS.TVSHOW_MORE_LIKE_THIS}`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-type": "application/json",
                'X-APP-SECRET': X_APP_SECRET,
                Authorization: "Bearer " + token,
            },
            body: requestBody,

        }).then((result) => {

            result.json().then((resp) => {

                if (resp.response == true) {
                    setMoreLikeThisTVShowdata(resp.data.tvshows)
                } else {
                    console.warn("Responce False Please Tru Again")
                }
            })
        })
    }

    useEffect(() => {
        setEpisode(undefined);
        if (tvshowid && SeasonId) {


            const Episoderequest = {
                profile_id: session?.profile_id, // Use profileId here
                tvshow_id: tvshowid,
                season_id: SeasonId,
                paginate: false,
                limit: -1,
                order_by: ["created_at", "asc"],
                country_code: UserLocation.country_code
            };

            const EpisoderequestBody = JSON.stringify(Episoderequest);

            fetch(`${API_URL_BASE}${API_URLS.TVSHOW_SEASON_EPISODE}`, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-type": "application/json",
                    'X-APP-SECRET': X_APP_SECRET,
                    Authorization: "Bearer " + token,
                },
                body: EpisoderequestBody,
            }).then((result) => {

                result.json().then((resp) => {

                    if (resp.response == true) {
                        setEpisode(resp.data);
                        setEpisodeLoading(false)
                        setFirstepisodeID(resp.data[0]['data_id'])
                    } else {
                        setEpisodeOrSeasonchCheck(false)
                    }
                })
            })
        }

    }, [tvshowid, SeasonId]);
    const options = {
        nav: true,
        items: 9,
        rewind: true,
        autoplay: true,
        dots: false,
        stagePadding: 40,
        navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"],
        responsive: {
            325: {
                items: 2,
            },
            425: {
                items: 2,
            },
            575: {
                items: 3,
            },
            767: {
                items: 4,
            },
            991: {
                items: 5,
            },
            1199: {
                items: 5,
            },
            1024: {
                items: 7,
            },
            1920: {
                items: 9,
            },


        },
    };

    return (
        <div>
            <Header />
            <ToastContainer />
         
          
            <Container fluid className='cust_container'>
                <>
                    <div className="watchlist search search_data">
                        <div className="search_box">
                            <input type='search' value={search} className='w-100 search' onChange={(event) => setSearch(event.target.value)} placeholder='Search'></input>
                            <img src='/img/search.png'></img>
                        </div>
                        <Tabs activeKey={tabKey} onSelect={(e) => { initTabKey(e as string) }} className='nav-pills search_tab'>
                            <Tab eventKey="one" title={<span>Movies</span>}>
                                <div className="view_all_section">
                                    <h4 className='result_text'>{searchDataCheck && errormovie === ""  ? `Result for "${search}"` : ''}</h4>
                                   
                                    <div className="slick-slider episode_details watchlist_slider">
                                        {
                                            !loading ?
                                                Array.isArray(searchmoviedata) && searchmoviedata.map((item) => (
                                                    <div className='item_box watchlist_box' key={item.data_id}>
                                                        <div onClick={() => handleShow(item.data_id, item.data_type)}>
                                                            <img className="img" src={item.media_poster} />
                                                        </div>
                                                        <div className="item_img_details">
                                                            <div className="item_img_header">
                                                                <h5>{item.title}</h5>
                                                                <ul>
                                                                    {item.is_paid == true ? <li onClick={() => PlayVideo(item.data_id)}><img src="img/play-icon.png" alt="" /></li>:<li onClick={() => handleShow(item.data_id,item.data_type)}><img src="img/play-icon.png" alt="" /></li>}

                                                                    {watchlistStatus && watchlistStatus[item.data_id] ? (
                                                                        <li onClick={() => WatchlistAdd(item.data_id, item.data_type)}>
                                                                            {watchlistStatus[item.data_id] ? (
                                                                                <img src="img/check-white.png" alt="" />
                                                                            ) : (
                                                                                <img src="img/add-icon.png" alt="" />
                                                                            )}

                                                                        </li>
                                                                    ) : (
                                                                        <li onClick={() => WatchlistAdd(item.data_id, item.data_type)}>
                                                                            { token && Object.keys(watchlistStatus).length === 0 && item.is_watchlist == "true" ? (
                                                                                <img src="img/check-white.png" alt="" />
                                                                            ) : (
                                                                                <img src="img/add-icon.png" alt="" />
                                                                            )}
                                                                        </li>
                                                                    )}
                                                                    <li onClick={() => FavoriteAdd(item.data_id, item.data_type)}>
                                                                        {favoriteStatus[item.data_id] ? (
                                                                            <img src="img/fav.png" alt="" />
                                                                        ) : (
                                                                            <img src="img/like stroke.png" alt="" />
                                                                        )}

                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <div className="item_img_number">
                                                                <h3>{item.maturity_level}</h3>
                                                                <h4>{item.release_date ? (new Date(item.release_date)).getFullYear() : ""} </h4>
                                                            </div>
                                                        </div>
                                                        {
                                                            item.is_premium == true && item.is_paid == false ? 
                                                             <div className='premium_icon'>
                                                                <Link to={"#"}><img src='img/premium_icon.png' /></Link>
                                                            </div> : ""
                                                        }
                                                    </div>
                                                ))
                                                : null
                                        }
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="two" title={<span>TV Shows</span>}>

                                <div className="view_all_section">
                                    <h4 className='result_text'>{searchtvshowdata ? `Result for "${search}"` : ''} </h4>
                                   
                                    <div className="slick-slider episode_details watchlist_slider">
                                        {
                                            !loading ?
                                                Array.isArray(searchtvshowdata) && searchtvshowdata.map((item) => (
                                                    <div className='item_box watchlist_box' key={item.title}>
                                                        <div onClick={() => handleShow(item.data_id, item.data_type)}>
                                                            <img className="img" src={item.media_poster} />
                                                        </div>
                                                        <div className="item_img_details">
                                                            <div className="item_img_header">
                                                                <h5>{item.title}</h5>
                                                                <ul>
                                                                    <li onClick={() => handleTvShow(item.data_id)}><img src="/img/play-icon.png" alt="" /></li>
                                                                    {watchlistStatus && watchlistStatus[item.data_id] ? (
                                                                        <li onClick={() => WatchlistAdd(item.data_id, item.data_type)}>
                                                                            {watchlistStatus[item.data_id] ? (
                                                                                <img src="img/check-white.png" alt="" />
                                                                            ) : (
                                                                                <img src="img/add-icon.png" alt="" />
                                                                            )}

                                                                        </li>
                                                                    ) : (
                                                                        <li onClick={() => WatchlistAdd(item.data_id, item.data_type)}>
                                                                            { token && Object.keys(watchlistStatus).length === 0 && item.is_watchlist == "true" ? (
                                                                                <img src="img/check-white.png" alt="" />
                                                                            ) : (
                                                                                <img src="img/add-icon.png" alt="" />
                                                                            )}
                                                                        </li>
                                                                    )}
                                                                    <li key={item.data_id} onClick={() => FavoriteAdd(item.data_id, item.data_type)}>
                                                                        { favoriteStatus[item.data_id] ? (
                                                                            <li><img src="/img/fav.png" alt="" /></li>
                                                                        ) : (
                                                                            <li><img src="/img/like stroke.png" alt="" /></li>
                                                                        )}
                                                                    </li>

                                                                </ul>
                                                            </div>
                                                            <div className="item_img_number">
                                                                <h3>{item.maturity_level}</h3>
                                                                <h4>{item.release_date ? (new Date(item.release_date)).getFullYear() : ""} </h4>
                                                            </div>
                                                        </div>
                                                        {
                                                            item.is_premium == true && item.is_paid == false ? 
                                                            <div className='premium_icon'>
                                                                <Link to={"#"}><img src='img/premium_icon.png' /></Link>
                                                            </div> : ""
                                                        }
                                                    </div>
                                                ))
                                                : <>
                                                    <div className="shimmer_card">

                                                    </div>
                                                </>
                                        }
                                    </div>
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </>
            </Container>
            <Container fluid className='cust_container '>
                {TopSearchchCheck ? (
                    <>
                        <h4 className='white_text'>Top Searches</h4>
                        {/* {viewAllTopSearchMovie && (
                            <Link to={"/ContinueWatching/topsearch"}>View All</Link>
                        )} */}

                        <div className="view_all_section">
                        
                            <div className="slick-slider episode_details">
                                {!loading ? (
                                    Array.isArray(TopSearchMovie) &&
                                    TopSearchMovie.map((item) => (
                                        <div className='item_box' key={item.data_id}>
                                            <div>
                                                {item.data_type === 'movie' ? (
                                                    <div onClick={() => handleShow(item.data_id, item.data_type)}>
                                                        <img src={item.media_poster} alt={item.title} />
                                                    </div>
                                                ) : (
                                                    <div onClick={() => handleTvShow(item.data_id)}>
                                                        <img src={item.media_poster} alt={item.title} />
                                                    </div>
                                                )}
                                            </div>
                                            <div className="item_img_details">
                                                <div className="item_img_header" key={item.title}>
                                                    <h5>{item.title}</h5>
                                                    <ul>
                                                    {item.data_type === 'movie' ? (
                                                        item.is_paid == true ? <li onClick={() => PlayVideo(item.data_id)}><img src="img/play-icon.png" alt="" /></li>:<li onClick={() => handleShow(item.data_id,item.data_type)}><img src="img/play-icon.png" alt="" /></li>
                                                    ) : (
                                                        <li onClick={() => handleTvShow(item.data_id)}><img src="/img/play-icon.png" alt="" /></li>
                                                    )}
                                                        

                                                        {watchlistStatus && watchlistStatus[item.data_id] ? (
                                                            <li onClick={() => WatchlistAdd(item.data_id, item.data_type)}>
                                                                {watchlistStatus[item.data_id] ? (
                                                                    <img src="/img/check-white.png" alt="" />
                                                                ) : (
                                                                    <img src="/img/add-icon.png" alt="" />
                                                                )}
                                                            </li>
                                                        ) : (
                                                            <li onClick={() => WatchlistAdd(item.data_id, item.data_type)}>
                                                                {Object.keys(watchlistStatus).length === 0 && item.is_watchlist == "true" ? (
                                                                    <img src="/img/check-white.png" alt="" />
                                                                ) : (
                                                                    <img src="/img/add-icon.png" alt="" />
                                                                )}
                                                            </li>
                                                        )}
                                                        <li onClick={() => FavoriteAdd(item.data_id, item.data_type)}>
                                                            {favoriteStatus[item.data_id] ? (
                                                                <li><img src="/img/fav.png" alt="" /></li>
                                                            ) : (
                                                                <li><img src="/img/like stroke.png" alt="" /></li>
                                                            )}
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="item_img_number">
                                                    <h3> {item.maturity_level}</h3>
                                                    <h4>{item.release_date ? (new Date(item.release_date)).getFullYear() : ""} </h4>
                                                </div>
                                            </div>
                                                {
                                                    item.is_premium == true ? <div className='premium_icon'>
                                                        <Link to={"#"}><img src='img/premium_icon.png' /></Link>
                                                    </div> : ""
                                                }
                                        </div>
                                    ))
                                ) : (
                                    Array.from({ length: 8 }).map((_, index) => (
                                        <div className="shimmer_card" key={index}></div>
                                    ))
                                )}
                            </div>
                        </div>
                    </>
                ):(

                    <>
                    {
                        !searchDataCheck && tabKey == 'one'? (
                            <div className='data_not_found_img data_not_found_img_two'>
                                <img src='/img/search.svg'></img>    
                                <p className='result_text'>{errormovie}</p>     
                            </div>
                        ) : null
                    }
                    
                    {
                        !searchDataCheckTvShow  && tabKey == 'two' ? (
                            <div className='data_not_found_img data_not_found_img_two'>
                                <img src='/img/search.svg'></img>    
                                <p className='result_text'>{errortvshow}</p>              
                            </div>
                        ) : null
                    }
                    </>
                    
                )}

            </Container>
                                       
            <Footer />

            

            <Modal className='series_modal' show={show} onHide={handleClose} backdrop="static" size="lg" keyboard={false} centered>
                <div className="modal_close" onClick={handleClose}>
                    <Link to={""}><img src="/img/modal_close.png" alt=""></img></Link>
                </div>

                <Modal.Body>
                    <div className="series_img">
                        {moviemodeldata ? (
                            <img src={moviemodeldata ? moviemodeldata['media_banner'] : '/img/default-banner.png'} className="series_big_imf" alt=""></img>
                        ) : (
                            <div className='series_img_shimmer'></div>
                        )}

                        <div className="series_tool">
                            <div className="series_name">
                                <h2>{moviemodeldata ? moviemodeldata['title'] : ''}</h2>
                            </div>
                            <div className="series_controller">
                                <div className="series_left_controller">

                                    {moviemodeldata && Object.keys(moviemodeldata['premium']).length > 0  && moviemodeldata['premium']['is_paid']  == false?
                                        <button className="music_play_btn" onClick={() => handleShowTwo(moviemodeldata['data_id'],'movie')}><img src="img/play_icon.jpg"></img>Pay & Play</button>
                                     :  <button onClick={() => moviemodeldata && PlayVideo(moviemodeldata['data_id'])} className="music_play_btn"><img src="img/play_icon.jpg"></img>Play</button>}


                                    {watchlistStatus && moviemodeldata && watchlistStatus[moviemodeldata['data_id']] ? (
                                        <div onClick={() => WatchlistAdd(moviemodeldata['data_id'], moviemodeldata['data_type'])}>
                                            {watchlistStatus[moviemodeldata['data_id']] ? (

                                                <button className="music_small_btn"><img src="/img/check-white.png"></img></button>
                                            ) : (
                                                <button className="music_small_btn"><img src="/img/music_add.png"></img></button>
                                            )}

                                        </div>
                                    ) : (
                                        <div onClick={() => moviemodeldata && WatchlistAdd(moviemodeldata['data_id'], moviemodeldata['data_type'])}>
                                            {moviemodeldata && Object.keys(watchlistStatus).length === 0 && moviemodeldata && moviemodeldata['is_watchlist'] === "true" ? (
                                                <button className="music_small_btn"><img src="/img/check-white.png" alt="" /></button>
                                            ) : (
                                                <button className="music_small_btn"><img src="/img/music_add.png" alt="" /></button>
                                            )}
                                        </div>
                                    )}
                                    {favoriteStatus && moviemodeldata && favoriteStatus[moviemodeldata['data_id']] ? (
                                        <div onClick={() => FavoriteAdd(moviemodeldata['data_id'], moviemodeldata['data_type'])}>
                                            {favoriteStatus[moviemodeldata['data_id']] ? (

                                                <button className="music_small_btn"><img src="/img/fav.png"></img></button>
                                            ) : (
                                                <button className="music_small_btn"><img src="/img/like stroke.png"></img></button>
                                            )}

                                        </div>
                                    ) : (
                                        <div onClick={() => moviemodeldata && FavoriteAdd(moviemodeldata['data_id'], moviemodeldata['data_type'])}>
                                            {moviemodeldata && Object.keys(favoriteStatus).length === 0 && moviemodeldata && moviemodeldata['is_watchlist'] === "true" ? (
                                                <button className="music_small_btn"><img src="/img/fav.png" alt="" /></button>
                                            ) : (
                                                <button className="music_small_btn"><img src="/img/like stroke.png" alt="" /></button>
                                            )}
                                        </div>
                                    )}

                                </div>
                                <div className="series_right_controller">
                                    <button className='music_small_btn'><img src="img/music_volum.png" className=" m-0 d-none"></img></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="series_full_description">
                        {
                            moviemodeldata ? (
                                <div className="series_details_content">
                                    <div className="series_description">
                                        <div className="more_second_section mt-0">
                                            <h3> {moviemodeldata ? moviemodeldata.maturity_level : ''}</h3>
                                            <h4>{moviemodeldata && moviemodeldata['release_date'] ? (new Date(moviemodeldata['release_date'])).getFullYear() : ""} &nbsp;|&nbsp; {moviemodeldata && FormatTimeWithHourMinute(moviemodeldata['duration'])}</h4>
                                            <h5 className='movie_price'>
                                                {!isUndefined(moviePrice) ? currencySymbol + moviePrice: ""}
                                            </h5>

                                        </div>
                                        <p>{moviemodeldata ? moviemodeldata['description'] : ''}</p>
                                    </div>
                                    <div className="series_categories">
                                        <h4>Cast : <span className="white_text">
                                            {moviemodeldata && moviemodeldata['celebs'] && Array.isArray(moviemodeldata['celebs']) ? (moviemodeldata['celebs'] as { title: string }[]).map((cast) => cast.title).join(', ') : ""}
                                        </span></h4>
                                        <h4>Genres  : <span className="white_text">
                                            {moviemodeldata && moviemodeldata['genres'] && Array.isArray(moviemodeldata['genres']) ? (moviemodeldata['genres'] as { genre_title: string }[]).map((genre) => genre.genre_title).join(', ') : ""}
                                        </span></h4>
                                    </div>
                                </div>
                            ) : (
                                null
                            )
                        }
                        <div className="more_like_this">

                            {
                                CheckMoreLikeThisMovie === true ? (
                                    <div className="more_like_this_title">
                                        <h3>More Like This</h3>
                                    </div>
                                ) : (null)
                            }

                            <div className="row">
                                {
                                    CheckMoreLikeThisMovie === true ? (
                                        MorelikeThisLoading === false && Array.isArray(MoreLikeThisMoviedata) && MoreLikeThisMoviedata.length > 0 ? (
                                            MoreLikeThisMoviedata.map((item) => (

                                                <div className="col-lg-4 col-md-4 col-sm-6">
                                                    <div className="more_like_box">
                                                        <div className="more_like_box_img" onClick={() => handleShow(item.data_id,'movie')}>
                                                            <img src={item.media_banner}></img>
                                                        </div>
                                                        {
                                                            item.is_premium == true && item.is_paid == false ? 
                                                            <div className='premium_icon'>
                                                                <Link to={"#"}><img src='img/premium_icon.png' /></Link>
                                                            </div>: "" 
                                                        }

                                                        <div className="more_like_content_box">
                                                            <div className="more_like_left_info">
                                                                <h4>{item.title}</h4>
                                                                {watchlistStatus && watchlistStatus[item.data_id] ? (
                                                                    <li onClick={() => WatchlistAdd(item.data_id, item.data_type)}>
                                                                        {watchlistStatus[item.data_id] ? (
                                                                            <img src="/img/check-white.png" alt="" />
                                                                        ) : (
                                                                            <img src="/img/add-icon.png" alt="" />
                                                                        )}

                                                                    </li>
                                                                ) : (
                                                                    <li onClick={() => WatchlistAdd(item.data_id, item.data_type)}>
                                                                        {Object.keys(watchlistStatus).length === 0 && item.is_watchlist == "true" ? (
                                                                            <img src="/img/check-white.png" alt="" />
                                                                        ) : (
                                                                            <img src="/img/add-icon.png" alt="" />
                                                                        )}
                                                                    </li>
                                                                )}
                                                            </div>
                                                            <div className="more_second_section">
                                                                <h3> {item.maturity_level}</h3>
                                                                <h4>
                                                                    {item.release_date ? (new Date(item.release_date)).getFullYear() : ""}
                                                                </h4>
                                                            </div>
                                                            <div className="more_second_description">
                                                                <p>{item.description}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            Array.from({ length: 3 }).map((_, index) => (
                                                <div className='more_like_shimmer' key={index}></div>
                                            ))
                                        )
                                    ) : (null)
                                }
                            </div>
                            <div className="more_img_load">
                                <Link to={""}><img src="img/down_arrowpng.png" alt=""></img></Link>
                            </div>
                        </div>
                        <div className="about_movie">
                            <div className="more_like_this_title">
                                <h3>About {moviemodeldata ? moviemodeldata['title'] : ''}</h3>
                            </div>
                            <div className="about_movie_details">
                                <div className="series_categories">
                                    <h4>Cast & Crew : <span className="white_text"> {moviemodeldata && moviemodeldata['celebs'] && Array.isArray(moviemodeldata['celebs']) ? (moviemodeldata['celebs'] as { title: string }[]).map((cast) => cast.title).join(', ') : ""}</span></h4>
                                    <h4>Genres  : <span className="white_text">{moviemodeldata && moviemodeldata['genres'] && Array.isArray(moviemodeldata['genres']) ? (moviemodeldata['genres'] as { genre_title: string }[]).map((genre) => genre.genre_title).join(', ') : ""}</span></h4>
                                    <h4>Maturity Rating: <span className="white_text white_text_border"> {moviemodeldata ? moviemodeldata['maturity_level'] : ''} </span></h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>

            </Modal>

            <Modal className='series_modal' show={tvmodelshow} onHide={tvshowhandleClose} backdrop="static" size="lg" keyboard={false} centered>
                <div className="modal_close" onClick={tvshowhandleClose}>
                    <Link to={""}><img src="/img/modal_close.png" alt=""></img></Link>
                </div>

                <Modal.Body>
                    <div className="series_img">
                        {tvshowmodeldata ? (
                            <img src={tvshowmodeldata ? tvshowmodeldata['media_banner'] : '/img/default-banner.png'} className="series_big_imf" alt=""></img>
                        ) : (
                            <div className='series_img_shimmer'></div>
                        )}
                        <div className="series_tool">
                            <div className="series_name">
                                <h2>{tvshowmodeldata ? tvshowmodeldata['title'] : ''}</h2>
                            </div>
                            <div className="series_controller">
                                <div className="series_left_controller">

                                    {tvshowmodeldata && Object.keys(tvshowmodeldata['premium']).length > 0  && tvshowmodeldata['premium']['is_paid'] == false ?
                                    
                                    <button className="music_play_btn" onClick={() => handleShowTwo(tvshowmodeldata['data_id'],'tvshow')}><img src="img/play_icon.jpg"></img>Pay & Play</button>
                                    :<button className="music_play_btn" onClick={() => FirstepisodeID && tvshowmodeldata && PlayEpisode(FirstepisodeID, tvshowmodeldata['data_id'])}><img src="img/play_icon.jpg"></img>Play</button>}


                                    {watchlistStatus && tvshowmodeldata && watchlistStatus[tvshowmodeldata['data_id']] ? (
                                        <div onClick={() => WatchlistAdd(tvshowmodeldata['data_id'], tvshowmodeldata['data_type'])}>
                                            {watchlistStatus[tvshowmodeldata['data_id']] ? (

                                                <button className="music_small_btn"><img src="/img/check-white.png"></img></button>
                                            ) : (
                                                <button className="music_small_btn"><img src="/img/music_add.png"></img></button>
                                            )}

                                        </div>
                                    ) : (
                                        <div onClick={() => tvshowmodeldata && WatchlistAdd(tvshowmodeldata['data_id'], tvshowmodeldata['data_type'])}>
                                            {tvshowmodeldata && Object.keys(watchlistStatus).length === 0 && tvshowmodeldata && tvshowmodeldata['is_watchlist'] === "true" ? (
                                                <button className="music_small_btn"><img src="/img/check-white.png" alt="" /></button>
                                            ) : (
                                                <button className="music_small_btn"><img src="/img/music_add.png" alt="" /></button>
                                            )}
                                        </div>
                                    )}

                                    {favoriteStatus && tvshowmodeldata && favoriteStatus[tvshowmodeldata['data_id']] ? (
                                        <div onClick={() => FavoriteAdd(tvshowmodeldata['data_id'], tvshowmodeldata['data_type'])}>
                                            {favoriteStatus[tvshowmodeldata['data_id']] ? (

                                                <button className="music_small_btn"><img src="/img/fav.png"></img></button>
                                            ) : (
                                                <button className="music_small_btn"><img src="/img/like stroke.png"></img></button>
                                            )}

                                        </div>
                                    ) : (
                                        <div onClick={() => tvshowmodeldata && FavoriteAdd(tvshowmodeldata['data_id'], tvshowmodeldata['data_type'])}>
                                            {tvshowmodeldata && Object.keys(favoriteStatus).length === 0 && tvshowmodeldata && tvshowmodeldata['is_watchlist'] === "true" ? (
                                                <button className="music_small_btn"><img src="/img/fav.png" alt="" /></button>
                                            ) : (
                                                <button className="music_small_btn"><img src="/img/like stroke.png" alt="" /></button>
                                            )}
                                        </div>
                                    )}

                                </div>
                                <div className="series_right_controller">
                                    <button className='music_small_btn'><img src="img/music_volum.png" className=" m-0 d-none"></img></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="series_full_description">
                        {
                            tvshowmodeldata ? (
                                <div className="series_details_content">
                                    <div className="series_description">
                                        <div className="more_second_section mt-0">
                                            <h3> {tvshowmodeldata ? tvshowmodeldata['maturity_level'] : ''}</h3>
                                            <h4>{tvshowmodeldata && tvshowmodeldata['release_date'] ? (new Date(tvshowmodeldata['release_date'])).getFullYear() : ""} </h4>
                                            <h5 className='movie_price'>
                                                {!isUndefined(tvShowPrice) ? currencySymbol + tvShowPrice: ""}
                                            </h5>
                                        </div>

                                        <p>{tvshowmodeldata ? tvshowmodeldata['description'] : ''}</p>
                                    </div>
                                    <div className="series_categories">
                                        <h4>Cast & Crew : <span className="white_text">
                                            {tvshowmodeldata && tvshowmodeldata['celebs'] && Array.isArray(tvshowmodeldata['celebs']) ? (tvshowmodeldata['celebs'] as { title: string }[]).map((cast) => cast.title).join(', ') : ""}
                                        </span></h4>
                                        <h4>Genres  : <span className="white_text"> {tvshowmodeldata && tvshowmodeldata['genres'] && Array.isArray(tvshowmodeldata['genres']) ? (tvshowmodeldata['genres'] as { genre_title: string }[]).map((genre) => genre.genre_title).join(', ') : ""}</span></h4>
                                    </div>
                                </div>
                            ) : (
                                null
                            )
                        }
                        <div className="series_episode_details">
                            <div className="series_episode_header">
                                <h3>Episode</h3>
                                <div className='genres_section'>
                                    <h3>Seasons</h3>
                                    <div className="select_box">
                                        <Form.Select aria-label="Default select example" className='cust_seleted' onChange={handleSelectChange}>
                                            {
                                                tvshowmodeldata && tvshowmodeldata.season && tvshowmodeldata.season.length > 0 ? (
                                                    tvshowmodeldata.season.map((season: any, index: any) => (

                                                        <option key={season.season_id} value={season.season_id} > {season.title}</option>
                                                    ))
                                                ) : (
                                                    <p style={{ color: "red" }}>No Seasons available</p>
                                                )
                                            }

                                        </Form.Select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="series_episode_list">


                            {
                                EpisodeOrSeasonchCheck === true ? (
                                    EpisodeLoading === false && episode && episode.length > 0 ? (
                                        episode.map((episode: any, index: any) => (
                                            <div key={index} className="series_episode_list_box"
                                             
                                            onClick={() => {
                                                if (tvshowmodeldata && Object.keys(tvshowmodeldata['premium']).length > 0 && tvshowmodeldata['premium']['is_paid'] == false) {
                                                  handleShowTwo(tvshowmodeldata['data_id'], 'tvshow'); // Open details page
                                                } else {
                                                    if(episode.data_id && tvshowmodeldata ){
                                                        PlayEpisode(episode.data_id, tvshowmodeldata['data_id']); // Play episode
                                                    }
                                                }
                                              }}
                                             
                                             >
                                                <div className="series_episode_list_box_details">
                                                    <h4>{episode.episode_number}</h4>
                                                    <div className="episode_details_img">
                                                        <img src={episode.media_banner} className="episode_img" alt={episode.title} />
                                                        <a href={episode.media_content}>
                                                            <img src="img/play_video.png" className="play_video_icon" alt="Play Video" />
                                                        </a>
                                                    </div>
                                                    <div className="series_episode_list_box_description">
                                                        <h3>{episode.title}</h3>
                                                        <p>{episode.description}</p>
                                                    </div>
                                                </div>
                                                <div className="series_episode_list_box_min">
                                                    <h2>{FormatTimeWithHourMinute(episode.duration)}</h2>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        Array.from({ length: 3 }).map((_, index) => (
                                            <div className='series_episode_list_box_shimmer' key={index}></div>
                                        ))
                                    )
                                ) : (
                                    <h5 className='white_text'>Episode Not Found.</h5>
                                )
                            }



                        </div>
                        <div className="more_like_this">
                            <div className="more_like_this_title">
                                <h3>More Like This</h3>
                            </div>
                            <div className="row">

                                {
                                    Array.isArray(MoreLikeThisTVShowdata) && MoreLikeThisTVShowdata.length > 0 ? (
                                        MoreLikeThisTVShowdata.map((item) => (
                                            <div className="col-lg-4 col-md-4 col-sm-6">
                                                <div className="more_like_box" >
                                                    <div className="more_like_box_img" onClick={() => handleTvShow(item.data_id)}>
                                                        <img src={item.media_banner}></img>
                                                    </div>
                                                    {
                                                        item.is_premium == true && item.is_paid == false ? 
                                                        <div className='premium_icon'>
                                                            <Link to={"#"}><img src='img/premium_icon.png' /></Link>
                                                        </div>: "" 
                                                    }
                                                    <div className="more_like_content_box">
                                                        <div className="more_like_left_info">
                                                            <h4>{item.title}</h4>
                                                            {watchlistStatus && watchlistStatus[item.data_id] ? (
                                                                <li key={item.data_id} onClick={() => WatchlistAdd(item.data_id, item.data_type)}>
                                                                    {watchlistStatus[item.data_id] ? (
                                                                        <img src="/img/check-white.png" alt="" />
                                                                    ) : (
                                                                        <img src="/img/add-icon.png" alt="" />
                                                                    )}

                                                                </li>
                                                            ) : (
                                                                <li onClick={() => WatchlistAdd(item.data_id, item.data_type)}>
                                                                    {Object.keys(watchlistStatus).length === 0 && item.is_watchlist == "true" ? (
                                                                        <img src="/img/check-white.png" alt="" />
                                                                    ) : (
                                                                        <img src="/img/add-icon.png" alt="" />
                                                                    )}
                                                                </li>
                                                            )}
                                                        </div>
                                                        <div className="more_second_section">
                                                            <h3> {item.maturity_level}</h3>
                                                            <h4>
                                                                {item.release_date ? (new Date(item.release_date)).getFullYear() : ""}
                                                            </h4>
                                                        </div>
                                                        <div className="more_second_description">
                                                            <p>{item.description}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <div className="no-movies-found-message">
                                        </div>
                                    )
                                }
                            </div>
                            <div className="more_img_load">
                                <Link to={""}><img src="img/down_arrowpng.png" alt=""></img></Link>
                            </div>
                        </div>
                        <div className="about_movie">
                            <div className="more_like_this_title">
                                <h3>About {tvshowmodeldata ? tvshowmodeldata['title'] : ''}</h3>
                            </div>
                            <div className="about_movie_details">
                                <div className="series_categories">
                                    <h4>Cast & Crew : <span className="white_text">{tvshowmodeldata && tvshowmodeldata['celebs'] && Array.isArray(tvshowmodeldata['celebs']) ? (tvshowmodeldata['celebs'] as { title: string }[]).map((cast) => cast.title).join(', ') : ""}</span></h4>
                                    <h4>Genres  : <span className="white_text">{tvshowmodeldata && tvshowmodeldata['genres'] && Array.isArray(tvshowmodeldata['genres']) ? (tvshowmodeldata['genres'] as { genre_title: string }[]).map((genre) => genre.genre_title).join(', ') : ""}</span></h4>
                                    <h4>Maturity Rating: <span className="white_text white_text_border"> {tvshowmodeldata ? tvshowmodeldata['maturity_level'] : ''}</span></h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal className='login_modal' show={showModal} onHide={handleClosee} centered   backdrop="static">
                <Modal.Body>
                    <div className='login_modal_img'>
                        <img src='/img/login.svg'></img>            
                    </div>
                    <div className='login_modal_text text-center'>
                         <h3>Login Now</h3>
                         <h4>You have to login to use this feature</h4>       
                    </div>
                    <div className='login_modal_btn'>
                         <Link to={routes.signin}>Login</Link>
                         <Link onClick={handleClosee}  to={""}>Continue</Link>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal className='movie_name_modal' show={showTwo} onHide={handleCloseTwo}  centered backdrop="static" keyboard={false}>
                
                <Modal.Body>
                    <div className='movie_name_close_modal' onClick={movie_name_modal_close}>
                        <Link to={"#"}><img src='img/modal_close.png'/></Link>
                    </div>
                    <div className='movie_name_details'>
                        <div className='movie_name_img'>
                            <img src='img/tv.svg'/>
                        </div>
                        <div className='movine_name_section'>
                            <h3>{itemTitle}</h3>
                            <h4>{currencySymbol}{itemPrice}</h4>
                            <button onClick={handleShowThree} >Continue to Pay</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal className='movie_name_modal' show={showThree} onHide={handleCloseThree}  centered backdrop="static" keyboard={false}>
                <Modal.Body>
                    {paymentLoder == true ? (
                    <div className='loader'>
                            <img src='img/loader.gif' alt="Loading..." />
                    </div>
                    ) : (
                        null
                    )}
                        <>
                            <div className='movie_name_close_modal' onClick={handleCloseThree}>
                                <Link to="#">
                                    <img src='img/modal_close.png' alt="Close Modal" />
                                </Link>
                            </div>
                            <div className='stripe_header'>
                                <h3>Payment for - <span>{itemTitle}</span></h3>
                                <h3>Total Amount - <span>{currencySymbol}{itemPrice}</span></h3>
                            </div>
                            <div className='stripe_details'>
                                {itemDataType && itemVideoId ? (
                                    <Elements stripe={stripePromise}>
                                        <ItemPurchase 
                                            data_id={itemVideoId} 
                                            data_type={itemDataType}  
                                            updatePaymentStatus={updateVideoPaymentStatus} 
                                            errorInVideoPayment={errorInVideoPayment} 
                                            loderShow={loderShow} 
                                            loderHide={loderHide}
                                        />
                                    </Elements>
                                ) : ""}
                            </div>
                        </>
                </Modal.Body>
            </Modal>


        </div>
    )
}
