import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { useLocation } from "react-router-dom";
import routes from '../Routes'
import { User, Session } from '../Helper';
import { UserInfo } from '../Model/Interface';
import { API_URL_BASE, X_APP_SECRET, headers } from '../utils';
import { API_URLS } from '../utils';
import SearchData from '../SearchData';
import secureLocalStorage from 'react-secure-storage';

export default function PrivacyHeader() {

  const location = useLocation();
  const { pathname } = location;

  var user = User();
  const token = user?.data.token
  var session = Session();

  const splitLocation = pathname.split("/");

  const ref = React.useRef();
  const navigate = useNavigate();

  const [isScrollValueMoreThanHeaderHeight, setIsScrollValueMoreThanHeaderHeight] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrollValueMoreThanHeaderHeight(window.scrollY > 96);
    }
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll);
  }, [])

  const [profilename, setProfilename] = React.useState<string>();
  const [profileimage, setProfileimage] = React.useState<string>();

  useEffect(() => {

    if (session) {
      setProfilename(session?.profile_name)
      setProfileimage(session?.profile_image)
    } else {
      console.warn("session not found");
    }

  }, [profilename, token])

  const [menu, setMenu] = useState(false)

  const openMenu = () => {
    setMenu(true)
  }
  const menuClose = () => {
    setMenu(false)
  }


  async function logoutUser() {
    try {
      if (user && user.data && user.data.token) {
        const response = await fetch(`${API_URL_BASE}${API_URLS.LOG_OUT}`, {
          method: 'POST',
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
            'X-APP-SECRET': X_APP_SECRET,
            Authorization: "Bearer " + token,
          },
        });
        if (response.ok) {
          const data = await response.json();
          if (data.response === true) {
            console.warn('Logout Successful');
            secureLocalStorage.removeItem('playnow-user');
            secureLocalStorage.removeItem('userProfile');
            secureLocalStorage.removeItem('user-email');
            navigate(routes.signin);
          } else {
            console.warn("Error");
          }
        } else {
          console.error('API request failed:', response.statusText);
          console.warn('Error during logout');
        }
      } else {
        console.warn('User information is missing.');
      }
    } catch (error) {

      console.error('An error occurred:', error);
      console.warn('An error occurred during logout');
    }
  }

  return (
    <div className='privacy'>
      <div className={isScrollValueMoreThanHeaderHeight ? "fixed" : "privacy_main_header"}>
        <Container >
          <div className="header_menu">
            <div className="desktop_menu">
              <div className="header">
                <div className="header_left_content">
                  <Link to={"/home"}><img src="/img/play_now_logo.png" alt=""></img></Link>

                </div>
                <div className="header_right_content">

                  <div className="drop_menu">
                    <Link to={""}><img src={profileimage} style={{ height: '45px', width: '45px' }} alt=""></img></Link>
                    <Link to={""}><img src="./img/down_arrow.png" className="mx-2" alt=""></img></Link>
                    <div className="profile_menu profile_menu_two">
                      <ul>

                        <li><Link to={""}><img src={profileimage} style={{ height: '45px', width: '45px' }} alt=""></img><span style={{ color: "red" }}>{profilename}</span></Link></li>

                        <li className="small_icon"><Link to={routes.switchProfile}><img src="/img/pen.png" alt=""></img><span className="small_text">Manage Profile</span></Link></li>
                        <li className="small_icon"><Link to={"/setting"}><img src="/img/user-account.png" alt=""></img><span className="small_text">Account Settings</span></Link></li>
                        <li className="small_icon"><Link to={"/billing-details"}><img src="/img/billing.png" alt=""></img><span className="small_text">Billing Details</span></Link></li>
                        <li className="small_icon"><Link to={"/plans"}><img src="/img/select_plan.png" alt=""></img><span className="small_text">Select Plan</span></Link></li>
                        <li className="small_icon"><Link to={""}><img src="/img/help.png" alt=""></img><span className="small_text">Help</span></Link></li>
                        <li className="small_icon" onClick={logoutUser} style={{ color: "red" }}> Logout</li>
                      </ul>
                      <ul className="profile_logout">
                        {/* <li><Link>Sign out</Link></li> */}
                      </ul>
                    </div>
                  </div>

                </div>
              </div>
            </div>


          </div>
        </Container>
      </div>
    </div>
  )
}
